import { FC, useCallback, useMemo } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { Icon } from '@mdi/react';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { IStatus, ISteper } from '../../gateways/interfaces/steper.interface';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStylesHorizontal = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: '#74de91',
    }
});

const useColorlibStepIconStylesVertical = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: '#2196f3',
    },
    completed: {
        backgroundColor: '#74de91',
    }
});

function ColorlibStepIconHorizontal(props: StepIconProps, items: ISteper[]) {
    const classes = useColorlibStepIconStylesHorizontal();
    const { active, completed } = props;

    const icons = Object.assign({}, ...items.map((item) => ({ [item.index + 1]: item.icon ? <Icon path={item.icon} size={1} /> : item.index + 1 })));

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

function ColorlibStepIconVertical(props: StepIconProps, items: ISteper[]) {
    const classes = useColorlibStepIconStylesVertical();
    const { active, completed } = props;

    const icons = Object.assign({}, ...items.map((item) => ({ [item.index + 1]: item.icon ? <Icon path={item.icon} size={1} /> : item.index + 1 })));

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',

        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

interface IProps {
    listSteps: ISteper[],
    stepActive: number,
    orientation: 'vertical' | 'horizontal',
    onChange: (value: number, block: boolean) => void;
}

export const StepperIntri: FC<IProps> = ({ listSteps, stepActive, orientation, onChange }) => {
    const { t } = useTranslation('solicitud');
    const classes = useStyles();
    const steps = listSteps;
    const history = useHistory();
    const step = useMemo(() => {
        switch (history.location.pathname) {
            case '/expediente/solicitud':
                return 0;
            case '/expediente/resultado':
                return 1;
            case '/expediente/justificantes':
                return 2;
            case '/expediente/cumplimiento':
                return 3;
            default:
                return 0;
        }
    }, [history])

    const handleChange = useCallback((stepSelected: number) => {
        if (listSteps[stepSelected].status !== IStatus.Blocked) {
            onChange(stepSelected, false);
        }
        return;
    }, [onChange, listSteps])
    return (
        <div className={classes.root}>
            <Stepper className={steps[stepActive].status !== IStatus.Blocked ? 'root' : ''} orientation={orientation} alternativeLabel={orientation === 'horizontal'} activeStep={stepActive} connector={orientation === 'horizontal' ? <ColorlibConnector /> : <StepConnector aria-orientation='vertical' />}>
                {steps.map((x, i) => (
                    <Step key={`step_${i}`}>
                        {
                            orientation === 'horizontal'
                                ?
                                <StepLabel style={{ cursor: 'pointer' }} onClick={() => handleChange(x.index)} StepIconComponent={(item) => ColorlibStepIconHorizontal(item, steps)}><span style={step === x.index ? { fontWeight: 'bold' } : { fontWeight: 100 }}>{t(x.name)}</span></StepLabel>
                                :
                                <StepLabel StepIconComponent={(item) => ColorlibStepIconVertical(item, steps)} style={{ cursor: 'pointer' }} onClick={() => handleChange(x.index)}><span style={step === x.index ? { fontWeight: 'bold' } : { fontWeight: 100 }}>{t(x.name)}</span></StepLabel>
                        }

                    </Step>
                ))}
            </Stepper>
        </div>
    );
}

export default StepperIntri;