import { useCallback, useContext } from "react";
import { PageContext } from "../contexts/app.context";
import { IAuthenticationRequestResult } from "./interfaces/auth.interfaces";

const useAuthGateway = () => {
    const [appState] = useContext(PageContext);
    const { axios } = appState;

    return {
        getAuthenticationRequest: useCallback(
            async (returnUrl: string): Promise<IAuthenticationRequestResult> => {
                try {
                    const response = await axios.get('/auth/authentication-request?returnUrl=' + encodeURIComponent(returnUrl));
                    
                    return response.data;
                } catch (e) {
                    return {
                        ok: false,
                        provider: '',
                        forcedIdP: '',
                        relayState: '',
                    };
                }
            },
            [axios],
        ),
        checkSession: useCallback(
            async (): Promise<IAuthenticationRequestResult> => {
                const response = await axios.get('/auth/check-session');
                
                return response.data;
            },
            [axios],
        ),
    };
};

export default useAuthGateway;