import { FC } from "react";
import { WithStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, withStyles } from '@material-ui/core';
import Term from "../organisms/term";
import MainLayout from "./_main.layout";
import styles from '../../resources/styles/clave.styles';

interface IProps extends WithStyles<typeof styles> {
    redirectToClave: () => void;
    body: string;
}

const ClaveTemplate: FC<IProps> = ({ classes, redirectToClave, body }) => {

    return (
        <MainLayout>
            <Card className={classes.contenido}>
                <CardContent>
                    <div className={classes.center}>
                        <img src={require(`./../../resources/images/ImgClaveModal.jpg`).default} alt="clave" className={classes.imgResponsive} />
                    </div>

                    <div>
                        <div dangerouslySetInnerHTML={{ __html: body }} />
                        <div className={classes.center}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={redirectToClave} size="large">
                                <Term text="global:continuar" />
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </MainLayout >
    )
}

export default withStyles(styles)(ClaveTemplate);