import { FC, useCallback, useMemo, useState } from "react";
import { CardContent, Card, CardActions, Button } from "@material-ui/core";
import MainLayout from "./_main.layout";
import useStyles from "../../resources/styles/generic.styles";
import { useTranslation } from "react-i18next";
import { EstadoExpediente, IExpediente, IFirmaJustificanteCumplimiento } from "../../gateways/interfaces/expediente.interface";
import JustificantesCumplimiento from "../organisms/justificantes-cumplimiento";
import FirmaJustificantes from "../organisms/firma-justificantes";

interface IProps {
    expediente: IExpediente;
    docCumplimiento: any;
    loading: boolean;
    signDialogOpen: boolean;
    handleSign: (firma: IFirmaJustificanteCumplimiento) => void;
    handleSignDialog: (open: boolean) => void;
    handleDownload: () => void;
}

export const CumplimientoCompromisosTemplate: FC<IProps> = ({ expediente, docCumplimiento, loading, signDialogOpen, handleSign, handleSignDialog, handleDownload }) => {
    const { t } = useTranslation("solicitud");
    const classes = useStyles();

    const expedienteCumplimentado = useMemo(() => expediente.idEstado === EstadoExpediente.CumplimientoCompromisos, [expediente]);
    // Local states    
    const [fileCumplimiento, setFileCumplimiento] = useState<{ file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string }>(docCumplimiento);

    // Events
    const handleFileCumplimiento = useCallback((file: any) => {
        setFileCumplimiento(file);
    }, []);

    const handleSignature = useCallback(() => {
        let firma: IFirmaJustificanteCumplimiento = {
            documentoCumplimiento: fileCumplimiento?.idDocumento,
        }
        handleSign(firma);
    }, [fileCumplimiento, handleSign]);

    return (
        <>
            {expediente !== null && expediente.justificantes !== undefined && expediente.sujeto !== undefined
                ?
                <>
                    <MainLayout>
                        <Card style={{ padding: 40 }} className={classes.card}>
                            <CardContent>
                                <h3 style={{ color: '#004f82', fontWeight: 400, fontSize: 24, marginBottom: 20 }}>{t("solicitud:cumplimiento_compromisos")}</h3>
                                <JustificantesCumplimiento
                                    file={fileCumplimiento}
                                    handleFile={handleFileCumplimiento}
                                    expedientBlocked={expedienteCumplimentado}
                                />
                            </CardContent>
                            <CardActions style={{ justifyContent: "flex-end" }}>
                                <>
                                {
                                    expediente.idEstado === EstadoExpediente.CumplimientoCompromisos ?
                                        <Button color="primary" variant="contained" style={{ margin: 10 }} onClick={handleDownload} >
                                            {t("solicitud:descargar_justificante")}
                                        </Button>
                                    :
                                    fileCumplimiento !== null && fileCumplimiento.file ?
                                        <Button color="primary" variant="contained" style={{ margin: 10 }} onClick={() => handleSignDialog(true)}>
                                            {t("solicitud:Firmar")}
                                        </Button>
                                    :
                                    null
                                }
                                </>
                            </CardActions>
                        </Card>
                    </MainLayout>
                    <FirmaJustificantes
                        expediente={expediente}
                        loading={loading}
                        type={4}
                        props={{
                            pagarCostos: null,
                            puedeJustificar: null,
                            importe: 0,
                            localizadorDocumento: "",
                        }}
                        dialogOpen={signDialogOpen}
                        handleDialogClose={() => handleSignDialog(false)}
                        handleDialogSubmit={handleSignature}
                        lineaAeat={""}
                    />
                </>
                :
                null
            }
        </>
    );
}

export default CumplimientoCompromisosTemplate;