import React, { useCallback, useContext } from 'react';
import { Typography, MenuItem, Menu, IconButton, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { RouteComponentProps, withRouter } from 'react-router';
import Icon from '@mdi/react';
import { mdiLogoutVariant, mdiDotsVertical } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import SedeAppBar from './app-bar';
import Term from './term';
import styles from '../../resources/styles/layout.styles';
import PageTitle from './page-title';
import clsx from 'clsx';
import { PageContext } from '../../contexts/app.context';
import { Link } from 'react-router-dom';

type Props = WithStyles<typeof styles> & RouteComponentProps;

const Header: React.FC<Props> = ({ classes, history }) => {
    const { t } = useTranslation('global');
    const [pageState, pageDispatcher] = useContext(PageContext);
    const isLogged = pageState.jwp !== null;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openSubMenuAnchorEl, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleLogout = useCallback(() => {
        const token = pageState.jwt !== null && 'accessToken' in pageState.jwt && pageState.jwt.accessToken !== null ? pageState.jwt.accessToken : null
        if (token !== null) {
            pageDispatcher({ type: 'cleanExpediente' });
            pageDispatcher({ type: 'logout' });
            const returnPath = document.location.pathname + document.location.search + document.location.hash;
            const loginPath = '/?return=' + encodeURIComponent(returnPath);
            history.push(loginPath);
        }
    }, [pageState, history, pageDispatcher]);

    const handleOpenLang = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseLang = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleToggleSubMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpenSubMenuAnchorEl(event.currentTarget);
        pageDispatcher({ type: 'open-nav-right' })
    }, [pageDispatcher]);

    const handleCloseSubMenu = useCallback(() => {
        setOpenSubMenuAnchorEl(null);
        pageDispatcher({ type: 'close-nav-right' })
    }, [pageDispatcher]);

    const handleChangeLang = useCallback(async (event: React.MouseEvent, lang: string) => {
        event.preventDefault();
        event.stopPropagation();
        i18next.changeLanguage(lang);
        pageDispatcher({
            type: 'change-lang',
            lang
        });
        handleCloseLang();
    }, [pageDispatcher, handleCloseLang]);

    return (
        <>
            <SedeAppBar openMenu={handleToggleSubMenu} />
            <div className={clsx(classes.desktopContainer, classes.mobileContainer)}>
                <Grid container className={classes.sedeHeader}>
                    <Grid item className={classes.containerLogo}>
                        <a href="https://www.atib.es">
                            <img className={classes.logoSedeIni} src={require('../../resources/images/iconoAtibSuperior.png').default} alt='logo-sede' width='100%' height='auto' />
                        </a>
                        <Link to="/" className={classes.titleIni}>
                            <span className={classes.textSede}>
                                <Term text="sede_electronica" />
                            </span>
                            <img className={classes.logoSede} src={require('../../resources/images/logo-sede.png').default} alt='logo-sede' />
                        </Link>
                    </Grid>
                    <IconButton color="inherit" onClick={handleToggleSubMenu} className={classes.showInMobile}>
                        <Icon path={mdiDotsVertical} title="Opciones" color="#fff" size={1} />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        className={classes.showInMobile}
                        anchorEl={openSubMenuAnchorEl}
                        keepMounted
                        open={pageState.navRightOpen}
                        onClose={handleCloseSubMenu}
                    >
                        <MenuItem onClick={(event) => handleChangeLang(event, 'ca')}>
                            <span style={{ color: 'dimgrey' }}>Català</span>
                        </MenuItem>
                        <MenuItem onClick={(event) => handleChangeLang(event, 'es')}>
                            <span style={{ color: 'dimgrey' }}>Castellano</span>
                        </MenuItem>
                        {
                            isLogged ?
                                <MenuItem onClick={handleLogout}>
                                    <Icon
                                        path={mdiLogoutVariant}
                                        title={t('global:cerrar_sesion')}
                                        size={1}
                                        className={classes.iconTextLog}
                                        color="dimgrey"
                                    />
                                    <span style={{ color: 'dimgrey' }}><Term text="cerrar_sesion" /></span>

                                </MenuItem>
                                :
                                null
                        }
                    </Menu>
                    <Grid item className={classes.containerLanguage}>
                        <Button aria-controls="change-lang" aria-haspopup="true" onClick={handleOpenLang} className={classes.textSedeIdioma}>
                            {pageState.lang === 'es' ? 'Castellano' : 'Català'}
                        </Button>
                        <Menu
                            id="change-lang"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseLang}
                        >
                            <MenuItem onClick={(event) => handleChangeLang(event, 'ca')}>Català</MenuItem>
                            <MenuItem onClick={(event) => handleChangeLang(event, 'es')}>Castellano</MenuItem>
                        </Menu>
                    </Grid>

                    {
                        isLogged
                            ? <Grid item className={classes.closeSessionContainer}>
                                <Button className={classes.closeSessionButton} onClick={handleLogout}>
                                    <Icon
                                        path={mdiLogoutVariant}
                                        title={t('global:cerrar_sesion')}
                                        size={1}
                                        className={classes.iconTextLog}
                                        color="white"
                                    />
                                    <Term text="cerrar_sesion" />
                                </Button>
                            </Grid>
                            :
                            null
                    }
                </Grid>
                {
                    <div className={classes.headerWrap}>
                        <Grid container className={classes.headerContainer} alignItems="center">
                            <Grid item className={classes.logoContainer}>
                                <a className={[classes.withoutLink, classes.zIndex].join(' ')} href="https://www.caib.es/" target="_blank" rel="noopener noreferrer">
                                    <img src={require('../../resources/images/goib.png').default} alt='logo' />
                                </a>
                            </Grid>
                            <Grid item className={classes.titleContainer}>
                                <PageTitle />
                            </Grid>
                            {
                                pageState.jwp != null ?
                                    <Grid item className={classes.textHeaderContainer}>

                                        <Typography className={classes.textHeader}>
                                            {pageState.jwp.fullname}
                                        </Typography>
                                        {
                                            pageState.jwp.idSujetoRepresentado !== null && pageState.jwp.nifRepresentado !== pageState.jwp.nif &&
                                            <Typography>
                                                {pageState.jwp.nifRepresentado} - {pageState.jwp.nombreRepresentado}
                                            </Typography>
                                        }
                                    </Grid>
                                    : null
                            }
                        </Grid>
                    </div>
                }
            </div>
        </>
    )
}

export default withRouter(withStyles(styles)(Header));