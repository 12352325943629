import { FC, useContext } from 'react';
import { Hidden, Drawer, WithStyles, withStyles, Button, SwipeableDrawer } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from '../../resources/styles/menu.styles';
import Term from './term';
import { useCallback } from 'react';
import { PageContext } from '../../contexts/app.context';

type Props = WithStyles<typeof styles> & RouteComponentProps;

// const menuItems: any = {
//     beneficiarios: 0,
//     requisitos: null,
//     importe_ayudas: null,
//     compromisos_beneficiarios: null,
//     responsabilidades: null,
//     procedimiento: null,
//     normativa_reguladora: null,
//     dudas: null,
// }

const ContentMenu: FC<Props> = ({ classes, history }) => {
    // Local states
    // const [activeItem, setActiveItem] = useState<string | null>('beneficiarios');
    const [pageState, pageDispatcher] = useContext(PageContext);
    const handleClose = useCallback(() => pageDispatcher({ type: 'close-nav-left' }), [pageDispatcher]);
    const handleOpen = useCallback(() => pageDispatcher({ type: 'open-nav-left' }), [pageDispatcher]);

    const handleStart = useCallback(() => {
        history.push('/clave');
    }, [history]);

    const menuList = (
        <Button className={classes.rounded} onClick={handleStart} color="primary" variant="contained">
            <Term text="iniciar_solicitud" />
        </Button>);

    return (
        <nav className={classes.drawer}>
            <Hidden smDown implementation="css">
                <Drawer
                    classes={{ paper: classes.drawerPaper }}
                    variant="permanent"
                    open>
                    {menuList}
                </Drawer>
            </Hidden>

            <Hidden mdUp implementation="css">
                <SwipeableDrawer
                    open={pageState.navLeftOpen}
                    onClose={handleClose}
                    onOpen={handleOpen}
                >
                    {menuList}
                </SwipeableDrawer>
            </Hidden>
        </nav>
    );
};

export default withRouter(withStyles(styles)(ContentMenu));


