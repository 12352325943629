import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import env from './env';

i18n.use(initReactI18next)
    .use(HttpApi)
    .init({
        debug: process.env.NODE_ENV === 'production' ? false : true,
        lng: 'es',
        backend: {
            loadPath: env.API_URI + 'i18n/{{lng}}/terms/{{ns}}',
            addPath: ((lng: string, ns: string) => {
                return `${env.API_URI}i18n/${lng}/terms/${ns}`;
            }) as any,
        },
        fallbackLng: 'ca',
        defaultNS: 'global',
        whitelist: ['es', 'ca'],
        saveMissing: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
