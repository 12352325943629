import { useCallback, useContext } from "react";
import { PageContext } from "../contexts/app.context";

const useDocumentGateway = () => {
    const [appState] = useContext(PageContext);
    const { axios } = appState;

    return {
        saveDocument: useCallback(
            async (file: File): Promise<string[]> => {
                const formData = new FormData();
                formData.append("files", file);
                const response = await axios.post(
                    "/documentos/upload",
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                if ((response.status !== 200 && response.status !== 201) || typeof response.data === 'string') {
                    throw new Error(response.data);
                }
                return response.data[0];
            },
            [axios]
        ),
    };
};

export default useDocumentGateway;
