import moment from "moment";
import { EstadoExpediente } from "../../gateways/interfaces/expediente.interface";

export const resolverPaso = (idEstado: number, puedeJustificar?: boolean) => {
  switch (idEstado) {
    case EstadoExpediente.Rechazada:
      if (puedeJustificar) {
        return 2;
      } else {
        return 1;
      }
    case EstadoExpediente.PendienteDocu:
    case EstadoExpediente.PendienteFirma:
    case EstadoExpediente.PendienteVali:
    case EstadoExpediente.PendienteValiDocu:
    case EstadoExpediente.PendienteCalc:
    case EstadoExpediente.RechazoPend:
    case EstadoExpediente.PendientePublicacion:
    case EstadoExpediente.Desistimiento:
      return 1;    
    case EstadoExpediente.Aprobada:
      if (moment() > moment('20210816')) {
        return 2
      } else {
        return 1
      }
    case EstadoExpediente.PagosJustificados:
    case EstadoExpediente.CumplimientoCompromisos:
      return 3;
    default:
      return 0;
  }
}

export const dispatch = (idEstado: number, history: any, puedeJustificar?: boolean) => {
  switch (idEstado) {
    case EstadoExpediente.Rechazada:
      if (puedeJustificar) {
        history.push("/expediente/justificantes");
        break;
      } else {
        history.push("/expediente/resultado");
        break;
      }
    case EstadoExpediente.PendienteDocu:
    case EstadoExpediente.PendienteFirma:
    case EstadoExpediente.PendienteVali:
    case EstadoExpediente.PendienteValiDocu:
    case EstadoExpediente.PendienteCalc:
    case EstadoExpediente.RechazoPend:
    case EstadoExpediente.PendientePublicacion:
    case EstadoExpediente.Desistimiento:
      history.push("/expediente/resultado");
      break;
    case EstadoExpediente.PagosJustificados:    
    case EstadoExpediente.Aprobada:
      if (moment() > moment('20210816')) {
        history.push("/expediente/justificantes");
      } else {
        history.push("/expediente/resultado");
      }
      break;
    case EstadoExpediente.CumplimientoCompromisos:
        history.push("/expediente/cumplimiento");
        break;
    default:
      history.push("/expediente/solicitud");
      break;
  }
};
