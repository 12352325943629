import { StyleRules, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules => ({
    list: {
        width: 'auto',
    },
    fullList: {
        width: 'auto',
    },
    spacingLogo: {
        marginTop: 25,
        marginBottom: 10,
        textAlign: 'center',
    },
    drawer: {
        zIndex: 900,
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
            marginTop: 230,
        },
    },
    drawerPaper: {
        top: 230,
        width: 300,
        borderRight: 'none',
        paddingTop: 25,
        paddingLeft: 15,
    },
    bold: {
        fontWeight: 'bold',
        fontSize: 15
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    textLogo: {
        marginRight: '55px',
        color: '#004F84',
        marginBottom: '25px'
    },
    logoMenuLateral: {
        marginBottom: '-23px',
        marginTop: '15px',
        width: '60%'
    },
    active: {
        fontWeight: 'bold',
        fontSize: 18,
        color: 'blue',
    },
    normal: {
        textDecoration: 'none',
        fontSize: 18,
        color: 'black',
    },
    rounded: {
        borderRadius: 15,
        margin: '16px 0',
        width: 215,
    },
    [theme.breakpoints.up('md')]: {
        subMenu: {
            '& a, button': {
                paddingLeft: 40,
            }
        },
        list: {
            width: 'auto',
        },
        drawerPaper: {
            top: 230,
            height: 'calc(100% - 230px)',
            width: 300,
            borderTopRightRadius: 8
        },
        hideInDesk: {
            display: 'none',
        }
    }
});

export default styles;