import { Grid } from "@material-ui/core";
import { FC, useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CardHome from "../components/organisms/card-home";
import { PageContext } from "../contexts/app.context";
import MainLayout from "../components/templates/_main.layout";
import useGlobalStyles from "../resources/styles/global.styles";
import { ConfigContext } from "../contexts/config.context";

type Props = RouteComponentProps;

export const HomePage: FC<Props> = ({ history }) => {
    const { t } = useTranslation('solicitud');
    const classes = useGlobalStyles();
    const [, pageDispatcher] = useContext(PageContext);
    const { FORMULARIO_ACTIVO, URL_SEDE_CVA, GESTION_PODERES } = useContext(ConfigContext);
    // Events
    const handleNavigate = useCallback((ruta: string) => {
        if (ruta.startsWith('https://')) {
            document.location.href = ruta;
        } else {
            history.push(ruta);
        }
    }, [history]);

    useEffect(() => {
        pageDispatcher({
            type: 'setMenu',
            menu: false,
            menuContent: false,
        });
    }, [pageDispatcher]);

    return (
        <MainLayout>
            <Grid className={classes.rootCards} container spacing={2}>
                <Grid item xs={12} sm={12} md={3} className={classes.rootGridContent}>
                    <CardHome
                        titulo={t('solicitud:card1_titulo')}
                        contenido={t('solicitud:card1_contenido')}
                        accionable={true}
                        imagen='imagen1'
                        onClick={() => handleNavigate('/requisitos')} />
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={classes.rootGridContent}>
                    <CardHome
                        titulo={t('solicitud:card2_titulo')}
                        contenido={t('solicitud:card2_contenido')}
                        accionable={GESTION_PODERES}
                        imagen='imagen2'
                        onClick={() => handleNavigate(`${URL_SEDE_CVA}/tramites/representaciones/gestion-de-representaciones`)} />
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={classes.rootGridContent}>
                    <CardHome
                        titulo={t('solicitud:card3_titulo')}
                        contenido={t('solicitud:card3_contenido')}
                        accionable={FORMULARIO_ACTIVO}
                        imagen='imagen3'
                        onClick={() => handleNavigate('/seleccion')} />
                </Grid>
            </Grid>
        </MainLayout>
    );
}