import { IStatus, ISteper } from "../gateways/interfaces/steper.interface"

const listSteps: ISteper[] = [
    {
        index: 0,
        name: 'Solicitud',
        status: IStatus.Opened,
        url: '/expediente/solicitud',
    },
    {
        index: 1,
        name: 'Resultado de la solicitud',
        status: IStatus.Blocked,
        url: '/expediente/resultado'
    },
    {
        index: 2,
        name: 'Justificantes',
        status: IStatus.Blocked,
        url: '/expediente/justificantes'
    },
    {
        index: 3,
        name: 'cumplimiento_compromisos',
        status: IStatus.Blocked,
        url: '/expediente/cumplimiento'
    },
]

export function MenuSteps(paso: number) {
    for (const step of listSteps) {
        if (paso >= step.index) {
            step.status = IStatus.Opened;
        }
    }
    return listSteps;
}