import { FC, useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogContentText, Divider, DialogTitle, unstable_createMuiStrictModeTheme as createMuiTheme, MuiThemeProvider, makeStyles } from "@material-ui/core";
import Term from './term';
import { Icon } from '@mdi/react';
import { mdiAlertBoxOutline } from '@mdi/js';
import { AlertsContext } from '../../contexts/alerts.context';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    content: {
        margin: 5,
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
        marginLeft: 10,
        marginBottom: -8,
    }
}));

const formLabelsTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            h6: {
                fontSize: 32,
                color: '#004f84',
                fontFamily: 'Roboto',
                fontWeight: 'lighter',
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#2196f3',
            }
        }
    }
})

const AlertDialog: FC = () => {
    const classes = useStyles();
    const [alerts, alertsDispatch] = useContext(AlertsContext);
    const [color, setColor] = useState('red');
    const dialogRef = useRef();


    useEffect(() => {
        if (alerts.alert) {
            switch (alerts.alert.variant) {
                case 'success':
                    setColor('green');
                    break;
                case 'warning':
                    setColor('#004f84');
                    break;
                case 'info':
                    setColor('blue');
                    break;
                default:
                    setColor('red');
                    break;
            }
        }
    }, [alerts.alert]);

    const handleClose = useCallback(() => {
        alertsDispatch({ type: 'hide-alert' });
    }, [alertsDispatch]);

    return <MuiThemeProvider theme={formLabelsTheme}>
        <Dialog
            ref={dialogRef}
            open={alerts.alert?.open === true}
        >
            <DialogTitle>
                <Term text="sede_electronica" />
                <Icon path={mdiAlertBoxOutline}
                    size={"42px"}
                    color={color}
                    className={classes.iconAlert}
                />
            </DialogTitle>
            {
                <DialogContent className={classes.content}>
                    {
                        alerts.alert && (
                            alerts.alert.isHtml === true && typeof alerts.alert.message === 'string'
                                ? <div dangerouslySetInnerHTML={{ __html: alerts.alert.message }} />
                                : <DialogContentText id="alert-dialog-description">
                                    {alerts.alert.message}
                                </DialogContentText>
                        )
                    }
                </DialogContent>
            }
            <Divider />
            <DialogActions style={{ color: '#2196f3' }}>
                <Button
                    onClick={handleClose}
                    color="primary"
                    autoFocus
                >
                    <Term text="cerrar" />
                </Button>
            </DialogActions>
        </Dialog>
    </MuiThemeProvider>
};

export default AlertDialog;