import { useCallback, useContext } from "react";
import { PageContext } from "../contexts/app.context";

const useContentsGateway = () => {
    const [appState] = useContext(PageContext);
    const { axios } = appState;

    return {
        fetchContent: useCallback(
            async (id: string): Promise<string> => {
                try {
                    const response = await axios.get(`/i18n/${appState.lang}/contents/${id}`);
                    return response.data;
                } catch (e) {
                    throw new Error('an error ocurred fetching i18n content: ' + e.toString());
                }
            },
            [axios, appState.lang],
        ),
    };
};

export default useContentsGateway;