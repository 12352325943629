import { Button, Divider, Grid, Card, CardContent } from "@material-ui/core";
import moment from "moment";
import { FC, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../contexts/config.context";
import { IExpediente } from "../../gateways/interfaces/expediente.interface";
import { ISujeto } from "../../gateways/interfaces/sujeto.interfaces";
import useStyles from "../../resources/styles/solicitud.styles";
import { PrettierText } from "../atoms/prettier-text.atom";
import Contestador from "./contestador";
import listadoDeclaraciones from "./declaraciones";

interface IProps {
    expediente: IExpediente;
    step: number;
    sujeto: ISujeto,
    body: string,
    onChange: (value: IExpediente) => void;
    changeStep: (step: number, block: boolean) => void;
    onSubmit: (value: IExpediente) => void;
    onImprimir: () => void;
    onDespresentar: () => void;
}

export const Presentacion: FC<IProps> = ({ expediente, sujeto, onSubmit, onImprimir, onDespresentar }) => {
    const { t } = useTranslation("solicitud");
    const classes = useStyles();
    const { FECHA_FIN } = useContext(ConfigContext);

    const declaraciones = useMemo(() => listadoDeclaraciones.filter(x => (expediente.detalles || []).findIndex(c => c.codigo === x.casilla) >= 0), [expediente]);
    const declaracionesAuto = useMemo(() => declaraciones.filter(x => x.auto), [declaraciones]);
    const declaracionesNoAuto = useMemo(() => declaraciones.filter(x => !x.auto), [declaraciones]);

    const handleImprimir = useCallback(() => {
        if (expediente.id) {
            onImprimir();
        }
    }, [onImprimir, expediente])

    const handleContinuar = useCallback(() => {
        onSubmit(expediente);
    }, [expediente, onSubmit])

    const handleDespresentar = useCallback(() => {
        onDespresentar();
    }, [onDespresentar])

    const handleIrExpedienteSede = useCallback(() => {
        document.location.href = 'https://sede.atib.es/cva/tramites/presentaciones';
    }, [])

    return (
        <div>
            <Card style={{ flex: 1 }}>
                <CardContent>
                    <div style={{ paddingLeft: 25, paddingTop: 25 }}>
                        <div style={{ display: "flex", marginBottom: 25 }}>
                            <h3 style={{ color: '#004f82', fontWeight: 400, fontSize: 24 }}>{t("solicitud:presentacion")}</h3>
                        </div>
                        <div style={{ display: "flex", marginTop: 15 }}>
                            <h4 style={{ color: '#004f84' }}>{t("solicitud:solicitante")}</h4>
                        </div>
                        <Divider style={{ marginBottom: 10 }} />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid item xs={12} md={3}>
                                <span>{t('solicitud:nombre')}:</span>
                                <b>&nbsp;&nbsp;{sujeto.nombre}</b>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <span>{t('solicitud:nif')}:</span>
                                <b>&nbsp;&nbsp;{sujeto.nif}</b>
                            </Grid>
                            {
                                (sujeto.nifRepresentante !== '' && sujeto.nifRepresentante !== null && sujeto.nifRepresentante !== sujeto.nif) &&
                                <>
                                    <Grid item xs={12} md={3}>
                                        <span>{t('solicitud:nombre_representante')}:</span>
                                        <b>&nbsp;&nbsp;{sujeto.nombreRepresentante}</b>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <span>{t('solicitud:nif_representante')}:</span>
                                        <b>&nbsp;&nbsp;{sujeto.nifRepresentante}</b>
                                    </Grid>
                                </>
                            }
                            {
                                expediente.detalles?.filter(x => x.origen === 'solicitante' && (x.codigo !== '5' && x.codigo !== '6' && x.codigo !== '8' && x.codigo !== '9')).map((y, i) => (
                                    <Grid item xs={12} md={3} key={'datos_solicitante_' + i}>
                                        <span>{t('solicitud:casillero' + y.codigo)}:</span>
                                        <b>&nbsp;&nbsp;{y.valor}</b>
                                    </Grid>
                                ))
                            }
                        </Grid>

                        <div style={{ marginTop: 25, marginBottom: 25 }}>
                            <h4 style={{ color: '#004f84' }}>{t("solicitud:declara_responsablemente")}</h4>

                            <PrettierText text={t('solicitud:texto_declaracion_previa')} />
                        </div>
                        <Divider style={{ marginBottom: 10 }} />
                        <div style={{ display: "flex", marginBottom: 15 }}>
                            <div>
                                {
                                    declaracionesAuto.map((y, i) => (
                                        <div key={'declara_responsablemente_auto_' + i} >

                                            <Contestador
                                                opciones={[]}
                                                onAnswer={() => { }}
                                                expediente={expediente}
                                                pregunta={y.declaracion}
                                                opcion={{ label: '', value: '' }}
                                            />
                                        </div>
                                    ))
                                }
                                {
                                    declaracionesNoAuto.map((y, i) => (
                                        <div key={'declara_responsablemente_no_auto_' + i} >

                                            <Contestador
                                                opciones={[]}
                                                onAnswer={() => { }}
                                                expediente={expediente}
                                                pregunta={y.declaracion}
                                                opcion={{ label: '', value: '' }}
                                            />
                                        </div>
                                    ))
                                }
                                {
                                    expediente.id &&
                                    <Contestador
                                        opciones={[]}
                                        onAnswer={() => { }}
                                        expediente={expediente}
                                        pregunta={expediente.detalles?.find(x => x.codigo === '70' && x.valor === '1') !== undefined ? 'solicitud:casillero70_1' : 'solicitud:casillero70_0'}
                                        opcion={{ label: '', value: '' }}
                                    />
                                }
                            </div>
                        </div>

                    </div>
                </CardContent>
            </Card>
            <Grid container className={classes.rootFooter} >
                <Grid item xs={6} >
                </Grid>
                <Grid item xs={12} md={6} className={classes.footerContainer}>
                    <div className={classes.wrapper}>
                        {
                            expediente.id &&
                            <Grid container>
                                <div style={{ flexGrow: 1 }}></div>
                                {expediente.detalles?.find(x => x.codigo === '70' && x.valor === '0') !== undefined &&
                                    <Grid item xs={6} md={'auto'}>
                                        <Button style={{ margin: 10 }} disabled={expediente.id === undefined} variant="contained" color="default" onClick={handleIrExpedienteSede}>
                                            {t('solicitud:ir_expediente')}
                                        </Button>
                                    </Grid>
                                }
                                {!(FECHA_FIN !== null && moment.utc(FECHA_FIN) < moment.utc()) &&
                                    <Grid item xs={6} md={'auto'}>
                                        <Button style={{ margin: 10 }} disabled={expediente.id === undefined} variant="contained" color="secondary" onClick={handleDespresentar}>
                                            {t('solicitud:despresentar_expediente')}
                                        </Button>
                                    </Grid>}
                                <Grid item xs={6} md={'auto'}>
                                    <Button style={{ margin: 10 }} disabled={expediente.id === undefined} variant="contained" color="primary" onClick={handleImprimir}>
                                        {t('solicitud:descargar_justificante')}
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                        {
                            !expediente.id &&
                            <Button disabled={expediente.id !== undefined} variant="contained" color="primary" onClick={handleContinuar}>
                                {t('solicitud:presentar')}
                            </Button>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Presentacion;
