import { FC, useState, useCallback } from 'react';
import { Dialog, Button, DialogActions, DialogContent, Divider, makeStyles, TextField, DialogTitle } from "@material-ui/core";
import Term from '../term';
import { useTranslation } from 'react-i18next';
import { InputChangeHandler } from '../../../utils/events';
import { NumberFormatCustom } from '../number-format.custom';
import { DialogFormProps, DialogoWorkflow } from '../workflow/interfaces';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    width: {
        width: '100%'
    },
    content: {
        margin: 5,
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
        marginLeft: 10,
        marginBottom: -8,
    }
}));


const DialogCasilla900: FC<DialogFormProps> = ({ expediente, onChange, onCloseWithoutChanges }) => {
    const { t } = useTranslation(["solicitud", "error"]);
    const classes = useStyles();
    const [importe, setImporte] = useState('');
    const [error, setError] = useState(false);

    const handleChangeImporte: InputChangeHandler = useCallback((event) => {
        if (parseFloat(event.target.value) <= 0) {
            setError(true);
        } else {
            setError(false);
        }
        setImporte(event.target.value);
    }, [])


    const handleChange = useCallback(() => {
        if (!error) {
            onChange({
                ...expediente,
                detalles: [
                    ...expediente.detalles ?? [],
                    {
                        codigo: '50',
                        origen: 'actividad-economica',
                        valor: importe,
                    }
                ]
            });
        } else {
            return;
        }

    }, [expediente, importe, onChange, error]);

    return <>
        <Dialog
            maxWidth='xs'
            fullWidth={true}
            open={true}
        >
            <DialogTitle>{t("solicitud:title_modal_900")}</DialogTitle>
            <DialogContent className={classes.content}>
                <TextField
                    label={t('casillero_900')}
                    className={classes.width}
                    onChange={handleChangeImporte}
                    error={error}
                    helperText={!error ? (parseFloat(importe) > 1800000 ? t('solicitud:casillero50_info') : '') : t('error:error_casilla50')}
                    value={importe}
                    InputProps={{
                        inputComponent: NumberFormatCustom as any,
                    }}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onCloseWithoutChanges}
                    color="default"
                >
                    <Term text="cerrar" />
                </Button>
                <Button
                    onClick={handleChange}
                    disabled={importe === '' || error}
                    color="primary"
                    variant="contained"
                >
                    <Term text="confirmar" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

const DialogCasilla900Workflow: DialogoWorkflow = {
    Component: DialogCasilla900,
    onResponse: (expediente) => {
        const actividadEconomica = [...(expediente.actividadEconomica || [])];

        actividadEconomica.forEach(x => {
            if (x.pregunta === 'solicitud:pregunta_solicitado_ayudas') {
                x.opcionSeleccionada = {
                    value: 'respuesta-dialogo-900',
                    label: 'solicitud:importe_casilla50',
                };
            }
        })

        return {
            ...expediente,
            actividadEconomica,
        }
    },
}

export default DialogCasilla900Workflow;