import { IDetalleExpediente, IExpediente } from "../../../gateways/interfaces/expediente.interface";
import { WorkflowTask, WorkflowConnector, WorkflowSetCasilla } from "./interfaces";

export type WorkflowStatus = "unstarted" | "started" | "finished";

export type WorkflowUserTaskCallback = (
  task: WorkflowTask,
  next: () => void,
  back: () => void,
) => void;

export class WorkflowEngine {
  public context: IExpediente | null = null;
  public status: WorkflowStatus = "unstarted";
  public startTask: WorkflowTask;
  public activeTask!: WorkflowTask;

  private tasksExecuted: WorkflowTask[] = [];
  private userTaskCallback: WorkflowUserTaskCallback | null = null;
  private endCallback: (() => void) | null = null;
  private backCallback: (() => void) | null = null;
  private contextChangeCallback: ((context: IExpediente) => void) | null = null;

  constructor(
    public tasks: WorkflowTask[],
    public connectors: WorkflowConnector[]
  ) {
    const startTask = tasks.filter((x) => x.type === "start");

    if (startTask.length === 0) {
      throw new Error("Start task is required");
    } else if (startTask.length > 1) {
      throw new Error("Multiples start tasks are not allowed");
    }

    this.startTask = startTask[0];

    this.reset();
  }

  updateContext(context: IExpediente) {
    this.context = context;
  }

  reset() {
    this.status = "unstarted";
    this.activeTask = this.startTask;
    this.tasksExecuted = [];
  }

  run() {
    if (this.status !== "unstarted") {
      return;
    }

    this.status = "started";
    this.executeActiveTask();
  }

  private executeActiveTask() {
    switch (this.activeTask.type) {
      case "start":
      case "exclusive-gateway":
        this.continue(this.getNextTask());
        break;
      case "set-casilla":
        this.updateCasilla();
        this.continue(this.getNextTask());
        break;
      case "pregunta":
      case "dialogo-fin":
      case "dialogo-form":
        if (this.userTaskCallback) {
          this.userTaskCallback(
            this.activeTask,
            () => this.continue(this.getNextTask()),
            () => this.back(),
          );
        } else {
          throw new Error(
            `Expected userTaskCallback to process question task type`
          );
        }
        break;
      case "end":
        this.status = "finished";
        this.endCallback && this.endCallback();
        return;
      default:
        throw new Error(`Unknown task type ${(this.activeTask as any).type}`);
    }
  }

  private updateCasilla() {
    const { casilla, valor } = this.activeTask as WorkflowSetCasilla;
    if (!this.context) {
      throw new Error('Cant update context, is not defined');
    }
    const detalles: IDetalleExpediente[] = [
      ...(this.context.detalles || []).filter(x => x.codigo !== casilla),
      { codigo: casilla, valor, origen: 'actividad-economica' },
    ];

    this.context.detalles = detalles;
    this.contextChangeCallback && this.contextChangeCallback(this.context);
  }

  private back() {
    if (this.tasksExecuted.length <= 1) {
      return;
    }
    const [previousTask] = this.tasksExecuted.splice(this.tasksExecuted.length - 1, 1);
    this.activeTask = previousTask;

    if (this.activeTask.type === 'exclusive-gateway') {
      this.back();
      return;
    }

    this.backCallback && this.backCallback();

    if (this.activeTask.type === 'set-casilla') {
      this.back();
      return;
    }

    this.executeActiveTask();
  }

  private continue(task: WorkflowTask | null) {
    if (task) {
      this.tasksExecuted.push(this.activeTask);
      this.activeTask = task;
      this.executeActiveTask();
    }
  }

  private getTaskById(id: string) {
    const task = this.tasks.find((x) => x.id === id);
    if (!task) {
      throw new Error(`Task "${id}" not found`);
    }
    return task;
  }

  private getNextTask() {
    if (this.context === null) {
      throw new Error("Context expected on getNextTask");
    }
    const possibleConnectors = this.connectors.filter(
      (x) => x.from === this.activeTask.id
    );

    if (possibleConnectors.length === 0) {
      throw new Error(`Not found connectors from ${this.activeTask.id}`);
    }

    const connectorsOrdered: WorkflowConnector[] = [
      ...possibleConnectors.filter(x => !!x.condition),
      ...possibleConnectors.filter(x => !!!x.condition),
    ]

    for (const connector of connectorsOrdered) {
      if (!connector.condition) {
        return this.getTaskById(connector.to);
      } else {
        if (connector.condition(this.context)) {
          return this.getTaskById(connector.to);
        }
      }
    }

    throw new Error(
      `Not found any viable connector from ${this.activeTask.id}`
    );
  }

  onUserTask(callback: WorkflowUserTaskCallback) {
    this.userTaskCallback = callback;
  }

  onEnd(callback: () => void) {
    this.endCallback = callback;
  }

  onBack(callback: () => void) {
    this.backCallback = callback;
  }

  onContextChange(callback: (context: IExpediente) => void) {
    this.contextChangeCallback = callback;
  }
}
