import { Button } from '@material-ui/core';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IOpcion } from '../../gateways/interfaces/opcion.interfaces';

interface IProps {
    opciones: IOpcion[];
    onAnswer: (valor: IOpcion) => void;
}

const Preguntas: React.FC<IProps> = ({ opciones, onAnswer }) => {
    const { t } = useTranslation();
    const handleSelect = useCallback((opcion: IOpcion) => {
        onAnswer(opcion);
    }, [onAnswer])

    return (
        <>
            <div style={{ display: 'flex' }}>
                &nbsp;&nbsp;
                {
                    opciones.map((x, index) => (
                        <Button key={index}
                            translate="no"
                            style={{ marginRight: 10, marginTop: 10 }}
                            color="default"
                            variant="outlined"
                            onClick={() => handleSelect(x)}>
                            {t(x.label)}
                        </Button>
                    ))
                }
            </div>
        </>
    )
}

export default Preguntas;