import React, { FC, useContext } from "react";
import { Route, RouteProps, RouteComponentProps, withRouter } from "react-router-dom";
import { PageContext } from "../../contexts/app.context";

interface IProps {
    lockComponent?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const ProtectedRoute: FC<IProps & RouteProps & RouteComponentProps> = ({ component, lockComponent, history, ...rest }) => {
    const [pageState] = useContext(PageContext);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (pageState.jwp === null) {
                    const returnPath = document.location.pathname + document.location.search + document.location.hash;
                    const loginPath = '/clave?return=' + encodeURIComponent(returnPath);
                    history.push(loginPath);
                    return lockComponent;
                }

                return pageState.jwp !== null
                    ? React.createElement(component as any, props)
                    : (lockComponent ? lockComponent : null);
            }} />
    );
};

export default withRouter(ProtectedRoute);