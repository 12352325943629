import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import ClaveForm from "../components/organisms/clave-form";
import ClaveTemplate from "../components/templates/clave.template";
import { AlertsContext } from "../contexts/alerts.context";
import useAuthGateway from "../gateways/auth.gateway";
import { PageContext } from "../contexts/app.context";
import useContentsGateway from "../gateways/contents.gateway";
import { ConfigContext } from "../contexts/config.context";

export const ClavePage: FC<RouteComponentProps> = ({ location, history }) => {
    const { t } = useTranslation(['global', 'error']);
    const { fetchContent } = useContentsGateway();
    const { PROBLEMAS_CLAVE } = useContext(ConfigContext);

    // Global states
    const [pageState, pageDispatcher] = useContext(PageContext);
    const [, alertsDispatch] = useContext(AlertsContext);

    // Local states
    const [body, setBody] = useState('');
    const [bodyClave, setBodyClave] = useState('');

    // Services 
    const authGateway = useAuthGateway();

    // Effects
    useEffect(() => {
        (async () => {
            if (PROBLEMAS_CLAVE && bodyClave !== '') {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: bodyClave,
                        variant: 'warning',
                        isHtml: true
                    }
                });
            }
        })();

    }, [PROBLEMAS_CLAVE, alertsDispatch, fetchContent, bodyClave])

    useEffect(() => {
        (async () => {
            setBody(await fetchContent('clave'));
            setBodyClave(await fetchContent('problemas_clave'));
        })();
    }, [fetchContent]);

    useEffect(() => {
        (async () => {
            const values = queryString.parse(location.search);
            const token: string = values.token as string;
            // const returnUrl: string = values.returnUrl as string;
            const error: string = values.error as string;

            pageDispatcher({
                type: 'setMenu',
                menu: false,
            });

            if (token) {
                pageDispatcher({
                    type: 'authenticate', payload: {
                        accessToken: token
                    }
                });
            } else if (error) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: t('error:' + error),
                        variant: 'warning',
                    }
                });
            }

            if (pageState.jwp) {
                history.push('/seleccion');
            }
        })();
    }, [alertsDispatch, history, location.search, pageDispatcher, pageState.jwp, t]);

    const redirectToClave = useCallback(async () => {
        const result = await authGateway.getAuthenticationRequest('/');
        if (result.ok) {
            const claveForm = document.getElementById('claveForm') as HTMLFormElement;
            const relayState = document.getElementById('RelayState') as HTMLInputElement;
            const samlRequest = document.getElementById('SAMLRequest') as HTMLInputElement;

            relayState.value = result.relayState;
            samlRequest.value = result.samlRequest ? result.samlRequest : '';
            claveForm.action = result.provider;
            claveForm.submit();
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: t('error:' + result.message),
                    variant: 'error',
                }
            });
        }
    }, [alertsDispatch, authGateway, t]);

    return (
        <>
            <ClaveTemplate redirectToClave={redirectToClave} body={body} />
            <ClaveForm />
        </>
    );
}