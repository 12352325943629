import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Card, CardContent, Grid } from "@material-ui/core";
import { FC, useCallback, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { IDetalleExpediente, IExpediente } from "../../gateways/interfaces/expediente.interface";
import { ISujeto } from "../../gateways/interfaces/sujeto.interfaces";
import useStyles from '../../resources/styles/solicitud.styles';
import NumberFormatCustomWithoutDecimals from "./number-format.custom-no-decimales";
import NumberFormatCustom from "./number-format.custom-no-euros";


interface IProps {
  expediente: IExpediente;
  sujeto: ISujeto,
  step: number;
  onChange: (value: IExpediente) => void;
  changeStep: (step: number, block: boolean) => void;
}

export const Solicitante: FC<IProps> = ({ expediente, sujeto, step, onChange, changeStep }) => {

  const { t } = useTranslation(['solicitud', 'error', 'direccion']);

  const classes = useStyles();
  const [casillas, setCasillas] = useState(expediente.detalles !== undefined && expediente.detalles?.length > 0 ? expediente.detalles : [])
  const [errorMessageMail, setErrorMessageMail] = useState<string>('');
  const [errorMessageMovil, setErrorMessageMovil] = useState<string>('');
  const [errorMessageCP, setErrorMessageCP] = useState<string>('');
  const [errorMessageSiglas, setErrorMessageSiglas] = useState<string>('');
  const [errorMessageCalle, setErrorMessageCalle] = useState<string>('');
  const [email, setEmail] = useState(expediente.detalles?.find(x => x.codigo === '10')?.valor ?? '');
  const [movil, setMovil] = useState(expediente.detalles?.find(x => x.codigo === '11')?.valor ?? '');
  const [cp, setCP] = useState(expediente.detalles?.find(x => x.codigo === '12')?.valor ?? '');
  const [calle, setCalle] = useState(expediente.detalles?.find(x => x.codigo === '990')?.valor ?? '');
  const [numero, setNumero] = useState(expediente.detalles?.find(x => x.codigo === '991')?.valor ?? '');
  const [letra, setLetra] = useState(expediente.detalles?.find(x => x.codigo === '992')?.valor ?? '');
  const [escalera, setEscalera] = useState(expediente.detalles?.find(x => x.codigo === '993')?.valor ?? '');
  const [piso, setPiso] = useState(expediente.detalles?.find(x => x.codigo === '994')?.valor ?? '');
  const [puerta, setPuerta] = useState(expediente.detalles?.find(x => x.codigo === '995')?.valor ?? '');
  const [siglas, setSiglas] = useState(expediente.detalles?.find(x => x.codigo === '996')?.valor ?? '');
  const [km, setKm] = useState(expediente.detalles?.find(x => x.codigo === '997')?.valor ?? '');

  const handleChangeExpediente = useCallback((expedienteAux: IExpediente) => {
    onChange(expedienteAux);
  }, [onChange]);

  const handleChangeKM = useCallback((valor: string) => {
    setKm(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '997');
    casillasAux.push({ origen: 'solicitante', codigo: '997', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
  }, [handleChangeExpediente, expediente, casillas]);

  const handleValidateSiglas = useCallback((siglas: string) => {
    if (siglas === undefined || siglas === '') {
      setErrorMessageSiglas(t('error:error_siglas'));
      return false;
    }
    setErrorMessageSiglas('');
    return true;
  }, [t])

  const handleValidateCalle = useCallback((calle: string) => {
    if (calle === undefined || calle === '') {
      setErrorMessageCalle(t('error:error_calle'));
      return false;
    }
    setErrorMessageCalle('');
    return true;
  }, [t])

  const handleValidateCP = useCallback((cp: string) => {
    const regexCP = /^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/;
    if (cp === undefined || cp === '' || !regexCP.test(cp)) {
      setErrorMessageCP(t('error:error_cp'));
      return false;
    }
    setErrorMessageCP('');
    return true;
  }, [t])

  const handleValidateMovil = useCallback((movil: string) => {
    if (movil === undefined || movil === '') {
      setErrorMessageMovil(t('error:error_telefono'));
      return false;
    } else if (!isValidPhoneNumber(movil)) {
      setErrorMessageMovil(t('error:error_telefono'));
      return false;
    }
    setErrorMessageMovil('');
    return true;
  }, [t])

  const handleValidateEmail = useCallback((email: string) => {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === undefined || email === '' || !regexEmail.test(email)) {
      setErrorMessageMail(t('error:error_mail'));
      return false;
    }
    setErrorMessageMail('');
    return true;
  }, [t])

  const handleChangeCP = useCallback((valor: string) => {
    setCP(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '12');
    casillasAux.push({ origen: 'solicitante', codigo: '12', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    if (expediente.worflowAE) {
      onChange({
        ...expediente,
        actividadEconomica: [],
        detalles: (expediente.detalles ?? []).filter(x => x.origen === 'solicitante') && casillasAux,
      });
      expediente.worflowAE && expediente.worflowAE.reset();
    } else {
      handleChangeExpediente({ ...expediente, detalles: casillasAux });
    }
    if (!handleValidateCP(valor)) {
      changeStep(step, true);
    }
  }, [changeStep, expediente, handleChangeExpediente, handleValidateCP, step, casillas, onChange]);

  const handleChangeCalle = useCallback((valor: string) => {
    setCalle(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '990');
    casillasAux.push({ origen: 'solicitante', codigo: '990', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
  }, [handleChangeExpediente, expediente, casillas]);

  const handleChangeNumero = useCallback((valor: string) => {
    setNumero(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '991');
    casillasAux.push({ origen: 'solicitante', codigo: '991', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
  }, [handleChangeExpediente, expediente, casillas]);

  const handleChangeLetra = useCallback((valor: string) => {
    setLetra(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '992');
    casillasAux.push({ origen: 'solicitante', codigo: '992', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
  }, [handleChangeExpediente, expediente, casillas]);

  const handleChangeEscalera = useCallback((valor: string) => {
    setEscalera(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '993');
    casillasAux.push({ origen: 'solicitante', codigo: '993', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
  }, [handleChangeExpediente, expediente, casillas]);

  const handleChangePiso = useCallback((valor: string) => {
    setPiso(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '994');
    casillasAux.push({ origen: 'solicitante', codigo: '994', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
  }, [handleChangeExpediente, expediente, casillas]);

  const handleChangePuerta = useCallback((valor: string) => {
    setPuerta(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '995');
    casillasAux.push({ origen: 'solicitante', codigo: '995', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
  }, [handleChangeExpediente, expediente, casillas]);

  const handleChangeSiglas = useCallback((valor: string) => {
    setSiglas(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '996');
    casillasAux.push({ origen: 'solicitante', codigo: '996', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
  }, [handleChangeExpediente, expediente, casillas]);

  const handleChangeEmail = useCallback((valor: string) => {
    setEmail(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '10');
    casillasAux.push({ origen: 'solicitante', codigo: '10', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
    if (!handleValidateEmail(valor)) {
      changeStep(step, true);
    }
  }, [handleValidateEmail, handleChangeExpediente, changeStep, expediente, step, casillas]);

  const handleChangeTelefono = useCallback((valor: string) => {
    setMovil(valor);
    let casillasAux = casillas;
    casillasAux = casillasAux.filter(x => x.codigo !== '11');
    casillasAux.push({ origen: 'solicitante', codigo: '11', valor } as IDetalleExpediente);
    setCasillas(casillasAux);
    handleChangeExpediente({ ...expediente, detalles: casillasAux });
    if (!handleValidateMovil(valor)) {
      changeStep(step, true);
    }
  }, [handleChangeExpediente, changeStep, handleValidateMovil, expediente, step, casillas]);

  const handleValidate = useCallback(() => {
    setErrorMessageCP('');
    setErrorMessageMovil('');
    setErrorMessageMail('');
    setErrorMessageSiglas('');
    setErrorMessageCalle('');
    let result = true;

    if (!handleValidateMovil(movil)) {
      setErrorMessageMovil(t('error:error_telefono'));
      result = false;
    }
    if (!handleValidateEmail(email)) {
      setErrorMessageMail(t('error:error_mail'));
      result = false;
    }
    if (!handleValidateCP(cp)) {
      setErrorMessageCP(t('error:error_cp'));
      result = false;
    }
    if (!handleValidateSiglas(siglas)) {
      setErrorMessageSiglas(t('error:error_siglas'));
      result = false;
    }
    if (!handleValidateCalle(calle)) {
      setErrorMessageCalle(t('error:error_calle'));
      result = false;
    }
    return result;
  }, [handleValidateMovil, handleValidateEmail, handleValidateCP, handleValidateCalle,
    handleValidateSiglas, t, cp, email, movil, siglas, calle
  ])

  const handleContinuar = useCallback(() => {
    if (handleValidate()) {
      window.scrollTo({ top: 0 });
      changeStep(step + 1, false);
    } else {
      changeStep(step, true);
    }
  }, [handleValidate, changeStep, step])

  useEffect(() => {
    let detalles = casillas;
    detalles = expediente.detalles?.filter(x => x.codigo !== '5' && x.codigo !== '6' && x.codigo !== '8' && x.codigo !== '9') || [];
    detalles.push(
      { codigo: '5', valor: sujeto.nif, origen: 'solicitante' },
      { codigo: '6', valor: sujeto.nombre, origen: 'solicitante' }
    );
    if (sujeto.nifRepresentante && sujeto.nifRepresentante !== sujeto.nif) {
      detalles.push(
        { codigo: '8', valor: sujeto.nifRepresentante, origen: 'solicitante' },
        { codigo: '9', valor: sujeto.nombreRepresentante, origen: 'solicitante' }
      );
    }
    setCasillas(detalles);
    handleChangeExpediente({ ...expediente, detalles: detalles });
    // eslint-disable-next-line
  }, [sujeto])

  return (
    <div>
      <Card style={{ flex: 1 }}>
        <CardContent>
          <div style={{ paddingLeft: 25, paddingTop: 25 }}>

            <div style={{ display: "flex", marginTop: 5, marginBottom: 25 }}>
              <h3 style={{ color: '#004f82', fontWeight: 400, fontSize: 24 }}>{t("solicitud:solicitante")}</h3>
            </div>
            {
              (sujeto.nif !== sujeto.nifRepresentante && sujeto.nifRepresentante !== null && sujeto.nifRepresentante !== '') && <>
                <div style={{ display: "flex" }}>
                  <h4>{t("solicitud:datos_representante")}</h4>
                </div>

                <div className={classes.responsiveDiv}>
                  <TextField
                    label={t("solicitud:nif")}
                    disabled={true}
                    id="nif-solicitante-repre"
                    className={classes.inputPadding}
                    value={sujeto.nifRepresentante || ''}
                    inputProps={{ className: classes.inputLabel }}
                  />
                  <TextField
                    label={t("solicitud:razon_social")}
                    disabled={true}
                    id="nombre-solicitante-repre"
                    className={classes.inputPadding}
                    value={sujeto.nombreRepresentante || ''}
                    inputProps={{ className: classes.inputLabel }}
                    InputLabelProps={{ className: classes.inputValue }}
                  />
                </div>
              </>
            }
            <div style={{ display: "flex" }}>
              <h4>{t("solicitud:datos_solicitante")}</h4>
            </div>
            <div className={classes.responsiveDiv}>
              <TextField
                label={t("solicitud:nif")}
                id="nif-solicitante"
                disabled={true}
                className={classes.inputPadding}
                value={sujeto.nif}
                inputProps={{ className: classes.inputLabel }}
                InputLabelProps={{ className: classes.inputValue }}
              />

              <TextField
                label={t("solicitud:razon_social")}
                id="nombre-solicitante"
                disabled={true}
                className={classes.inputPadding}
                value={sujeto.nombre}
                inputProps={{ className: classes.inputLabel }}
                InputLabelProps={{ className: classes.inputValue }}
              />
            </div>
            <div style={{ display: "flex", paddingTop: 10, paddingBottom: 10 }}>
              <h4>{t("solicitud:domicilio_fiscal")}</h4>
            </div>
            <div className={classes.responsiveDiv}>
              <FormControl className={classes.inputPadding}>
                <InputLabel error={errorMessageSiglas !== ''} id="demo-simple-select-label">{t("solicitud:casillero996")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={siglas}
                  error={errorMessageSiglas !== ''}
                  onChange={(e: any) => handleChangeSiglas(e.target.value)}
                >
                  <MenuItem value={'CL'}>{t('direccion:CL')}</MenuItem>
                  <MenuItem value={'CM'}>{t('direccion:CM')}</MenuItem>
                  <MenuItem value={'AV'}>{t('direccion:AV')}</MenuItem>
                  <MenuItem value={'PZ'}>{t('direccion:PZ')}</MenuItem>
                </Select>
                {
                  errorMessageSiglas &&
                  <FormHelperText error={true}>
                    {errorMessageSiglas}
                  </FormHelperText>
                }
              </FormControl>
              <TextField
                label={t("solicitud:casillero990")}
                disabled={expediente.id !== undefined}
                className={classes.inputPadding}
                error={errorMessageCalle !== ''}
                helperText={errorMessageCalle}
                value={calle}
                onChange={(e) => handleChangeCalle(e.target.value)}
                inputProps={{ className: classes.inputLabel }}
                InputLabelProps={{ className: classes.inputValue }}
              />
              <TextField
                label={t("solicitud:casillero991")}
                disabled={expediente.id !== undefined}
                className={classes.inputPadding}
                value={numero}
                onChange={(e) => handleChangeNumero(e.target.value)}
                InputProps={{
                  className: classes.inputLabel,
                  inputComponent: NumberFormatCustomWithoutDecimals as any,
                }}
                InputLabelProps={{ className: classes.inputValue }}
              />
              <TextField
                label={t("solicitud:casillero997")}
                disabled={expediente.id !== undefined}
                className={classes.inputPadding}
                value={km}
                onChange={(e) => handleChangeKM(e.target.value)}
                InputProps={{
                  className: classes.inputLabel,
                  inputComponent: NumberFormatCustom as any,
                }}
                InputLabelProps={{ className: classes.inputValue }}
              />
              <TextField
                label={t("solicitud:casillero992")}
                disabled={expediente.id !== undefined}
                className={classes.inputPadding}
                value={letra}
                onChange={(e) => handleChangeLetra(e.target.value)}
                inputProps={{ className: classes.inputLabel }}
                InputLabelProps={{ className: classes.inputValue }}
              />
              <TextField
                label={t("solicitud:casillero993")}
                disabled={expediente.id !== undefined}
                className={classes.inputPadding}
                value={escalera}
                onChange={(e) => handleChangeEscalera(e.target.value)}
                inputProps={{ className: classes.inputLabel }}
                InputLabelProps={{ className: classes.inputValue }}
              />
              <TextField
                label={t("solicitud:casillero994")}
                disabled={expediente.id !== undefined}
                className={classes.inputPadding}
                value={piso}
                onChange={(e) => handleChangePiso(e.target.value)}
                inputProps={{ className: classes.inputLabel }}
                InputLabelProps={{ className: classes.inputValue }}
              />
              <TextField
                label={t("solicitud:casillero995")}
                disabled={expediente.id !== undefined}
                className={classes.inputPadding}
                value={puerta}
                onChange={(e) => handleChangePuerta(e.target.value)}
                inputProps={{ className: classes.inputLabel }}
                InputLabelProps={{ className: classes.inputValue }}
              />
              <TextField
                label={t("solicitud:casillero12")}
                disabled={expediente.id !== undefined}
                error={errorMessageCP !== ''}
                helperText={errorMessageCP}
                className={classes.inputPaddingWithoutFlex}
                value={cp}
                onChange={(e) => handleChangeCP(e.target.value)}
                inputProps={{ className: classes.inputLabel }}
                InputLabelProps={{ className: classes.inputValue }}
              />
            </div>

            <div style={{ display: "flex" }}>
              <h4>{t("solicitud:datos_contacto")}</h4>
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label={t("solicitud:casillero10")}
                    id="email-solicitante"
                    fullWidth
                    style={{ marginTop: 4 }}
                    disabled={expediente.id !== undefined}
                    error={errorMessageMail !== ''}
                    helperText={errorMessageMail !== '' ? t(errorMessageMail) : t('motivo_mail')}
                    value={email}
                    onChange={(e) => handleChangeEmail(e.target.value)}
                    inputProps={{ className: classes.inputLabel }}
                    InputLabelProps={{ className: classes.inputValue }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputLabel htmlFor="movil" error={!!errorMessageMovil && errorMessageMovil.length > 0}>{t("solicitud:casillero11")}</InputLabel>
                  <PhoneInput
                    id="movil"
                    name="movil"
                    international
                    defaultCountry="ES"
                    value={movil}
                    onChange={handleChangeTelefono}
                    error={errorMessageMovil}
                  />
                  <FormHelperText error={!!errorMessageMovil && errorMessageMovil.length > 0} >
                    {errorMessageMovil}
                  </FormHelperText>
                </Grid>
                {/* <TextField
                  label={t("solicitud:casillero11")}
                  id="telefono-solicitante"
                  disabled={expediente.id !== undefined}
                  style={{ flex: 1, paddingRight: 35 }}
                  error={errorMessageMovil !== ''}
                  helperText={errorMessageMovil}
                  value={movil}
                  onChange={(e) => handleChangeTelefono(e.target.value)}
                  inputProps={{ className: classes.inputLabel }}
                  InputLabelProps={{ className: classes.inputValue }}
                /> */}
              </Grid>
            </div>

          </div>
        </CardContent>
      </Card>
      <Grid container className={classes.rootFooter}>
        <Grid item xs={6} />
        <Grid item xs={12} md={6} className={classes.footerContainer}>
          <div className={classes.wrapper}>
            <Button disabled={!!expediente.id} variant="contained" color="primary" onClick={handleContinuar}>
              {t('solicitud:continuar')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};


export default Solicitante;
