import { IExpediente } from "../../gateways/interfaces/expediente.interface";
import { IDeclaracion } from "../../gateways/interfaces/solicitud.interfaces";

function validarCasilla(expediente: IExpediente, casilla: string): boolean {
    return expediente.detalles?.find(x => x.codigo === casilla) !== undefined;
}

function checkCasillaTrue(expediente: IExpediente, casilla: string): boolean {
    return expediente.detalles?.find(x => x.codigo === casilla && x.valor === '1') !== undefined;
}

// eslint-disable-next-line
export default [
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '31');
        },
        declaracion: 'solicitud:declaracion1031',
        casilla: '1031',
    },
    {
        auto: false,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '31');
        },
        declaracion: 'solicitud:declaracion2001',
        casilla: '2001',
    },
    {
        auto: false,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '31');
        },
        declaracion: 'solicitud:declaracion2002',
        casilla: '2002',
    },
    {
        auto: false,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '31');
        },
        declaracion: 'solicitud:declaracion2003',
        casilla: '2003',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '32');
        },
        declaracion: 'solicitud:declaracion1032',
        casilla: '1032',
    },
    {
        auto: false,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '33');
        },
        declaracion: 'solicitud:declaracion1033',
        casilla: '1033',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '45')
        },
        declaracion: 'solicitud:declaracion14',
        casilla: '14',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '46')
        },
        declaracion: 'solicitud:declaracion3114',
        casilla: '3114',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '37');
        },
        declaracion: 'solicitud:declaracion15',
        casilla: '15',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '39');
        },
        declaracion: 'solicitud:declaracion16',
        casilla: '16',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '38');
        },
        declaracion: 'solicitud:declaracion1038',
        casilla: '1038',
    },
    {
        auto: false,
        visualizar: undefined,
        declaracion: 'solicitud:declaracion18',
        casilla: '18',
    },
    {
        auto: false,
        visualizar: (expediente: IExpediente) => {
            return !checkCasillaTrue(expediente, '700');
        },
        declaracion: 'solicitud:declaracion19',
        casilla: '19',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '700');
        },
        declaracion: 'solicitud:declaracion20',
        casilla: '20',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '33') && !checkCasillaTrue(expediente, '2000');
        },
        declaracion: 'solicitud:declaracion3021',
        casilla: '3021',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '2000') && checkCasillaTrue(expediente, '42');
        },
        declaracion: 'solicitud:declaracion22',
        casilla: '22',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '2000') && checkCasillaTrue(expediente, '42') && !checkCasillaTrue(expediente, '3050');
        },
        declaracion: 'solicitud:declaracion3053',
        casilla: '3053',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '2000') && !checkCasillaTrue(expediente, '42');
        },
        declaracion: 'solicitud:declaracion21',
        casilla: '21',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '2000') && !checkCasillaTrue(expediente, '42') && checkCasillaTrue(expediente, '1006');
        },
        declaracion: 'solicitud:declaracion1021',
        casilla: '1021',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '2000') && !checkCasillaTrue(expediente, '42') && !checkCasillaTrue(expediente, '1006');
        },
        declaracion: 'solicitud:declaracion1022',
        casilla: '1022',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '2000') && !checkCasillaTrue(expediente, '42') && checkCasillaTrue(expediente, '338');
        },
        declaracion: 'solicitud:declaracion2021',
        casilla: '2021',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '2000') && !checkCasillaTrue(expediente, '42') && !checkCasillaTrue(expediente, '338');
        },
        declaracion: 'solicitud:declaracion2022',
        casilla: '2022',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return (
                validarCasilla(expediente, '32') || validarCasilla(expediente, '33')
            ) && !checkCasillaTrue(expediente, '43');
        },
        declaracion: 'solicitud:declaracion23',
        casilla: '23',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return (
                validarCasilla(expediente, '32') || validarCasilla(expediente, '33')
            ) && checkCasillaTrue(expediente, '43');
        },
        declaracion: 'solicitud:declaracion24',
        casilla: '24',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return (
                validarCasilla(expediente, '32') || validarCasilla(expediente, '33')
            ) && !checkCasillaTrue(expediente, '44') && checkCasillaTrue(expediente, '45');
        },
        declaracion: 'solicitud:declaracion25',
        casilla: '25',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '32') && checkCasillaTrue(expediente, '44');
        },
        declaracion: 'solicitud:declaracion26',
        casilla: '26',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '33') && checkCasillaTrue(expediente, '44');
        },
        declaracion: 'solicitud:declaracion27',
        casilla: '27',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '33') && checkCasillaTrue(expediente, '39') && validarCasilla(expediente, '701');
        },
        declaracion: 'solicitud:declaracion28',
        casilla: '28',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '33') && checkCasillaTrue(expediente, '39') && !validarCasilla(expediente, '701');
        },
        declaracion: 'solicitud:declaracion29',
        casilla: '29',
    }, {
        auto: false,
        visualizar: (expediente: IExpediente) => {
            return !checkCasillaTrue(expediente, '31');
        },
        declaracion: 'solicitud:declaracion53',
        casilla: '53',
    }, {
        auto: false,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '31');
        },
        declaracion: 'solicitud:declaracion1053',
        casilla: '1053',
    }, {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '900');
        },
        declaracion: 'solicitud:declaracion54',
        casilla: '54',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return !checkCasillaTrue(expediente, '900');
        },
        declaracion: 'solicitud:declaracion1000',
        casilla: '1000',
    },
    {
        auto: false,
        visualizar: undefined,
        declaracion: 'solicitud:declaracion57',
        casilla: '57',
    },
    {
        auto: false,
        visualizar: undefined,
        declaracion: 'solicitud:declaracion58',
        casilla: '58',
    },
    {
        auto: false,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '33');
        },
        declaracion: 'solicitud:declaracion59',
        casilla: '59',
    },
    {
        auto: false,
        visualizar: undefined,
        declaracion: 'solicitud:declaracion60',
        casilla: '60',
    },
    {
        auto: false,
        visualizar: undefined,
        declaracion: 'solicitud:declaracion61',
        casilla: '61',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return checkCasillaTrue(expediente, '49');
        },
        declaracion: 'solicitud:declaracion90',
        casilla: '90',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '49') && !checkCasillaTrue(expediente, '49');
        },
        declaracion: 'solicitud:declaracion91',
        casilla: '91',
    },
    {
        auto: true,
        visualizar: undefined,
        declaracion: 'solicitud:declaracion1002',
        casilla: '1002',
    },
    {
        auto: false,
        visualizar: undefined,
        declaracion: 'solicitud:declaracion1003',
        casilla: '1003',
    },
    {
        auto: true,
        visualizar: (expediente: IExpediente) => {
            return validarCasilla(expediente, '3260');
        },
        declaracion: 'solicitud:declaracion3261',
        casilla: '3261',
    }
] as IDeclaracion[];
