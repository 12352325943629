import { IconButton, makeStyles, TextField } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDetalleExpediente, IExpediente, IJustificantePago, IJustificantePagoProvisional } from "../../gateways/interfaces/expediente.interface";
import NumberFormatCustom from "../organisms/number-format.custom";
import CheckSiNo from "./check.si-no";
import { AttachButton } from "../atoms/attach-button.atom";
import RelacionPagos from "./relacion-pagos";
import Attachment from "./attachment";
import { mdiEyeCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";

interface IProps {
    expediente: IExpediente;
    type: number;
    handleAdd: (event: any, justificantes: IJustificantePagoProvisional[]) => void;
    handleDelete: (id: number) => void;
    handleView: (id: string) => void;
    detallesExpediente?: IDetalleExpediente[];
    files: {
        ingreso: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string },
        costos: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string },
        auditoria: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string },
    }
    props: {
        pagarCostos: boolean | null,
        puedeJustificar: boolean | null,
        importe: number,
        localizadorDocumento: string
    }
    handleFiles: (x: any) => void;
    handleProps: (x: any) => void;
    setValidation: React.Dispatch<React.SetStateAction<boolean>>;
    expedientBlocked: boolean;
    downloadFile: (x: string, name: string) => void;
    handleOrderJustificantes: (justificantes?: IJustificantePago[]) => void;
}

const useStyles = makeStyles({
    title: {
        color: '#004f82',
        fontSize: 20
    },
    padded: {
        padding: 10
    },
    inlineTextFields: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paddedTextFields: {
        marginRight: 30
    }
});

export const JustificantesDestino: FC<IProps> = ({ expediente, type, handleAdd, handleDelete, handleView, files, handleFiles, props, handleProps, setValidation, expedientBlocked, downloadFile, handleOrderJustificantes }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [pagarCostos, setPagarCostos] = useState<boolean | null>(props.pagarCostos);
    const [puedeJustificar, setPuedeJustificar] = useState<boolean | null>(props.puedeJustificar);
    const [importe, setImporte] = useState(props.importe);
    const [errorImporte, setErrorImporte] = useState(false);
    const [messageImporte, setMessageImporte] = useState('');
    const [localizadorDocumento, setLocalizadorDocumento] = useState(props.localizadorDocumento);
    const [errorLocalizadorDocumento, setErrorLocalizadorDocumento] = useState(false);
    const [messageLocalizadorDocumento, setMessageLocalizadorDocumento] = useState('');
    const [justificantes, setJustificantes] = useState<IJustificantePagoProvisional[]>([])
    const [open, setOpen] = useState(false);
    const [fileIngreso, setFileIngreso] = useState<{ file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string }>(files.ingreso);
    const [fileCostos, setFileCostos] = useState<{ file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string }>(files.costos);
    const [fileAuditoria, setFileAuditoria] = useState<{ file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string }>(files.auditoria);

    const showIngreso = useMemo(() => {
        return puedeJustificar === false
    }, [puedeJustificar])
    const showCostos = useMemo(() => {
        return pagarCostos === true
    }, [pagarCostos])
    const showAuditoria = useMemo(() => {
        return type === 3
    }, [type])

    const handleChangePagarCostos = useCallback((bit: boolean) => {
        setPagarCostos(bit);
        deattachFileCostos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePuedeJustificar = useCallback((bit: boolean) => {
        if (!bit) {
            setImporte(0);
            setLocalizadorDocumento('');
            setErrorLocalizadorDocumento(false);
            setMessageLocalizadorDocumento('');
            deattachFileIngreso();
        }
        setPuedeJustificar(bit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeImporte: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        const valueStr = e.target.value;
        const value = parseFloat(valueStr);
        if (value >= parseFloat(expediente?.importeAsignado || '0')) {
            setErrorImporte(true);
            setMessageImporte(t('error:importe_justificado_alto'));
        } else {
            setErrorImporte(false);
            setMessageImporte('');
        }
        setImporte(value);
    }, [expediente?.importeAsignado, t])

    const handleChangeLocalizadorDocumento: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        const value = e.target.value;
        if (value.length !== 13 || value.substring(0, 3) !== '046' || !(new RegExp('^[0-9]+$').test(value))) {
            setErrorLocalizadorDocumento(true);
            setMessageLocalizadorDocumento(t('error:localizador_documento_046_invalido'));
        } else {
            setErrorLocalizadorDocumento(false);
            setMessageLocalizadorDocumento('');
        }
        setLocalizadorDocumento(e.target.value);
    }, [t])

    const handleChangeJustificantes = useCallback((justificantesAUX: IJustificantePagoProvisional[]) => {
        setJustificantes(justificantesAUX);
    }, [])

    const handleSubmitModal = useCallback((event: any) => {
        handleAdd(event, justificantes);
        setJustificantes([]);
        setOpen(false);
    }, [handleAdd, justificantes]);

    const handleCloseModal = useCallback(() => {
        setOpen(false);
    }, []);

    const handleOpenModal = useCallback(() => {
        setOpen(true);
    }, []);

    const handleFileIngreso = useCallback((x: any) => {
        setFileIngreso(x);
    }, []);

    const deattachFileIngreso = useCallback(() => {
        setFileIngreso({ file: null, showContent: null, nombre: null, idDocumento: '' });
    }, []);

    const handleFileCostos = useCallback((x: any) => {
        setFileCostos(x);
    }, []);

    const deattachFileCostos = useCallback(() => {
        setFileCostos({ file: null, showContent: null, nombre: null, idDocumento: '' });
    }, []);

    const handleFileAuditoria = useCallback((x: any) => {
        setFileAuditoria(x);
    }, []);

    const deattachFileAuditoria = useCallback(() => {
        setFileAuditoria({ file: null, showContent: null, nombre: null, idDocumento: '' });
    }, []);

    useEffect(() => {
        handleFiles({ ingreso: fileIngreso, costos: fileCostos, auditoria: fileAuditoria });
    }, [fileIngreso, fileCostos, fileAuditoria, handleFiles])

    useEffect(() => {
        handleProps({ pagarCostos: pagarCostos, puedeJustificar: puedeJustificar, importe: importe, localizadorDocumento: localizadorDocumento });
    }, [pagarCostos, puedeJustificar, importe, localizadorDocumento, handleProps])

    useEffect(() => {
        if (showIngreso && (fileIngreso.file === null && fileIngreso.idDocumento === "")) {
            setValidation(false);
            return;
        }
        if (showCostos && type !== 3 && (fileCostos.file === null && fileCostos.idDocumento === "")) {
            setValidation(false);
            return;
        }
        if (showAuditoria && (fileAuditoria.file === null && fileAuditoria.idDocumento === "")) {
            setValidation(false);
            return;
        }
        if (type === 3) {
            setValidation(true);
            return;
        }
        if (puedeJustificar === null || pagarCostos === null) {
            setValidation(false);
            return;
        }
        if (!puedeJustificar && (errorImporte || errorLocalizadorDocumento || localizadorDocumento === '' || fileIngreso === null)) {
            setValidation(false);
            return;
        }
        if (pagarCostos) {
            setValidation(false);
            return;
        }
        setValidation(true);
    }, [errorImporte, errorLocalizadorDocumento, expediente.importeAsignado,
        expediente?.justificantes, fileAuditoria, fileCostos, fileIngreso,
        localizadorDocumento, pagarCostos, puedeJustificar, setValidation,
        showAuditoria, showCostos, showIngreso, type]);

    useEffect(() => {
        handleOrderJustificantes(expediente?.justificantes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h4 className={classes.title}>{t("solicitud:justificantes_destino")}</h4>
            {
                <div className={classes.padded}>
                    <h4>{t("solicitud:puede_justificar_todo")}</h4>
                    <CheckSiNo checked={puedeJustificar} onChange={(x) => handleChangePuedeJustificar(x)} disabled={expedientBlocked} />
                </div>
            }
            {
                showIngreso &&
                <div style={{ display: 'flex', padding: 10 }}>
                    <div style={{ flex: 1, marginRight: 40 }}>
                        <h5>{t("solicitud:adjuntar_modelo_046")}</h5>
                        <TextField
                            fullWidth
                            label={t("solicitud:numero_documento_046")}
                            value={localizadorDocumento}
                            error={errorLocalizadorDocumento}
                            helperText={messageLocalizadorDocumento}
                            onChange={handleChangeLocalizadorDocumento}
                            style={{ marginBottom: '10px' }}
                            disabled={expedientBlocked}
                        />
                        <div className={classes.inlineTextFields}>
                            <TextField
                                fullWidth
                                className={classes.paddedTextFields}
                                label={t("solicitud:importe_justificado")}
                                value={importe}
                                error={errorImporte}
                                helperText={messageImporte}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                                onChange={handleChangeImporte}
                                disabled={expedientBlocked}
                            />
                            <TextField
                                fullWidth
                                className={classes.paddedTextFields}
                                label={t("solicitud:importe_no_justificado")}
                                value={parseFloat(expediente?.importeAsignado || '0') - importe}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                                disabled={true}
                            />
                            <TextField
                                fullWidth
                                label={t("solicitud:importe_asignado")}
                                value={parseFloat(expediente?.importeAsignado || '0')}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                                disabled={true}
                            />
                        </div>
                    </div>
                    {expedientBlocked ?
                        <div>
                            <IconButton color="inherit" onClick={() => downloadFile(fileIngreso.idDocumento, "documento ingreso")}>
                                <Icon color='#004f84' path={mdiEyeCircleOutline} size={1} />
                            </IconButton>
                        </div>
                        :
                        <div style={{ alignSelf: 'center' }}>
                            <AttachButton
                                id="fileIngreso"
                                documento={{ file: fileIngreso.file, showContent: null, nombre: null, idDocumento: fileIngreso.idDocumento}}
                                nameFile="documento ingreso"
                                handleFile={handleFileIngreso}
                                deattachFile={deattachFileIngreso}
                                blocked={expedientBlocked}
                            />
                        </div>
                    }
                </div>
            }
            {
                type !== 3 &&
                <div style={{ padding: 10 }}>
                    <h4>{t("solicitud:pagar_costos_pregunta")}</h4>
                    <CheckSiNo checked={pagarCostos} onChange={(x) => handleChangePagarCostos(x)} disabled={expedientBlocked} />
                </div>
            }
            {
                showCostos && type !== 3 &&
                <Attachment
                    text={expediente.linea === "1" ? "solicitud:adjuntos-auditoria-costes-fijos-linea1" : "solicitud:adjuntos-auditoria-costes-fijos"}
                    name="Costos"
                    file={fileCostos}
                    handleFile={handleFileCostos}
                    deattachFile={deattachFileCostos}
                    addBorder={type === 2}
                    blocked={expedientBlocked}
                    downloadFile={downloadFile}
                />
            }
            {
                type === 2 &&
                <div style={{ marginTop: 20 }}>
                    <RelacionPagos
                        justificantes={justificantes}
                        open={open}
                        expediente={expediente}
                        handleAdd={handleSubmitModal}
                        handleOpen={handleOpenModal}
                        handleClose={handleCloseModal}
                        handleChangeJustificantes={handleChangeJustificantes}
                        handleDelete={handleDelete}
                        handleView={handleView}
                        expedientBlocked={expedientBlocked}
                        handleOrderJustificantes={handleOrderJustificantes}
                    />
                </div>
            }
            {
                showAuditoria &&
                <>
                    <Attachment
                        text="solicitud:adjuntos-auditoria"
                        name="Auditoria"
                        file={fileAuditoria}
                        handleFile={handleFileAuditoria}
                        deattachFile={deattachFileAuditoria}
                        addBorder={false}
                        blocked={expedientBlocked}
                        downloadFile={downloadFile}
                    />
                    <div style={{ padding: 10 }}>
                        <h4>{t("solicitud:pagar_costos_dos_docs_pregunta")}</h4>
                        <CheckSiNo checked={pagarCostos} onChange={(x) => handleChangePagarCostos(x)} disabled={expedientBlocked} />
                    </div>
                    {
                        showCostos &&
                        <Attachment
                        text={"solicitud:adjuntos-auditoria-costes-fijos-60000"}
                        name="Costos"
                        file={fileCostos}
                        handleFile={handleFileCostos}
                        deattachFile={deattachFileCostos}
                        addBorder={type === 2}
                        blocked={expedientBlocked}
                        downloadFile={downloadFile}
                    />
                    }
                </>
            }
        </div>
    )
}
export default JustificantesDestino;