/* eslint-disable no-useless-concat */
import { Button, TextField, Typography } from "@material-ui/core";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertsContext } from "../contexts/alerts.context";
import { PageContext } from "../contexts/app.context";
import useAfirmaGateway from "../gateways/afirma.gateway";
import { TipoAccion } from "../gateways/interfaces/afirma.interfaces";
import { Afirma } from "../utils/afirma";

export const AfirmaTestPage: FC = () => {
    const { t } = useTranslation('global');
    const [pageState, ] = useContext(PageContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const gateway = useAfirmaGateway();
    const [nif, setNif] = useState('00000000T');
    const datosFirma = `{
       }`;
    const [log, setLog] = useState('');

    const handleFirmar = useCallback(async () => {        
        setLog((log) => log + 'Generando orden...\n');
        const result = await gateway.generarFirma(JSON.parse(datosFirma));
        setLog((log) => log + 'Orden: ' + JSON.stringify(result) + '\n');
        if (result.ok) {
            const afirma = new Afirma(result.idFirma ? result.idFirma : 0, TipoAccion.despresentacion, gateway, 'es', (datosFirma: any) => {
                console.log(datosFirma);
                setLog((log) => log + 'Callback recibido, idExpediente: ' + datosFirma.expediente.id + '\n');
            });
            // eslint-disable-next-line
            setLog((log) => log + 'Ejecutando autofirma...' + '\n');
            afirma.processSignature();
            // eslint-disable-next-line
            setLog((log) => log + 'Esperando respuesta...' + '\n');
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: t(result.mensaje),
                    variant: 'info',
                }
            });
        }
    }, [gateway, alertsDispatch, datosFirma, t]);

    useEffect(() => {
        if (pageState.jwp) {
            setNif(pageState.jwp.nif)
        }
    }, [pageState.jwp]);

    return (
        <div>
            <TextField
                label="NIF"
                margin="normal"
                style={{ margin: 5 }}
                fullWidth
                value={nif}
                onChange={(e) => setNif(e.target.value)}
            />
            {/* <TextField
                label="Datos a firmar"
                fullWidth
                style={{ margin: 5 }}
                margin="normal"
                value={content}
                onChange={(e) => setContent(e.target.value)}
            /> */}
            <Button onClick={handleFirmar}>
                Lanzar AutoFirma
            </Button>
            <div style={{ margin: 10 }}>
                <Typography variant="h6">Log system</Typography>
                <pre>
                    {log}
                </pre>
            </div>
        </div>
    );
}
