import { FC, useCallback } from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Term from '../term';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';


interface IProps extends RouteComponentProps {
    mensaje: string;
}

const FinFormularioDialogo: FC<IProps> = ({ mensaje, history }) => {
    const { t } = useTranslation("solicitud");

    const handleReset = useCallback(() => {
        history.push('')
    }, [history])

    return <>
        <Dialog
            maxWidth='xs'
            fullWidth={true}
            open={true}
            disableBackdropClick={true}
        >
            <DialogTitle>{t("solicitud:title_fin_formulario")}</DialogTitle>
            <DialogContent>
                {t(mensaje)}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleReset}
                    color="secondary"
                    variant="contained"
                >
                    <Term text="cerrar" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

export default withRouter(FinFormularioDialogo);