import { StyleRules, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules => ({
    root: {
        margin: '0 0.5rem',
        flex: 1,        
    },
    contenido: {
        width: '100%',
        marginTop: 15,
    },
    button: {
        marginTop: 15,
        borderRadius: 15,
        width: 215,
    },
    center: {
        textAlign: 'center',
        margin: 15,
    },
    [theme.breakpoints.down('md')]: {
        contenido: {
            marginTop: 15,
        },
        imgResponsive: {
            width: '100%', height: 'auto'
        }
    },    
    
});

export default styles;