import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import JustificantesPagoTemplate from "../components/templates/justificantes-pago.template";
import { AlertsContext } from "../contexts/alerts.context";
import { PageContext } from "../contexts/app.context";
import useExpedienteGateway from "../gateways/expediente.gateway";
import { CircularProgress } from "@material-ui/core";
import { IExpediente, IJustificantePagoProvisional, IFirmaJustificantesProvisional, IJustificantePago, TipoDocumento } from "../gateways/interfaces/expediente.interface";
import { downloadFile } from "../utils/downloadFile";
import { TipoAccion } from "../gateways/interfaces/afirma.interfaces";
import { Afirma } from "../utils/afirma";
import useAfirmaGateway from "../gateways/afirma.gateway";

export const JustificantesPagoPage: FC<RouteComponentProps> = ({ history }) => {
    const { t } = useTranslation(['solicitud', 'error']);
    const [loadingClave, setLoadingClave] = useState(false);

    // Global states
    const [pageState, pageDispatcher] = useContext(PageContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, setLoading] = useState(false);

    // Gateways
    const { getDocsJustificativos, saveJustificante, getFile, deleteJustificante, getJustificantes, updateJustificantes, getLineaAeat, descargarJustificanteJustificacion } = useExpedienteGateway();
    const gateway = useAfirmaGateway();
    const [docs, setDocs] = useState<any>(null);

    // Local states
    const [expediente, setExpediente] = useState<IExpediente | null>(null);
    const [lineaAeat, setLineaAeat] = useState('');

    // Events
    useCallback(async () => {
        if (pageState.expediente) {
            if (!pageState.expediente.puedeJustificar) {
                history.push('/expediente');
            }
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: t('error_solicitud'),
                    variant: 'warning',
                }
            });
        }
    }, [pageState.expediente, history, alertsDispatch, t])

    const handleAddJustificante = useCallback(async (_event: any, justificantesAUX: IJustificantePagoProvisional[]) => {
        const justificantes = (expediente?.justificantes || []);
        if (expediente !== null && expediente.id) {
            const justificante = await saveJustificante(expediente.id, justificantesAUX);
            if (justificante != null) {
                setExpediente({ ...expediente, justificantes: justificantes?.concat(justificante) });
            }
        }
    }, [expediente, saveJustificante]);

    const handleOrderJustificantes = useCallback(async (justificantesAux?: IJustificantePago[]) => {
        if (justificantesAux !== undefined) {
            justificantesAux = justificantesAux.map((x, index: number) => x = { ...x, numeroPrelacion: (index + 1).toString() });
            await updateJustificantes(justificantesAux);
        }
        setExpediente({ ...expediente, justificantes: justificantesAux })
    }, [expediente, updateJustificantes]);

    const handleViewDocument = useCallback(async (id: string, name?: string) => {
        const result = await getFile(id);
        downloadFile(result, name ?? 'justificante pago adjunto');
    }, [getFile]);

    const handleDelete = useCallback(async (id: number) => {
        await deleteJustificante(id);
        const justificantes = expediente?.justificantes?.filter(x => x.id !== id);
        handleOrderJustificantes(justificantes);
    }, [expediente, deleteJustificante, handleOrderJustificantes])

    const handleDownload = useCallback(async () => {
        const x = await descargarJustificanteJustificacion(pageState.lang, TipoDocumento.justificanteJustificacion);
        downloadFile(x, 'justificante');
    }, [descargarJustificanteJustificacion, pageState])

    const handleSign = useCallback(async (firmaAUX: IFirmaJustificantesProvisional) => {
        setLoadingClave(true);
        const result = await gateway.generarFirma({ tipoAccion: TipoAccion.justificacion, content: firmaAUX });
        if (result.ok) {
            const afirma = new Afirma(result.idFirma ? result.idFirma : 0, TipoAccion.justificacion, gateway, pageState.lang, (datosFirma: any) => {
                if (datosFirma.ok) {
                    setExpediente({ ...expediente, idEstado: 13 });
                    setLoadingClave(false);
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t(datosFirma.mensaje),
                            variant: 'info',
                        }
                    });
                    setLoadingClave(false);
                }
            });
            afirma.processSignature();
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: t(result.mensaje),
                    variant: 'info',
                }
            });
            setLoadingClave(false);
        }
    }, [alertsDispatch, expediente, gateway, pageState.lang, t]);

    useEffect(() => {
        window.addEventListener("popstate", () => {
            history.push('/');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function load() {
            if (pageState.expediente) {
                const docs = await getDocsJustificativos(pageState.expediente.id ?? 0);
                setDocs(docs);
                let justificantes = await getJustificantes(pageState.expediente.id ?? 0) as IJustificantePago[];
                if (justificantes !== undefined && justificantes.length > 1) {
                    justificantes = justificantes.sort((x, y) => (parseInt(x.numeroPrelacion) || 0) - (parseInt(y.numeroPrelacion)) || 0);
                }
                setLoading(true);
                setExpediente({ ...pageState.expediente, justificantes: justificantes });
                setLineaAeat(await getLineaAeat(pageState.expediente.id ?? 0));
                setLoading(false);
                if (pageState.expediente?.puedeJustificar) {
                    pageDispatcher({
                        type: 'setMenu',
                        menu: true,
                    });
                }
            } else {
                history.push('/');
            }
        }
        load();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDocsJustificativos, pageDispatcher, pageState.expediente]);

    return (
        <>
            {
                expediente
                    ?
                    <JustificantesPagoTemplate
                        expediente={expediente}
                        loading={loadingClave}
                        handleAdd={handleAddJustificante}
                        handleDelete={handleDelete}
                        handleView={handleViewDocument}
                        handleSign={handleSign}
                        docs={docs}
                        handleOrderJustificantes={handleOrderJustificantes}
                        lineaAeat={lineaAeat}
                        handleDownload={handleDownload}
                    />
                    :
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress size={75} />
                    </div>
            }
        </>
    );
}