import { setForceWSMode, setServlets, cargarAppAfirma, downloadRemoteData, sign, setToken } from './miniapplet.js';

import { getBrowser, getPlatform, getUserAgent } from './client';
import * as Sentry from '@sentry/browser';
import env from '../env';
import { ISignature, ISignatureResponse, TipoAccion } from '../gateways/interfaces/afirma.interfaces';
import { getAccessToken } from '../contexts/app.context';


export class Afirma {
    private idFirma: number;
    private tipoAccion: TipoAccion;
    private idioma: string;
    private gateway: any;
    private resultFunctionCallback: any;

    constructor(idFirma: number, tipoAccion: TipoAccion, gateway: any, idioma: string, resultFunctionCallback: any) {
        this.idFirma = idFirma;
        this.tipoAccion = tipoAccion;
        this.gateway = gateway;
        this.idioma = idioma;
        this.resultFunctionCallback = resultFunctionCallback;
    }

    public processSignature() {
        const urlDownload = env.AFIRMA_DESCARGA_DOCUMENTO ? env.AFIRMA_DESCARGA_DOCUMENTO + this.idFirma : '';
        const urlStorage = env.AFIRMA_STORAGE ? env.AFIRMA_STORAGE + this.idFirma : '';
        const urlRetriever = env.AFIRMA_RETRIEVER ? env.AFIRMA_RETRIEVER + this.idFirma : '';
        const accessToken = getAccessToken();
        setServlets(urlStorage, urlRetriever);
        setToken(accessToken);
        downloadRemoteData(urlDownload, this.initFirma, this.showErrorInitFirma);
    }

    private initFirma = (datosB64: any) => {
        sign(datosB64, "SHA256withRSA", "XAdES", null, this.saveSignatureFuntion, this.showErrorFunction);
    }

    private showErrorInitFirma(e: any) {
        console.error('Afirma Error', { e });
        Sentry.captureException(e);
        this.resultFunctionCallback({ ok: false, mensaje: 'error_autofirma' });
    }

    private saveSignatureFuntion = async (signatureB64: string) => {
        const datosFirma = await this.saveSignature(signatureB64);
        this.resultFunctionCallback(datosFirma);
    }

    private showErrorFunction = (type: any, message: string) => {
        console.error('Afirma Error', { type, message });
        Sentry.withScope((scope) => {
            scope.setExtras(type);
            Sentry.captureException(message);
        });
        this.resultFunctionCallback({ok: false, mensaje: 'error_autofirma'});
    }

    private async saveSignature(textoFirmado?: string): Promise<ISignatureResponse> {
        const signature: ISignature = {
            browser: getBrowser(),
            platform: getPlatform(),
            textoFirmado: textoFirmado ? textoFirmado : '',
            userAgent: getUserAgent(),
            idFirma: this.idFirma,
            tipoAccion: this.tipoAccion,
            idioma: this.idioma,
        }

        return await this.gateway.saveSignature(signature);
    }
}

export function configAppAfirma() {
    setForceWSMode(true);
    cargarAppAfirma('');
}