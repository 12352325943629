import { FC } from 'react';
import { withStyles, WithStyles, AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { Icon } from '@mdi/react'
import { mdiDotsVertical } from '@mdi/js';
import styles from '../../resources/styles/layout.styles';
import { useTranslation } from 'react-i18next';
import iconoAtibSuperior from '../../resources/images/iconoAtibSuperior.png';

interface IProps extends WithStyles<typeof styles> {
    openMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const SubvencionesAppBar: FC<IProps> = ({ classes, openMenu }) => {
    const { t } = useTranslation('global');
    // const [, pageDispatcher] = useContext(PageContext);

    // const handleChangeOpenNav = useCallback(() => {
    //     pageDispatcher({ type: 'open-nav-left' })
    // }, [pageDispatcher]);

    return (
        <AppBar position="fixed" color="default" className={classes.rootAppBar}>
            <Toolbar>
                <a href="https://ajutscovid.atib.es" className={classes.logoSpace}>
                    <img className={classes.logoSedeIni} src={iconoAtibSuperior} alt='logo-sede' />
                </a>
                <div className={classes.growAppBar} />
                <div>
                    <Typography className={classes.titleAppBar} component="h1" variant="h6" color="inherit">
                        {t('titulo')}
                    </Typography>
                    <Typography className={classes.subtitleAppBar} component="span" variant="subtitle2" color="inherit">
                        {t('subtitulo')}
                    </Typography>
                </div>
                <div className={classes.growAppBar} />
                <IconButton color="inherit" onClick={openMenu}>
                    <Icon path={mdiDotsVertical} title="Pasos" color="#fff" size={1} />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

export default withStyles(styles)(SubvencionesAppBar);