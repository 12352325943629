import { FC, useEffect, useMemo, useState } from "react";
import { CardContent, Card, IconButton } from "@material-ui/core";
import MainLayout from "./_main.layout";
import { useTranslation } from "react-i18next";
import { EstadoExpediente, IExpediente } from "../../gateways/interfaces/expediente.interface";
import { Icon } from '@mdi/react';
import { mdiCheckboxMarkedCircleOutline, mdiFolderClockOutline, mdiTextBoxRemoveOutline, mdiFileUndoOutline, mdiEyeCircleOutline } from "@mdi/js";
import NumberFormat from "react-number-format";

interface IProps {
    body: string;
    expediente: IExpediente;
    docs: any[];
    handleView: (id: string) => void;
}

const ResultadoSolicitudTemplate: FC<IProps> = ({ body, expediente, docs, handleView }) => {

    const { t, i18n } = useTranslation("solicitud");
    const [iconColor, setIconColor] = useState('#ff8037');
    const tieneRemesaRecurso = useMemo(() => expediente.referenciaBoibExtra !== null ? !new RegExp('\\d{4}\\/\\d+\\/\\d+').test(expediente.referenciaBoibExtra || '') : false, [expediente.referenciaBoibExtra])
    const [iconName, setIconName] = useState('');
    const [resultadoTitle, setResultadoTitle] = useState('');
    const importeAyuda = useMemo(() => { return expediente.importeAsignado ? parseFloat(expediente.importeAsignado) : null }, [expediente.importeAsignado])
    const importeReintegro = useMemo(() => {
        return (importeAyuda || 0) - parseFloat(expediente.detalles?.find(x => x.codigo === '4001')?.valor || '0')
    },
        [expediente.detalles, importeAyuda])

    useEffect(() => {
        (async () => {
            switch (expediente?.idEstado) {
                case EstadoExpediente.Aprobada:
                    setIconColor('#81a163');
                    setResultadoTitle(t('solicitud:solicitud_aceptada'));
                    setIconName(mdiCheckboxMarkedCircleOutline);
                    break;
                case EstadoExpediente.PagosJustificados:
                    if (tieneRemesaRecurso) {
                        setIconColor('#e28383');
                        setResultadoTitle(t('solicitud:solicitud_rechazada'));
                        setIconName(mdiTextBoxRemoveOutline);
                    } else {
                        setIconColor('#81a163');
                        setResultadoTitle(t('solicitud:solicitud_aceptada'));
                        setIconName(mdiCheckboxMarkedCircleOutline);
                    }
                    break;
                case EstadoExpediente.Rechazada:
                    setIconColor('#e28383');
                    setResultadoTitle(t('solicitud:solicitud_rechazada'));
                    setIconName(mdiTextBoxRemoveOutline);
                    break;
                case EstadoExpediente.Desistimiento:
                    setIconColor('#e28383');
                    setResultadoTitle(t('solicitud:solicitud_desistida'));
                    setIconName(mdiFileUndoOutline);
                    break;
                default:
                    setIconColor('#ff8037');
                    setResultadoTitle(t('solicitud:solicitud_pendiente_resultado'));
                    setIconName(mdiFolderClockOutline);
                    break;
            }

        })();
    }, [expediente, t, tieneRemesaRecurso]);

    return (
        <MainLayout>
            <div>
                <Card style={{ flex: 1, paddingBottom: 100 }}>
                    <CardContent>
                        <div style={{ paddingLeft: 25, paddingTop: 25 }}>
                            <div style={{ display: "flex", marginBottom: 25 }}>
                                <h3 style={{ color: '#004f82', fontWeight: 400, fontSize: 24 }}>{t("solicitud:estado_solicitud")}</h3>
                            </div>
                            <Icon style={{ verticalAlign: 'middle', marginRight: 5 }} path={iconName} color={iconColor} size={1.5} />
                            <b>{resultadoTitle}</b>

                            <div id="home" dangerouslySetInnerHTML={{ __html: body }} />
                            {
                                (expediente.idEstado === EstadoExpediente.Aprobada || expediente.idEstado === EstadoExpediente.PagosJustificados) &&
                                <div style={{ marginBottom: 10 }}>
                                    <p style={{ fontWeight: 'bold' }}>
                                        {t("importe_ayuda")}:&nbsp;
                                        <NumberFormat
                                            value={importeAyuda}
                                            displayType={'text'}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            suffix={' €'}
                                            decimalScale={2}
                                        />
                                    </p>
                                    <p style={{ fontWeight: 'bold' }}>
                                        {t("Linea")}:&nbsp;
                                        {expediente.linea}
                                    </p>
                                </div>
                            }
                            {
                                (expediente.idEstado === EstadoExpediente.Aprobada
                                    || expediente.idEstado === EstadoExpediente.Rechazada
                                    || expediente.idEstado === EstadoExpediente.Desistimiento
                                    || expediente.idEstado === EstadoExpediente.PagosJustificados) &&
                                // expediente.referenciaBoib &&
                                <div>
                                    <p>
                                        <a href={`https://www.caib.es/eboibfront/pdf/${i18n.language}/${expediente.referenciaBoib}`}>{t("solicitud:acceder_resolucion_boib")}</a>
                                    </p>
                                    {
                                        expediente.referenciaBoibExtra && !tieneRemesaRecurso &&
                                        <p>
                                            <a href={`https://www.caib.es/eboibfront/pdf/${i18n.language}/${expediente.referenciaBoibExtra}`}>{t("solicitud:acceder_resolucion_extra_boib")}</a>
                                        </p>
                                    }
                                </div>
                            }
                            {
                                tieneRemesaRecurso &&
                                <>
                                    <p>
                                        {t("solicitud:resolucion_recurso")}
                                    </p>
                                </>
                            }
                            {
                                expediente.idEstado === EstadoExpediente.PagosJustificados &&
                                importeReintegro > 0 &&
                                <div>
                                    <p style={{ fontWeight: 'bold' }}>
                                        {t("detectado_importe_devuelto")}&nbsp;
                                        <NumberFormat
                                            value={importeReintegro}
                                            displayType={'text'}
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            suffix={' €'}
                                            decimalScale={2}
                                        />
                                    </p>
                                </div>
                            }
                            {
                                docs && docs.length > 0 &&
                                <>
                                    <h4>{t("DOCUMENTOS_ASOCIADOS_AL_RECURSO")}</h4>
                                    {
                                        docs.map((x, i) => (
                                            <div key={`documento_recurso_${i}_tipo_${x.tipoDocumento}`} >
                                                {x.descripcionTipo}
                                                <IconButton color="inherit" onClick={() => handleView(x.idDocumento)}>
                                                    <Icon color='#004f84' path={mdiEyeCircleOutline} size={1} />
                                                </IconButton>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    </CardContent>
                </Card>
            </div>
        </MainLayout>
    );
}

export default ResultadoSolicitudTemplate;