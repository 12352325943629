import { CircularProgress, makeStyles } from "@material-ui/core";
import { FC, Suspense } from "react";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    }
}))

const PageLoader: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress size={50} />
        </div>
    );
}

export const PageSuspense: FC = ({ children }) => {
    return (
        <Suspense fallback={<PageLoader />}>
            {children}
        </Suspense>
    )
}