export function parseFloatIntelligent(value: unknown): number {
    if (typeof value === 'number') {
        return value;
    } else if (typeof value === 'string') {
        const val = value.replace(/\r/gm, '').replace(/\n/gm, '');

        let parts = val.split(',');
        if (parts.length > 0 && parts[parts.length - 1].length === 2) {
            // es formato español con decimales
            return parseFloat(val.replace(/\./gm, '').replace(',', '.'));
        } else {
            parts = val.split('.');
            if (parts.length > 0 && parts[parts.length - 1].length === 2) {
                // es formato ingles con decimales
                return parseFloat(val);
            } else if (parts.length > 0 && parts[parts.length - 1].length === 3) {
                // millares español sin decimales
                return parseFloat(val.replace(/\./gm, ''));
            } else {
                return parseFloat(val);
            }
        }
    } else {
        return value as number;
    }
}