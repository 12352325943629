import { FC } from "react";
import { useRouteMatch } from "react-router";
import { CNAEPage } from "./cnae";
import { Route } from "react-router-dom";

export const HerramientasPage: FC = () => {
    const match = useRouteMatch();
    return (
        <div>
            <Route exact path={match.path + "/cnae"} component={CNAEPage} />
        </div>
    )
}