import { IconButton, makeStyles } from "@material-ui/core";
import { mdiEyeCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AttachButton } from "../atoms/attach-button.atom";

interface IProps {
    text: string;
    name: string;
    file: { file: File | null, idDocumento: string };
    handleFile: (x: any) => void;
    deattachFile: () => void;
    addBorder: boolean;
    blocked: boolean;
    downloadFile: (x: string, name: string) => void;
}

const useStyles = makeStyles({
    basic: {
        display: 'flex',
        padding: 10
    },
    bordered: {
        borderBottom: '1px solid lightgrey'
    }
});

export const Attachment: FC<IProps> = ({ text, name, file, handleFile, deattachFile, addBorder, blocked, downloadFile }) => {
    const { t } = useTranslation("expediente");
    const classes = useStyles();
    return (
        <div className={`${classes.basic} ${addBorder ? classes.bordered : ''}`}>
            <div style={{ flex: 1, marginRight: 40 }}>
                <span>{t(text)}</span>
            </div>
            <div>
                {blocked ?
                    <div>
                        <IconButton color="inherit" onClick={() => downloadFile(file.idDocumento, `documento${name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })}`)}>
                            <Icon color='#004f84' path={mdiEyeCircleOutline} size={1} />
                        </IconButton>
                    </div>
                    :
                    <AttachButton
                        id={`file${name}`}
                        documento={{ file: file.file, showContent: null, nombre: null, idDocumento: file.idDocumento}}
                        nameFile={`documento${name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })}}`}
                        handleFile={handleFile}
                        deattachFile={deattachFile}
                        blocked={blocked}
                    />
                }
            </div>
        </div>
    )
}
export default Attachment;