import { useCallback, useContext } from "react";
import { PageContext } from "../contexts/app.context";
import { IApoderamiento } from "./interfaces/apoderamiento.interface";

const useApoderamientoGateway = () => {
    const [appState] = useContext(PageContext);
    const { axios } = appState;

    return {
        getListPoderdantes: useCallback(
            async (): Promise<IApoderamiento[] | null> => {
                try {
                    const response = await axios.get('/apoderamiento');
                    return response.data;
                } catch (e) {
                    return null;
                }
            },
            [axios],
        ),
        getRepresentar: useCallback(
            async (nif: string): Promise<string | null> => {
                try {
                    const response = await axios.get('/auth/impersonate-apoderado?nifPoderdante=' + nif);
                    if(response.data.ok !== true){
                        return null;
                    }
                    return response.data.accessToken;
                } catch (e) {
                    return null;
                }
            },
            [axios],
        ),
    };
};

export default useApoderamientoGateway;