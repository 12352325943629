import { Button, TextField, Card, CardContent, Grid } from "@material-ui/core";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDetalleExpediente, IExpediente, OnChangeExpedienteCallback } from "../../gateways/interfaces/expediente.interface";
import { IOpcion } from "../../gateways/interfaces/opcion.interfaces";
import { ISujeto } from "../../gateways/interfaces/sujeto.interfaces";
import Contestador from "./contestador";
import FinFormularioDialogo from "./dialogs/fin-formulario.dialog";
import { WorkflowEngine } from "./workflow";
import { fromBpmnToWorkflow } from "./workflow/bpmn.converter";
import { TaskPreguntaOption } from "./workflow/interfaces";
import actividadesEconomicasBPMN from './actividad-economica-bpmn';
import NumberFormatCustom from "./number-format.custom";
import useStyles from '../../resources/styles/solicitud.styles';
import * as ibantools from 'ibantools';
import { Alert } from "@material-ui/lab";
import { PrettierText } from "../atoms/prettier-text.atom";

interface IProps {
    expediente: IExpediente;
    step: number;
    sujeto: ISujeto,
    onChange: OnChangeExpedienteCallback;
    changeStep: (step: number, block: boolean) => void;
}

const workflowActividadesEconomicas = fromBpmnToWorkflow(actividadesEconomicasBPMN);

const ActividadEconimica: FC<IProps> = ({ expediente, onChange, step, changeStep }) => {
    const { t } = useTranslation();
    const [iban, setIban] = useState(expediente.detalles?.find(x => x.codigo === '65')?.valor || '');
    const [importe, setImporte] = useState(expediente.detalles?.find(x => x.codigo === '800')?.valor || '');
    const [errorIban, setErrorIban] = useState(false);
    const [errorImporte, setErrorImporte] = useState(false);
    const [messageImporte, setMessageImporte] = useState('');

    const isValidForm = useMemo(() =>
        importe.length > 0 && iban.length > 0 && !errorImporte && !errorIban
        , [iban.length, importe.length, errorImporte, errorIban]);

    const classes = useStyles();

    useEffect(() => {
        if (expediente.worflowAE) {
            return;
        }
        const workflow = new WorkflowEngine(workflowActividadesEconomicas.tasks, workflowActividadesEconomicas.connectors);
        workflow.onContextChange((context) => {
            onChange({ ...context });
        });
        workflow.onUserTask((task, next, back) => {
            onChange((c) => ({ ...c, workflowAETask: { task, next, back } }));
        });
        workflow.onEnd(() => {
            onChange((c) => ({ ...c, workflowAETask: undefined }));
        });
        workflow.onBack(() => {
            onChange(e => {
                const ea = (e.actividadEconomica || []);
                const [deleted] = ea.splice(ea.length - 1, 1);
                const casillasPosibles = deleted.opciones.map(x => x.value.casilla);
                return {
                    ...e,
                    actividadEconomica: ea,
                    workflowAETask: undefined,
                    detalles: [
                        ...(e.detalles || []).filter(d => !casillasPosibles.includes(d.codigo)),
                    ]
                }
            });
        })
        onChange((c) => ({ ...c, worflowAE: workflow }))
    }, [onChange, expediente.worflowAE]);

    useEffect(() => {
        if (!expediente.worflowAE || !expediente || expediente.worflowAE.status === 'finished') {
            return;
        }

        if (expediente.worflowAE.status === 'unstarted') {
            expediente.worflowAE.updateContext(expediente);
            expediente.worflowAE.run();
        }
    }, [expediente]);

    const handleChangeIBAN: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        let newIban = e.target.value.replace(/\s/g, '');
        newIban = newIban.toUpperCase();
        setIban(newIban);
        if ((!newIban.startsWith('ES') || !ibantools.isValidIBAN(newIban))) {
            setErrorIban(true);
            changeStep(step, true);
        } else {
            setErrorIban(false);
        }
        let casillasAux = expediente.detalles || [];
        casillasAux = casillasAux.filter(x => x.origen === 'solicitante' || x.origen === 'actividad-economica');
        casillasAux = casillasAux.filter(x => x.codigo !== '65');
        casillasAux.push({
            origen: 'actividad-economica',
            codigo: '65',
            valor: newIban,
        } as IDetalleExpediente);
        onChange({ ...expediente, detalles: casillasAux });
    }, [expediente, onChange, changeStep, step])

    const handleChangeImporte: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        const valueStr = e.target.value;
        const value = parseFloat(valueStr);

        let casillasAux = expediente.detalles || [];
        let is31 = casillasAux.findIndex(x => x.codigo === '31' && x.valor === '1') >= 0;

        setImporte(valueStr);
        if (is31 && value > 3000) {
            setErrorImporte(true);
            setMessageImporte(t('error:error_importe_max_31'));
        } else if (value <= 0) {
            setErrorImporte(true);
            setMessageImporte(t('error:error_importe'));
        }
        else {
            setMessageImporte('');
            setErrorImporte(false);
        }
        changeStep(step, true);
        casillasAux = casillasAux.filter(x => x.origen === 'solicitante' || x.origen === 'actividad-economica');
        casillasAux = casillasAux.filter(x => x.codigo !== '800');
        casillasAux.push({ origen: 'actividad-economica', codigo: '800', valor: valueStr } as IDetalleExpediente);
        onChange({ ...expediente, detalles: casillasAux });
    }, [changeStep, expediente, onChange, step, t])

    const handleAnswer = useCallback((opcion: IOpcion) => {
        if (!expediente || !expediente.workflowAETask || expediente.workflowAETask.task.type !== 'pregunta') {
            return;
        }

        const respuesta = (opcion.value as unknown as TaskPreguntaOption);

        const expedienteActualizado: IExpediente = {
            ...expediente,
            actividadEconomica: [
                ...expediente.actividadEconomica ?? [],
                {
                    pregunta: expediente.workflowAETask.task.pregunta,
                    opciones: expediente.workflowAETask.task.opciones.map(x => ({ value: x, label: x.label })),
                    opcionSeleccionada: opcion,
                }
            ],
            detalles: [
                ...expediente.detalles ?? [],
                {
                    codigo: respuesta.casilla,
                    origen: 'actividad-economica',
                    valor: respuesta.valor,
                }
            ]
        };
        onChange(expedienteActualizado);
        expediente.worflowAE && expediente.worflowAE.updateContext(expedienteActualizado);
        expediente.workflowAETask.next();
        window.scrollTo({ top: document.body.scrollHeight });
    }, [expediente, onChange]);

    const handleClean = useCallback(() => {
        const expedienteCleaned = {
            ...expediente,
            actividadEconomica: [],
            detalles: (expediente.detalles ?? []).filter(x => x.origen === 'solicitante'),
        };
        onChange(expedienteCleaned);
        setIban('');
        setImporte('');
        changeStep(step, true);
        window.scrollTo({ top: 0 });
        expediente.worflowAE && expediente.worflowAE.reset();
        expediente.worflowAE && expediente.worflowAE.updateContext(expedienteCleaned);
    }, [changeStep, expediente, onChange, step]);

    const handleContinuar = useCallback(() => {
        if (!isValidForm) {
            return;
        }
        window.scrollTo({ top: 0 });
        changeStep(step + 1, false);
    }, [changeStep, step, isValidForm])

    const handleDialogoFormChange = useCallback((expedienteActualizado: IExpediente) => {
        if (!expedienteActualizado.workflowAETask || expedienteActualizado.workflowAETask.task.type !== 'dialogo-form') {
            return;
        }
        const newExpediente = expedienteActualizado.workflowAETask.task.componentResponse(expedienteActualizado);

        onChange(newExpediente);

        newExpediente.worflowAE && newExpediente.worflowAE.updateContext(newExpediente);
        expedienteActualizado.workflowAETask.next();
        window.scrollTo({ top: document.body.scrollHeight });
    }, [onChange]);

    const handleCloseDialogFormWithoutChanges = useCallback(() => {
        if (!expediente.workflowAETask) {
            return;
        }
        expediente.workflowAETask.back();
    }, [expediente]);

    return (
        <div>
            <Card style={{ flex: 1 }}>
                <CardContent>
                    <div style={{ paddingLeft: 25, paddingTop: 25 }}>
                        <div style={{ display: "flex", marginTop: 5, marginBottom: 25 }}>
                            <h3 style={{ color: '#004f82', fontWeight: 400, fontSize: 24 }}>{t("solicitud:actividad_economica")}</h3>
                        </div>
                        {expediente.actividadEconomica && expediente.actividadEconomica.map((ae, i) => (
                            <div style={{ marginBottom: 20 }} key={`ea_contestador_${i}`}>
                                <Contestador
                                    expediente={expediente}
                                    pregunta={ae.pregunta}
                                    opciones={ae.opciones}
                                    opcion={{ ...ae.opcionSeleccionada, label: ae.opcionSeleccionada.label }}
                                    onAnswer={handleAnswer}
                                />
                            </div>
                        ))}

                        {
                            expediente.workflowAETask && <>
                                {expediente.workflowAETask.task.type === 'pregunta' && <div style={{ marginBottom: 20 }}>
                                    <Contestador
                                        expediente={expediente}
                                        pregunta={expediente.workflowAETask.task.pregunta}
                                        opciones={expediente.workflowAETask.task.opciones.map(x => ({ value: x, label: x.label }))}
                                        textoAclaratorio={expediente.workflowAETask.task.textoAclaratorio}
                                        opcion={undefined}
                                        onAnswer={handleAnswer}
                                    />
                                </div>
                                }
                                {
                                    expediente.workflowAETask.task.type === 'dialogo-fin' && <FinFormularioDialogo
                                        mensaje={expediente.workflowAETask.task.motivo}
                                    />
                                }
                                {
                                    expediente.workflowAETask.task.type === 'dialogo-form'
                                    && <expediente.workflowAETask.task.componente
                                        expediente={expediente}
                                        onChange={handleDialogoFormChange}
                                        onCloseWithoutChanges={handleCloseDialogFormWithoutChanges}
                                    />
                                }
                            </>
                        }
                        {
                            expediente?.worflowAE?.status === 'finished' && <div style={{}}>
                                <Alert severity="info" style={{ marginBottom: '1rem' }}>
                                    <PrettierText text={t('solicitud:iban_e_importe_aclaracion')} />
                                </Alert>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={t("solicitud:casillero65")}
                                            className={classes.inputPadding}
                                            InputLabelProps={{ className: classes.inputValue }}
                                            value={iban}
                                            error={errorIban}
                                            helperText={errorIban ? t('error:error_iban') : ''}
                                            onChange={handleChangeIBAN}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={t("solicitud:importe_total_ayuda")}
                                            className={classes.inputPadding}
                                            InputLabelProps={{ className: classes.inputValue }}
                                            value={importe}
                                            error={errorImporte}
                                            helperText={messageImporte}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom as any,
                                            }}
                                            onChange={handleChangeImporte}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div>
                </CardContent>
            </Card>
            <Grid container className={classes.rootFooter}>
                <Grid item xs={6} >
                </Grid>
                <Grid item xs={12} md={6} className={classes.footerContainer}>
                    <div className={classes.wrapper}>
                        <Button color="default" disabled={!!expediente.id} style={{ marginRight: 10 }} variant="contained" onClick={handleClean}>{t('limpiar')}</Button>
                        <Button
                            disabled={!!expediente.id || !expediente.worflowAE || expediente.worflowAE.status !== 'finished' || !isValidForm}
                            variant="contained"
                            color="primary"
                            onClick={handleContinuar}>
                            {t('solicitud:continuar')}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>);
}

export default ActividadEconimica;