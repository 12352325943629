import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import logoGOIB from '../../resources/images/goib.png'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        margin: theme.spacing(1),
        paddingBottom: 100,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    }
}))

export const Footer: FC = () => {
    const classes = useStyles();
    return (
        <footer className={classes.root}>
            <img src={logoGOIB} style={{ marginBottom: 80 }} alt="LOGO Govern Illes Balears" />
        </footer>
    )
}