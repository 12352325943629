export function downloadFile(blob: Blob, fileName: string): boolean {
    const ua = window.navigator.userAgent;
    const isIexplorer = (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0 || ua.indexOf('Edge/') > 0);

    if (isIexplorer) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return true;
    } else {
        const anchor = document.createElement('a');
        const fileURL = URL.createObjectURL(blob);

        anchor.href = fileURL;
        anchor.download = fileName;
        anchor.target = '_blank';

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        return true;
    }
}