import axios, { AxiosError, AxiosInstance } from "axios";
import {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  ReducerAction,
  ReducerState,
} from "react";
import { authStorage, langStorage } from "../storages";
import env from "../env";
import { refreshToken } from "../gateways/refresh.gateway";
import { IJwtPayload, IJwtResponse } from "../gateways/interfaces/auth.interfaces";
import { IExpediente } from "../gateways/interfaces/expediente.interface";

export interface IPageState {
  axios: AxiosInstance;
  lang: string;
  navLeftOpen: boolean;
  navRightOpen: boolean;
  jwp: IJwtPayload | null;
  jwt: IJwtResponse | null;
  menu: boolean;
  menuContent: boolean;
  footer: boolean;
  logout: boolean;
  pasoActivo: number;
  expediente: IExpediente | null;
}

export interface IPageNotification {
  message: string | ReactNode;
  variant: "success" | "warning" | "error" | "info";
  autoHideDuration?: number;
}

export interface IPageNotificationWithOpen extends IPageNotification {
  open: boolean;
}

export type PageAction =
  | {
    type: "setMenu";
    menu: boolean;
    menuContent?: boolean;
    footer?: boolean;
  }
  | { type: "authenticate"; payload: IJwtResponse }
  | { type: "change-lang"; lang: string }
  | { type: "logout" }
  | { type: "open-nav-left" | "close-nav-left" }
  | { type: "open-nav-right" | "close-nav-right" }
  | {
    type: "show-notification" | "remove-notification" | "hide-notification";
    payload: IPageNotification;
  }
  | {
    type: "setPaso";
    payload: number;
  }
  | {
    type: "setExpediente";
    payload: IExpediente;
  }
  | {
    type: "cleanExpediente";
  };

const getJwtPayloadFromToken = (jwt: string) => {
  if (jwt) {
    const parts = jwt.split(".");
    const data = parts[1];
    return parseToken(data);
  }
  return null;
};

const parseToken = (data: any) => {
  data = data.replace(/-/gim, "+").replace(/_/gim, "/");

  const payload = JSON.parse(atob(data)) as IJwtPayload;
  payload.fullname = decodeURIComponent(escape(payload.fullname));
  payload.nombreRepresentado = decodeURIComponent(escape(payload.nombreRepresentado));

  return payload;
};

const authenticate = (state: IPageState, jwt: IJwtResponse) => {
  if (!("accessToken" in jwt)) {
    return state;
  }
  const jwp = getJwtPayloadFromToken(jwt.accessToken);
  if (jwp) {
    state.jwt = jwt;
    state.jwp = jwp;

    authStorage.setItem("jwp", JSON.stringify(jwp));
    authStorage.setItem("jwt", JSON.stringify(state.jwt));
  }

  return state;
};

const logout = (state: IPageState) => {
  authStorage.removeItem("jwp");
  authStorage.removeItem("jwt");
  state.jwp = null;
  state.jwt = null;
  state.logout = true;
  return state;
};

export const PageReducer: Reducer<IPageState, PageAction> = (state, action) => {
  switch (action.type) {
    case "setMenu":
      return {
        ...state,
        menu: action.menu,
        menuContent: action.menuContent ? action.menuContent : false,
        footer: action.footer ? action.footer : false,
      };
    case "authenticate":
      return authenticate(state, action.payload);
    case "change-lang":
      return updateLangage(state, action.lang);
    case "open-nav-left":
      return { ...state, navLeftOpen: true };
    case "close-nav-left":
      return { ...state, navLeftOpen: false };
    case "open-nav-right":
      return { ...state, navRightOpen: true };
    case "close-nav-right":
      return { ...state, navRightOpen: false };
    case "logout":
      return logout(state);
    case "setPaso":
      return { ...state, pasoActivo: action.payload };
    case "setExpediente":
      return setearExpediente(state, action.payload);
    case "cleanExpediente":
      return cleanExpediente(state);
    default:
      throw new Error("Unexpected action");
  }
};

type PageReducerState = ReducerState<typeof PageReducer>;
type PageDispatchAction = Dispatch<ReducerAction<typeof PageReducer>>;
type PageContextType = [PageReducerState, PageDispatchAction];

const updateLangage = (state: IPageState, language: string) => {
  const newState = { ...state, lang: language };
  langStorage.setItem("lang", language);
  const htmlNodes = document.getElementsByTagName("html");
  if (!!htmlNodes && htmlNodes.length > 0) {
    htmlNodes[0].setAttribute("lang", language);
  }
  return newState;
};

const tryReadStorage = (key: string, storage: Storage = authStorage) => {
  try {
    const data = storage.getItem(key);
    if (data !== null) {
      return JSON.parse(data);
    }
    return null;
  } catch (e) {
    return null;
  }
};

(() => {
  const jwt = tryReadStorage("jwt", sessionStorage);
  if (jwt && jwt.accessToken && jwt.accessToken.indexOf("token=") >= 0) {
    authStorage.removeItem("jwp");
    authStorage.removeItem("jwt");
  }
})();

export function getLang(): string {
  const lang = langStorage.getItem("lang");
  let userLang = navigator.language ? navigator.language : "ca";
  userLang = userLang !== "es" && userLang !== "ca" ? "ca" : userLang;

  const htmlNodes = document.getElementsByTagName("html");
  if (!!htmlNodes && htmlNodes.length > 0) {
    const htmlNode = htmlNodes[0];
    if (htmlNode.getAttribute("lang") !== userLang) {
      htmlNode.setAttribute("lang", userLang);
    }
  }

  let language = lang ? lang : userLang;

  if (!['es', 'ca'].includes(language)) {
    language = 'ca';
  }

  langStorage.setItem("lang", language);

  return language;
}

export function getAccessToken(): string {
  const jwt = tryReadStorage("jwt");
  return jwt != null ? jwt.accessToken : null;
}

export function setAccessToken(accessToken: string): void {
  const jwt = tryReadStorage("jwt");
  const newJwt = Object.assign({}, jwt, { accessToken });
  authStorage.setItem("jwt", JSON.stringify(newJwt));
}

function setearExpediente(state: IPageState, expediente: IExpediente) {
  // sessionStorage.setItem("expediente", JSON.stringify(expediente));
  return { ...state, expediente: expediente };
}

function cleanExpediente(state: IPageState) {
  // sessionStorage.removeItem("expediente");
  return { ...state, expediente: null }
}

function AxiosConfiguration() {
  const axiosInstance = axios.create({
    baseURL: env.API_URI,
  });

  axiosInstance.interceptors.request.use((config) => {
    const accessToken = getAccessToken();

    if (!!accessToken) {
      config.headers.Authorization = "Bearer " + accessToken;
    }
    const lang = localStorage.getItem("lang");
    if (lang) {
      config.headers["X-Language"] = lang;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      const response = error.response;
      const rqHeaders = error.config?.headers;

      if (response) {
        if (response.status === 401) {
          const jwt: string =
            rqHeaders && rqHeaders.Authorization
              ? rqHeaders.Authorization.split(' ')[1]
              : null;
          const result = await refreshToken(jwt);
          if (result && result.accessToken !== '') {
            setAccessToken(result.accessToken);
            const newRqConfig = error.config;
            newRqConfig.headers.Authorization = "Bearer " + result.accessToken;
            return await axiosInstance.request(newRqConfig);
          } else if (result.status === 403) {
            return result;
          }
        } else if (response.status === 403) {
          return response;
        }
      } else {
        console.log("No tengo response");
      }

      throw error;
    }
  );

  return axiosInstance;
}

export const PageDefaultState: IPageState = {
  axios: AxiosConfiguration(),
  lang: getLang(),
  navLeftOpen: false,
  navRightOpen: false,
  jwp: tryReadStorage("jwp"),
  jwt: tryReadStorage("jwt"),
  logout: false,
  menu: true,
  menuContent: false,
  footer: true,
  pasoActivo: 0,
  expediente: null,
};

export const PageContext = createContext<PageContextType>([
  PageDefaultState,
  (state) => state,
]);
