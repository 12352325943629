// eslint-disable-next-line import/no-anonymous-default-export
const apiUrlBase = window.origin + '/api/';

const env = {
    API_URI: process.env.REACT_APP_API_URI || apiUrlBase,
    AFIRMA_DESCARGA_DOCUMENTO: process.env.REACT_APP_AFIRMA_DESCARGA_DOCUMENTO || (apiUrlBase + 'afirma/getContenido/'),
    AFIRMA_STORAGE: process.env.REACT_APP_AFIRMA_STORAGE || (apiUrlBase + 'afirma/storage/'),
    AFIRMA_RETRIEVER: process.env.REACT_APP_AFIRMA_RETRIEVER || (apiUrlBase + 'afirma/retriever/'),
};

export default env;
