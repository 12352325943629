/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button, CardContent, Card, Grid
} from "@material-ui/core";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ISujeto } from "../../gateways/interfaces/sujeto.interfaces";
import { IDetalleExpediente, IExpediente } from "../../gateways/interfaces/expediente.interface";
import Declarador from "./declarador";
import listadoDeclaraciones from "./declaraciones";
import useStyles from "../../resources/styles/solicitud.styles";

interface IProps {
    expediente: IExpediente;
    step: number;
    sujeto: ISujeto,
    onChange: (value: IExpediente) => void;
    changeStep: (step: number, block: boolean) => void;
}

export const DeclaraResponsablemente: FC<IProps> = ({ expediente, step, onChange, changeStep }) => {
    const { t } = useTranslation("solicitud");
    const classes = useStyles();

    const declaracionesVisibles = useMemo(
        () => listadoDeclaraciones.filter(x => (x.visualizar === undefined || x.visualizar(expediente) === true) && x.auto === false),
        [listadoDeclaraciones, expediente]
    );

    const estanTodasDeclaradas = useMemo(() => {
        let output = true;
        const detalles = expediente.detalles ?? [];

        for (const declara of declaracionesVisibles) {
            if (detalles.findIndex(x => x.codigo === declara.casilla) === -1) {
                output = false;
                break;
            }
        }
        return output;
    }, [expediente, declaracionesVisibles]);

    const handleChangeDeclarador = useCallback((expedienteAux: IExpediente) => {
        onChange(expedienteAux);
        window.scrollTo(0, document.body.scrollHeight);
    }, [onChange])

    const handleContinuar = useCallback(() => {
        if (estanTodasDeclaradas) {
            const declaracionesAuto: IDetalleExpediente[] = listadoDeclaraciones
                .filter(x => (x.visualizar === undefined || x.visualizar(expediente) === true) && x.auto === true)
                .map(x => ({ codigo: x.casilla, valor: '1', origen: 'declara' }));

            const detallesSinDeclaracionesAuto: IDetalleExpediente[] = (expediente.detalles || [])
                .filter(x => declaracionesAuto.findIndex(c => c.codigo === x.codigo) === -1);

            expediente.detalles = [
                ...declaracionesAuto,
                ...detallesSinDeclaracionesAuto,
            ];

            onChange(expediente);
            window.scrollTo({ top: 0 });
            changeStep(step + 1, false);
        }
    }, [estanTodasDeclaradas, step, changeStep])


    return (
        <div>
            <Card style={{ flex: 1 }}>
                <CardContent>
                    <div style={{ paddingLeft: 25, paddingTop: 25 }}>
                        <div style={{ display: "flex", marginBottom: 25 }}>
                            <h3 style={{ color: '#004f82', fontWeight: 400, fontSize: 24 }}>{t("solicitud:declara_responsablemente")}</h3>
                        </div>
                        <Declarador expediente={expediente} onChange={handleChangeDeclarador} declaraciones={declaracionesVisibles} />
                    </div>
                </CardContent>
            </Card>
            <Grid container className={classes.rootFooter}>
                <Grid item xs={6} >
                </Grid>
                <Grid item xs={12} md={6} className={classes.footerContainer}>
                    <div className={classes.wrapper}>
                        <Button disabled={!estanTodasDeclaradas || !!expediente.id} variant="contained" color="primary" onClick={handleContinuar}>
                            {t('solicitud:continuar')}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default DeclaraResponsablemente;
