import { FC, useCallback, useContext } from "react";
import { CardContent, Card, Button } from "@material-ui/core";
import MainLayout from "./_main.layout";
import useStyles from "../../resources/styles/generic.styles";
import Term from "../organisms/term";
import { ConfigContext } from "../../contexts/config.context";

interface IProps {
    body: string;
    handleStart: () => void;
}

export const InfoRequisitosTemplate: FC<IProps> = ({ body, handleStart }) => {
    const classes = useStyles();
    const { FORMULARIO_ACTIVO } = useContext(ConfigContext);

    const onStart = useCallback(() => {
        handleStart();
    }, [handleStart]);

    return (
        <MainLayout>
            <div className={classes.root15}>
                <Card className={classes.card}>
                    <CardContent>
                        <div id="home" dangerouslySetInnerHTML={{ __html: body }} />
                    </CardContent>
                </Card>
            </div>

            {
                FORMULARIO_ACTIVO &&
                <div className={classes.root15}>
                    <Card className={classes.card}>
                        <CardContent className={classes.rootCenter}>
                            <Button className={classes.rounded} onClick={onStart} color="primary" variant="contained" >
                                <Term text="iniciar_solicitud" />
                            </Button>
                        </CardContent>
                    </Card>
                </div>
            }
        </MainLayout>
    );
}

export default InfoRequisitosTemplate;