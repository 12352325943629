import { FC, useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import InfoRequisitosTemplate from "../components/templates/info-requisitos.template";
import { PageContext } from "../contexts/app.context";
import useContentsGateway from "../gateways/contents.gateway";

type Props = RouteComponentProps;

export const InfoRequisitosPage: FC<Props> = ({ history }) => {
    const { fetchContent } = useContentsGateway();

    // Global states
    const [, pageDispatcher] = useContext(PageContext);

    // Local states
    const [body, setBody] = useState('');

    // Events
    const handleStart = useCallback(() => {
        history.push('/clave');
    }, [history]);

    // Effects
    useEffect(() => {
        (async () => {
            setBody(await fetchContent('home'));
        })();
    }, [fetchContent]);

    useEffect(() => {
        pageDispatcher({
            type: 'setMenu',
            menu: false,
            menuContent: false,
        });
    }, [pageDispatcher]);

    return (
        <InfoRequisitosTemplate body={body} handleStart={handleStart} />
    );
}