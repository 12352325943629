import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { Dialog, Button, DialogActions, DialogContent, Divider, makeStyles, TextField, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, FormHelperText } from "@material-ui/core";
import Term from '../term';
import { useTranslation } from 'react-i18next';
import { InputChangeHandler } from '../../../utils/events';
import { NumberFormatCustom } from '../number-format.custom';
import { DialogFormProps, DialogoWorkflow } from '../workflow/interfaces';
import { Alert } from "@material-ui/lab";
import { PrettierText } from '../../atoms/prettier-text.atom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    width: {
        width: '100%'
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
        marginLeft: 10,
        marginBottom: -8,
    },
    section: {
        margin: theme.spacing(2, 0),
    }
}));


const DialogThomasCook: FC<DialogFormProps> = ({ expediente, onChange, onCloseWithoutChanges }) => {
    const { t } = useTranslation("solicitud");
    const classes = useStyles();
    const esEstimacionDirecta = useMemo<boolean>(() => expediente.detalles?.find((x) => x.codigo === '32' && x.valor === '1') !== undefined, [expediente]);

    const masInfo = useMemo(
        () => t(esEstimacionDirecta ? 'solicitud:dialogo_thomas_cook_32_mas_info' : 'solicitud:dialogo_thomas_cook_33_mas_info'),
        [t, esEstimacionDirecta],
    );

    const [tieneCreditosImpagados, setTieneCreditosImpagados] = useState<string>('');
    const [inversionesRealizadas2019, setInversionesRealizadas2019] = useState<string>('');

    const [casilla600, setCasilla600] = useState('');
    const [casilla601, setCasilla601] = useState('');
    const [casilla610, setCasilla610] = useState('');
    const [casilla620, setCasilla620] = useState('');
    const [casilla630, setCasilla630] = useState('');
    const [casilla631, setCasilla631] = useState('');
    const [casilla650, setCasilla650] = useState('');

    const [casilla600Error, setCasilla600Error] = useState(false);
    const [casilla610Error, setCasilla610Error] = useState(false);
    const [casilla620Error, setCasilla620Error] = useState(false);
    const [casilla630Error, setCasilla630Error] = useState(false);
    const [casilla631Error, setCasilla631Error] = useState(false);

    const [errorSeleccionarOpcionThomasCook, setErrorSeleccionarOpcionThomasCook] = useState(false);
    const [errorSeleccionarHaRealizadoInversiones2019, setErrorSeleccionarHaRealizadoInversiones2019] = useState(false);


    const handleValidate = useCallback(() => {
        const errorOpcionThomasCook = tieneCreditosImpagados === '';
        setErrorSeleccionarOpcionThomasCook(errorOpcionThomasCook);

        const errorOpcionInversiones2019 = inversionesRealizadas2019 === '';
        setErrorSeleccionarHaRealizadoInversiones2019(errorOpcionInversiones2019);

        let errorCasilla600o601 = false;

        if (esEstimacionDirecta) {
            const valorCasilla600 = parseFloat(casilla600);
            const errorCasilla600 = isNaN(valorCasilla600) || valorCasilla600 <= 0;
            errorCasilla600o601 = errorCasilla600;
            setCasilla600Error(errorCasilla600);
        } else {
            const valorCasilla601 = parseFloat(casilla601);
            const errorCasilla601 = isNaN(valorCasilla601) || valorCasilla601 <= 0;
            errorCasilla600o601 = errorCasilla601;
            setCasilla600Error(errorCasilla601);
        }

        const valorCasilla610 = parseFloat(casilla610);
        const errorCasilla610 = tieneCreditosImpagados === '1' && (isNaN(valorCasilla610) || valorCasilla610 < 0.01);
        setCasilla610Error(errorCasilla610);

        const valorCasilla620 = parseFloat(casilla620);
        const errorCasilla620 = inversionesRealizadas2019 === '1' && (isNaN(valorCasilla620) || valorCasilla620 < 0.01);
        setCasilla620Error(errorCasilla620);

        const valorCasilla630 = parseFloat(casilla630);
        const errorCasilla630 = isNaN(valorCasilla630) || valorCasilla630 < 0;
        setCasilla630Error(errorCasilla630);

        const valorCasilla631 = parseFloat(casilla631);
        const errorCasilla631 = isNaN(valorCasilla631) || valorCasilla631 < 0;
        setCasilla631Error(errorCasilla631);

        return !errorOpcionThomasCook && !errorOpcionInversiones2019 && !errorCasilla600o601 && !errorCasilla610 && !errorCasilla620 && !errorCasilla630 && !errorCasilla631;
    }, [esEstimacionDirecta, casilla600, casilla601, casilla610, casilla620, casilla630, casilla631, tieneCreditosImpagados, inversionesRealizadas2019]);

    const handleChange600: InputChangeHandler = useCallback((event) => {
        const valor = parseFloat(event.target.value);
        setCasilla600Error(valor <= 0);
        setCasilla600(event.target.value);
    }, [])
    const handleChange601: InputChangeHandler = useCallback((event) => {
        const valor = parseFloat(event.target.value);
        setCasilla600Error(valor <= 0);
        setCasilla601(event.target.value);
    }, [])
    const handleChange610: InputChangeHandler = useCallback((event) => {
        const valor = parseFloat(event.target.value);
        setCasilla610Error(tieneCreditosImpagados === '1' && valor < 0.01);
        setCasilla610(event.target.value);
    }, [tieneCreditosImpagados])
    const handleChange620: InputChangeHandler = useCallback((event) => {
        const valor = parseFloat(event.target.value);
        setCasilla620Error(inversionesRealizadas2019 === '1' && valor < 0.01);
        setCasilla620(event.target.value);
    }, [inversionesRealizadas2019])
    const handleChange630: InputChangeHandler = useCallback((event) => {
        const valor = parseFloat(event.target.value);
        setCasilla630Error(valor < 0);
        setCasilla630(event.target.value);
    }, [])
    const handleChange631: InputChangeHandler = useCallback((event) => {
        const valor = parseFloat(event.target.value);
        setCasilla631Error(valor < 0);
        setCasilla631(event.target.value);
    }, [])

    const handleChangeCreditosImpagadosThomasCook = useCallback((_e: any, value: string) => {
        setCasilla610Error(false);
        setErrorSeleccionarOpcionThomasCook(false);
        if (value === '0') {
            setCasilla610('0');
        } else {
            setCasilla610('');
        }
        setTieneCreditosImpagados(value);
    }, []);

    const handleChangeInversionesRealizadas2019 = useCallback((_e: any, value: string) => {
        setCasilla620Error(false);
        setCasilla630Error(false);
        setCasilla631Error(false);
        setErrorSeleccionarHaRealizadoInversiones2019(false);

        if (value === '0') {
            setCasilla620('0');
            setCasilla630('0');
            setCasilla631('0');
        } else {
            setCasilla620('');
            setCasilla630('');
            setCasilla631('');
        }

        setInversionesRealizadas2019(value);
    }, []);

    const handleChange = useCallback(() => {
        if (!handleValidate()) {
            return;
        }

        if (esEstimacionDirecta) {
            onChange({
                ...expediente,
                detalles: [
                    ...expediente.detalles ?? [],
                    {
                        codigo: '600',
                        origen: 'actividad-economica',
                        valor: casilla600,
                    },
                    {
                        codigo: '610',
                        origen: 'actividad-economica',
                        valor: casilla610,
                    },
                    {
                        codigo: '620',
                        origen: 'actividad-economica',
                        valor: casilla620,
                    },
                    {
                        codigo: '630',
                        origen: 'actividad-economica',
                        valor: casilla630,
                    },
                    {
                        codigo: '631',
                        origen: 'actividad-economica',
                        valor: casilla631,
                    },
                    {
                        codigo: '650',
                        origen: 'actividad-economica',
                        valor: casilla650,
                    }
                ]
            });
        } else {
            onChange({
                ...expediente,
                detalles: [
                    ...expediente.detalles ?? [],
                    {
                        codigo: '601',
                        origen: 'actividad-economica',
                        valor: casilla601,
                    },
                    {
                        codigo: '610',
                        origen: 'actividad-economica',
                        valor: casilla610,
                    },
                    {
                        codigo: '620',
                        origen: 'actividad-economica',
                        valor: casilla620,
                    },
                    {
                        codigo: '630',
                        origen: 'actividad-economica',
                        valor: casilla630,
                    },
                    {
                        codigo: '631',
                        origen: 'actividad-economica',
                        valor: casilla631,
                    },
                    {
                        codigo: '650',
                        origen: 'actividad-economica',
                        valor: casilla650,
                    }
                ]
            });
        }
        window.scrollTo({ top: document.body.scrollHeight });
    }, [casilla600, casilla601, casilla610, casilla620, casilla630, casilla631, casilla650, onChange, esEstimacionDirecta, expediente, handleValidate]);

    useEffect(() => {
        if ((casilla600 !== '' || casilla601 !== '') && casilla620 !== '' && casilla630 !== '' && casilla631) {
            let valor = 0
            if (esEstimacionDirecta) {
                valor = parseFloat(casilla631) - parseFloat(casilla630) - parseFloat(casilla600);
            } else {
                valor = parseFloat(casilla631) - parseFloat(casilla630) - parseFloat(casilla601);
            }
            if (casilla610 !== '') {
                valor = parseFloat(casilla610) + valor;
            }

            if (isNaN(valor)) {
                setCasilla650('');
            } else {
                setCasilla650(valor.toString());
            }
        }
    }, [casilla600, casilla601, casilla610, casilla620, casilla630, casilla631, esEstimacionDirecta])

    return <>
        <Dialog
            maxWidth='lg'
            fullWidth={true}
            open={true}
        >
            <DialogTitle>{t("solicitud:title_modal_thomas_cook")}</DialogTitle>
            <DialogContent>
                <Alert severity="info" style={{ marginBottom: '10px' }}>
                    <PrettierText text={masInfo} />
                </Alert>

                <div className={classes.section}>
                    <TextField
                        label={esEstimacionDirecta ? t('casillero600') : t('casillero601')}
                        onChange={esEstimacionDirecta ? handleChange600 : handleChange601}
                        value={esEstimacionDirecta ? casilla600 : casilla601}
                        error={casilla600Error}
                        helperText={casilla600Error ? t('error:casilla_valor_mayor_a_0') : ''}
                        className={classes.width}
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                        }}
                    />
                </div>

                <div className={classes.section}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" error={errorSeleccionarOpcionThomasCook}>
                            {t('solicitud:dialogo_tiene_creditos_impagados_thomas_cook')}
                        </FormLabel>
                        <RadioGroup row aria-label="position" name="position" defaultValue="end" onChange={handleChangeCreditosImpagadosThomasCook}>
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label={t('Si')}
                            />
                            <FormControlLabel
                                value="0"
                                control={<Radio color="primary" />}
                                label={t('No')}
                            />
                        </RadioGroup>
                        {
                            errorSeleccionarOpcionThomasCook && (
                                <FormHelperText error={true}>
                                    {t('error:tiene_que_seleccionar_una_opcion')}
                                </FormHelperText>
                            )
                        }
                    </FormControl>
                    {
                        tieneCreditosImpagados === '1' && (
                            <TextField
                                label={t('casillero610')}
                                onChange={handleChange610}
                                value={casilla610}
                                error={casilla610Error}
                                helperText={casilla610Error ? t('error:casilla_valor_mayor_a_0') : ''}
                                className={classes.width}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                            />
                        )
                    }
                </div>

                <div className={classes.section}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" error={errorSeleccionarHaRealizadoInversiones2019}>
                            {t('solicitud:dialogo_ha_realizado_inversiones_2019')}
                        </FormLabel>
                        <RadioGroup row aria-label="position" name="position" defaultValue="end" onChange={handleChangeInversionesRealizadas2019}>
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label={t('Si')}
                            />
                            <FormControlLabel
                                value="0"
                                control={<Radio color="primary" />}
                                label={t('No')}
                            />
                        </RadioGroup>
                        {
                            errorSeleccionarHaRealizadoInversiones2019 && (
                                <FormHelperText error={true}>
                                    {t('error:tiene_que_seleccionar_una_opcion')}
                                </FormHelperText>
                            )
                        }
                    </FormControl>

                    {
                        inversionesRealizadas2019 === '1' && (<>
                            <TextField
                                label={t('casillero620')}
                                onChange={handleChange620}
                                value={casilla620}
                                error={casilla620Error}
                                helperText={casilla620Error ? t('error:casilla_valor_mayor_a_0') : ''}
                                className={classes.width}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                            />
                            <TextField
                                label={t('casillero630')}
                                onChange={handleChange630}
                                value={casilla630}
                                error={casilla630Error}
                                helperText={casilla630Error ? t('error:casilla_valor_mayor_igual_a_0') : ''}
                                className={classes.width}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                            />
                            <TextField
                                label={t('casillero631')}
                                onChange={handleChange631}
                                value={casilla631}
                                error={casilla631Error}
                                helperText={casilla631Error ? t('error:casilla_valor_mayor_igual_a_0') : ''}
                                className={classes.width}
                                InputProps={{
                                    inputComponent: NumberFormatCustom as any,
                                }}
                            />
                        </>)
                    }
                </div>

                <TextField
                    label={t('casillero650')}
                    disabled={true}
                    value={casilla650}
                    className={classes.width}
                    InputProps={{
                        inputComponent: NumberFormatCustom as any,
                    }}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onCloseWithoutChanges}
                    color="default"
                >
                    <Term text="cerrar" />
                </Button>
                <Button
                    onClick={handleChange}
                    color="primary"
                    variant="contained"
                >
                    <Term text="confirmar" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

const DialogThomasCookWorkflow: DialogoWorkflow = {
    Component: DialogThomasCook,
    onResponse: (expediente) => {
        const actividadEconomica = [...(expediente.actividadEconomica || [])];
        const esEstimacionDirecta = (expediente.detalles?.findIndex(x => x.codigo === '32' && x.valor === '1') ?? -1) >= 0;

        actividadEconomica.push({
            pregunta: 'solicitud:respuesta_modal_Thomas_Cook',
            opciones: [],
            opcionSeleccionada: {
                value: 'respuesta-moda-thomas-cook',
                label: esEstimacionDirecta ? 'solicitud:thomasCook_estimacion_directa_respuesta' : 'solicitud:thomasCook_no_estimacion_directa_respuesta',
            }
        });

        return {
            ...expediente,
            actividadEconomica,
        }
    },
}

export default DialogThomasCookWorkflow;