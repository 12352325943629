import { withStyles, WithStyles } from '@material-ui/core/styles';
import Term from './term';

import styles from '../../resources/styles/layout.styles';

const PageTitle: React.FC<WithStyles<typeof styles>> = ({ classes }) => {

    return (<div style={{ marginLeft: 50 }}>
        <h1 className={classes.pageTitle}>
            <span className={classes.iconContainerTransparent}>
                <img src={require('../../resources/images/flotador_A.png').default} alt='logo-a' width='100%' height='auto' />
            </span>
            <Term text="titulo" />
        </h1>
        <Term className={classes.pageSubtitle} text="subtitulo" />
    </div>)
}

export default withStyles(styles)(PageTitle);