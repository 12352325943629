import { FC, useEffect, useContext, useCallback, useState } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Hidden, Drawer, WithStyles, withStyles } from '@material-ui/core';
import styles from '../../resources/styles/menu.styles';
import { PageContext } from '../../contexts/app.context';
import StepperIntri from "../organisms/stepper";
import { MenuSteps } from '../../utils/menu.steps';
import { IStatus, ISteper } from '../../gateways/interfaces/steper.interface';

type Props = WithStyles<typeof styles> & RouteComponentProps;

const SideNavLeft: FC<Props> = ({ classes, history }) => {

    // Global states
    const [pageState, pageDispatcher] = useContext(PageContext);
    const [listSteps, setListSteps] = useState<ISteper[]>(MenuSteps(0));
    const handleClose = useCallback(() => pageDispatcher({ type: 'close-nav-left' }), [pageDispatcher]);
    const handleOpen = useCallback(() => pageDispatcher({ type: 'open-nav-left' }), [pageDispatcher]);

    // Events
    const handleChange = useCallback((step: number) => {
        if (listSteps[step] && listSteps[step].status !== IStatus.Blocked) {
            const url = listSteps[step].url;
            history.push(url ? url : '/expediente');
        }
    }, [listSteps, history]);

    // Components
    const stepper = (
        <StepperIntri listSteps={listSteps} stepActive={pageState.pasoActivo} orientation={'vertical'} onChange={handleChange} />
    );

    // Effects
    useEffect(() => {
        setListSteps(MenuSteps(pageState.pasoActivo));
    }, [pageState, pageState.pasoActivo])

    useEffect(() => {
        const unregisterCallback = history.listen(() => {
            handleClose();
        });

        return () => {
            unregisterCallback();
        }
    }, [history, handleClose]);

    return (
        <nav className={classes.drawer}>
            <Hidden mdUp implementation="css">
                <SwipeableDrawer
                    open={pageState.navLeftOpen}
                    onClose={handleClose}
                    onOpen={handleOpen}
                >
                    <a className={[classes.withoutLink, classes.zIndex].join(' ')} href="https://www.caib.es/" target="_blank" rel="noopener noreferrer">
                        <img src={require('../../resources/images/goib.png').default} alt='logo' width='100%' height='auto' />
                    </a>
                    {stepper}
                </SwipeableDrawer>
            </Hidden>

            <Hidden smDown implementation="css">
                <Drawer
                    classes={{ paper: classes.drawerPaper }}
                    variant="permanent"
                    open
                >
                    {stepper}
                </Drawer>
            </Hidden>
        </nav>
    );
};

export default withRouter(withStyles(styles)(SideNavLeft));