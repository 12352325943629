/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@material-ui/core";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import SolicitudTemplate from "./../components/templates/solicitud.template";
import { PageContext } from "../contexts/app.context";
import { ISujeto } from "../gateways/interfaces/sujeto.interfaces";
import useExpedienteGateway from "../gateways/expediente.gateway";
import useStyles from "../resources/styles/solicitud.styles";
import { AlertsContext } from "../contexts/alerts.context";
import { useTranslation } from "react-i18next";
import { IExpediente, OnChangeExpedienteCallback } from "../gateways/interfaces/expediente.interface";
import useContentsGateway from "../gateways/contents.gateway";
import { Afirma } from "../utils/afirma";
import { TipoAccion } from "../gateways/interfaces/afirma.interfaces";
import useAfirmaGateway from "../gateways/afirma.gateway";
import { useHistory } from "react-router";
import { downloadFile } from "../utils/downloadFile";
import { PrettierText } from "../components/atoms/prettier-text.atom";
import { ConfigContext } from "../contexts/config.context";
import moment from "moment";

const SolicitudPage: FC = () => {
    const { fetchContent } = useContentsGateway();
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles(theme);
    const { t } = useTranslation("solicitud");
    const [sujeto, setSujeto] = useState<ISujeto | null>(null);
    const [expediente, setExpediente] = useState<IExpediente>({ detalles: [] });
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingClave, setLoadingClave] = useState(false);
    const [despresentado, setDespresentado] = useState(false);
    const expedientesGateway = useExpedienteGateway();
    const [pageState, pageDispatcher] = useContext(PageContext);
    const [, alertDispatcher] = useContext(AlertsContext);
    const [body, setBody] = useState('');
    const [plantillaAceptar70, setPlantillaAceptar70] = useState('');
    const [open, setOpen] = useState(false);
    const [openDespresentar, setOpenDespresentar] = useState(false);
    const [aceptar, setAceptar] = useState<boolean | null>(null);
    const gateway = useAfirmaGateway();
    const { FECHA_FIN } = useContext(ConfigContext);

    const handleChangeSteps = useCallback((stepAux: number) => {
        setStep(stepAux);
    }, []);

    const handleAceptar = useCallback(() => {
        let detalles = [];
        detalles = expediente.detalles?.filter(x => x.codigo !== '70') || [];
        detalles.push({ codigo: '70', valor: '1', origen: 'presenta' });
        setExpediente({ ...expediente, detalles: detalles });
        setAceptar(true);
    }, [expediente, aceptar]);

    const handleRechazar = useCallback(() => {
        let detalles = [];
        detalles = expediente.detalles?.filter(x => x.codigo !== '70') || [];
        detalles.push({ codigo: '70', valor: '0', origen: 'presenta' });
        setExpediente({ ...expediente, detalles: detalles });
        setAceptar(false);
    }, [expediente, aceptar]);

    const handleLeerTerminos = useCallback(() => {
        setAceptar(null);
    }, []);

    const handleCloseModal = useCallback(() => {
        setAceptar(null);

        setOpen(false);
    }, []);

    const handleSubmitModal = useCallback(async () => {
        setLoadingClave(true);
        const result = await gateway.generarFirma({ tipoAccion: TipoAccion.presentacion, content: { detalles: expediente.detalles, sociedades: expediente.sociedades, } });
        if (result.ok) {
            const afirma = new Afirma(result.idFirma ? result.idFirma : 0, TipoAccion.presentacion, gateway, pageState.lang, (datosFirma: any) => {
                if (datosFirma.ok) {
                    setExpediente(datosFirma.expediente);
                    setOpen(false);
                    pageDispatcher({ type: 'setPaso', payload: 1 })
                    pageDispatcher({ type: 'setExpediente', payload: datosFirma.expediente })
                } else {
                    setOpen(false);
                    alertDispatcher({
                        type: 'show-alert',
                        payload: {
                            message: t(datosFirma.mensaje),
                            variant: 'info',
                        }
                    });
                }
                setLoadingClave(false);
            });
            afirma.processSignature();
        } else {
            setOpen(false);
            setLoadingClave(false);
            alertDispatcher({
                type: 'show-alert',
                payload: {
                    message: t(result.mensaje),
                    variant: 'info',
                }
            });
        }
    }, [expediente, expedientesGateway]);

    const handleChangeExpediente = useCallback<OnChangeExpedienteCallback>((expedienteAux) => {
        setExpediente(expedienteAux);
    }, []);

    const handleAceptarDespresentar = useCallback(async () => {
        if (FECHA_FIN !== null && moment.utc(FECHA_FIN) < moment.utc()) {
            return;
        }
        setLoadingClave(true);
        const result = await gateway.generarFirma({ tipoAccion: TipoAccion.despresentacion, content: { detalles: expediente.detalles, sociedades: expediente.sociedades, } });
        if (result.ok) {
            const afirma = new Afirma(result.idFirma ? result.idFirma : 0, TipoAccion.despresentacion, gateway, pageState.lang, (datosFirma: any) => {
                if (datosFirma.ok) {
                    setDespresentado(true);
                    pageDispatcher({ type: 'cleanExpediente' });
                } else {
                    alertDispatcher({
                        type: 'show-alert',
                        payload: {
                            message: t(datosFirma.mensaje),
                            variant: 'info',
                        }
                    });
                }
                setLoadingClave(false);
            });
            afirma.processSignature();
        } else {
            setLoadingClave(false);
            alertDispatcher({
                type: 'show-alert',
                payload: {
                    message: t(result.mensaje),
                    variant: 'info',
                }
            });
        }
    }, [pageState.lang])

    const handleCloseDespresentar = useCallback(() => {
        if (despresentado) {
            history.push('/seleccion');
        }
        setOpenDespresentar(false);
    }, [despresentado, history])

    const handleDespresentar = useCallback(async () => {
        setOpenDespresentar(true);
    }, [])

    const handleSubmit = useCallback(async (expedienteAux: IExpediente) => {
        setExpediente(expedienteAux);
        setOpen(true);
    }, []);

    const handleImprimir = useCallback(async () => {
        const x = await expedientesGateway.descargarJustificante(pageState.lang);
        downloadFile(x, 'justificante');
    }, [expedientesGateway, pageState, downloadFile])

    useEffect(() => {
        async function load() {
            setLoading(true);
            let response: any = {};
            if (pageState.jwp?.idSujetoRepresentado) {
                response = await expedientesGateway.getSujetoRepresentado();
                response.sujeto.nifRepresentante = pageState.jwp.nif;
                response.sujeto.nombreRepresentante = pageState.jwp.fullname;
            } else {
                response = await expedientesGateway.getSujeto();
            }
            if (pageState.expediente && pageState.expediente.id && response && response.ok) {
                setExpediente(pageState.expediente);
                setSujeto(response.sujeto);
                setLoading(false);
            } else {
                const data = sessionStorage.getItem("expediente")
                if (data && JSON.parse(data).id) {
                    pageDispatcher({ type: 'setExpediente', payload: JSON.parse(data) });
                    setExpediente(JSON.parse(data));
                } else {
                    alertDispatcher({
                        type: 'show-alert',
                        payload: {
                            message: <span dangerouslySetInnerHTML={{ __html: await fetchContent('aviso-autofirma') }} />,
                            variant: 'info',
                        }
                    });
                }
                if (response && response.ok) {
                    setSujeto(response.sujeto);
                    setExpediente({ ...expediente, sujeto: response.sujeto });
                    setLoading(false);
                } else {
                    alertDispatcher({
                        type: 'show-alert',
                        payload: {
                            message: t('error_acceso_solicitud'),
                            variant: 'error',
                        }
                    });
                }
            }
        }
        load();
    }, [])

    useEffect(() => {
        (async () => {
            setBody(await fetchContent('resum'));
            setPlantillaAceptar70(await fetchContent('plantilla_aprobacion_70'));
        })();
    }, [fetchContent]);

    useEffect(() => {
        window.addEventListener("popstate", () => {
            history.push('/');
        });
    }, [])

    return (
        <>
            {
                loading || sujeto == null
                    ?
                    <div className={classes.loader}>
                        <CircularProgress size={50} />
                    </div>
                    :
                    <>
                        <SolicitudTemplate stepActivo={step} expediente={expediente} sujeto={sujeto} onChangeStep={handleChangeSteps} onChangeExpediente={handleChangeExpediente} body={body} onSubmit={handleSubmit} onImprimir={handleImprimir} onDespresentar={handleDespresentar} />
                        <Dialog open={open}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">{t("solicitud:presentacion")}</DialogTitle>
                            <DialogContent>
                                {
                                    !loadingClave
                                        ?
                                        <>
                                            {
                                                aceptar === false &&
                                                <PrettierText text={t('solicitud:rechazar_condiciones')} />
                                            }
                                            {
                                                aceptar === null &&
                                                <PrettierText text={t('solicitud:acepta_condiciones')} />
                                            }
                                            {
                                                aceptar === true &&
                                                <div dangerouslySetInnerHTML={{ __html: plantillaAceptar70 }} />
                                            }

                                        </>
                                        :
                                        <>
                                            <CircularProgress size={50} />
                                        </>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={loadingClave} onClick={handleCloseModal} color="default">
                                    {t('global:cerrar')}
                                </Button>
                                <div style={{ flex: 1 }} />
                                {
                                    aceptar === null &&
                                    <>
                                        <Button disabled={loadingClave} onClick={handleRechazar} color="secondary">
                                            {t('global:rechazar')}
                                        </Button>
                                        <Button disabled={loadingClave} onClick={handleAceptar} color="primary" autoFocus>
                                            {t('global:aceptar')}
                                        </Button>
                                    </>
                                }
                                {
                                    aceptar === false &&
                                    <>
                                        <Button disabled={loadingClave} onClick={handleLeerTerminos} color="primary">
                                            {t('global:volver_leer')}
                                        </Button>
                                        <Button disabled={loadingClave} onClick={handleSubmitModal} color="primary" autoFocus>
                                            {t('solicitud:Presentar')}
                                        </Button>
                                    </>
                                }
                                {
                                    aceptar === true &&
                                    <>
                                        <Button disabled={loadingClave} onClick={handleSubmitModal} color="primary" autoFocus>
                                            {t('solicitud:Presentar')}
                                        </Button>
                                    </>
                                }
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openDespresentar}>
                            <DialogTitle id="alert-dialog-title">{t("solicitud:despresentar")}</DialogTitle>
                            <DialogContent>
                                {
                                    !loadingClave
                                        ?
                                        <>
                                            {
                                                !despresentado
                                                    ?
                                                    t('solicitud:confirmar_despresentacion')
                                                    :
                                                    t('solicitud:despresentado')
                                            }

                                        </>
                                        :
                                        <>
                                            <CircularProgress size={50} />
                                        </>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div style={{ flex: 1 }} />
                                <Button disabled={loadingClave} onClick={handleCloseDespresentar} color="default">
                                    {t('global:cerrar')}
                                </Button>
                                {
                                    !despresentado ?
                                        !(FECHA_FIN !== null && moment.utc(FECHA_FIN) < moment.utc()) &&
                                        <Button disabled={loadingClave} onClick={handleAceptarDespresentar} color="secondary" autoFocus>
                                            {t('global:despresentar')}
                                        </Button>
                                        :
                                        <Button disabled={loadingClave} onClick={handleImprimir} color="secondary" autoFocus>
                                            {t('global:descargar_justificante_despresentacion')}
                                        </Button>
                                }

                            </DialogActions>
                        </Dialog>
                    </>
            }
        </>
    );
};


export default SolicitudPage;
