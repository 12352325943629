import { FC, useState, useCallback } from 'react';
import { Dialog, Button, DialogActions, DialogContent, Divider, makeStyles, TextField, DialogTitle } from "@material-ui/core";
import Term from '../term';
import { useTranslation } from 'react-i18next';
import { InputChangeHandler } from '../../../utils/events';
import { NumberFormatCustom } from '../number-format.custom';
import { DialogFormProps, DialogoWorkflow } from '../workflow/interfaces';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    width: {
        width: '100%'
    },
    content: {
        margin: 5,
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
        marginLeft: 10,
        marginBottom: -8,
    }
}));


const DialogCasilla39: FC<DialogFormProps> = ({ expediente, onChange, onCloseWithoutChanges }) => {
    const { t } = useTranslation("");
    const classes = useStyles();
    const [importe, setImporte] = useState('');
    const [importe2, setImporte2] = useState('');

    const handleChangeImporte: InputChangeHandler = useCallback((event) => {
        setImporte(event.target.value);
    }, []);

    const handleChangeImporte2: InputChangeHandler = useCallback((event) => {
        setImporte2(event.target.value);
    }, []);

    const handleChange = useCallback(() => {
        if (importe !== '' && importe2 !== '') {
            onChange({
                ...expediente,
                detalles: [
                    ...expediente.detalles ?? [],
                    { codigo: '277', origen: 'actividad-economica', valor: importe },
                    { codigo: '278', origen: 'actividad-economica', valor: importe2 },
                    { codigo: '1010', origen: 'actividad-economica', valor: (parseInt(importe, 10) === 0 ? '0' : (parseInt(importe2, 10) - parseInt(importe, 10)) / parseInt(importe2, 10)).toString() }
                ]
            });
        }
    }, [importe, onChange, importe2, expediente]);

    return <>
        <Dialog
            maxWidth='md'
            fullWidth={true}
            open={true}
        >
            <DialogTitle>{t("solicitud:title_modal_39")}</DialogTitle>
            <DialogContent className={classes.content}>
                <Alert severity="info" style={{ marginBottom: '1rem' }}>
                    {t('solicitud:dialogo_volumen_operaciones_277_278_mas_info')}
                </Alert>
                <TextField
                    label={t('solicitud:casillero277')}
                    className={classes.width}
                    onChange={handleChangeImporte}
                    value={importe}
                    InputProps={{
                        inputComponent: NumberFormatCustom as any,
                    }}
                />
                <TextField
                    label={t('solicitud:casillero278')}
                    className={classes.width}
                    onChange={handleChangeImporte2}
                    value={importe2}
                    InputProps={{
                        inputComponent: NumberFormatCustom as any,
                    }}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onCloseWithoutChanges}
                    color="default"
                >
                    <Term text="cerrar" />
                </Button>
                <Button
                    onClick={handleChange}
                    disabled={importe === '' || importe2 === ''}
                    color="primary"
                    variant="contained"
                >
                    <Term text="confirmar" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

const DialogCasilla39Workflow: DialogoWorkflow = {
    Component: DialogCasilla39,
    onResponse: (expediente) => {
        const actividadEconomica = [...(expediente.actividadEconomica || [])];

        actividadEconomica?.push({
            pregunta: 'solicitud:respuesta_modal_39',
            opciones: [],
            opcionSeleccionada: {
                value: 'respuesta-dialogo-39',
                label: 'solicitud:baseImponible_1010',
            }
        });

        return {
            ...expediente,
            actividadEconomica,
        }
    },
}

export default DialogCasilla39Workflow;