import { FC, useState, useCallback, useMemo } from 'react';
import { Dialog, Button, DialogActions, DialogContent, Divider, makeStyles, TextField, DialogTitle } from "@material-ui/core";
import Term from '../term';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DialogFormProps, DialogoWorkflow } from '../workflow/interfaces';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    content: {
        margin: 5,
    },
    width: {
        width: '100%'
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
        marginLeft: 10,
        marginBottom: -8,
    }
}));

const DialogCasilla47: FC<DialogFormProps> = ({ expediente, onChange, onCloseWithoutChanges }) => {
    const { t } = useTranslation("solicitud");
    const classes = useStyles();
    const [fecha, setFecha] = useState('');

    const errorFechaActividad = useMemo(() => moment(fecha) > moment('20200331') || moment(fecha) < moment('20190101'), [fecha]);

    const handleFecha = useCallback((x: string) => {
        setFecha(x);
    }, [])

    const handleChange = useCallback(() => {
        onChange({
            ...expediente,
            detalles: [
                ...expediente.detalles ?? [],
                { codigo: '47', origen: 'actividad-economica', valor: fecha }
            ],
        });
    }, [expediente, fecha, onChange]);

    return <>
        <Dialog
            maxWidth='xs'
            fullWidth={true}
            open={true}
        >
            <DialogTitle>{t("solicitud:title_modal_47")}</DialogTitle>
            <DialogContent className={classes.content}>
                <TextField
                    label={t("solicitud:fecha_actividad")}
                    type="date"
                    className={classes.width}
                    style={{ flex: 1, paddingRight: 5 }}
                    onChange={(e) => handleFecha(e.target.value)}
                    error={errorFechaActividad}
                    helperText={t('error:error_fecha_actividad')}
                    value={fecha}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onCloseWithoutChanges}
                    color="default"
                >
                    <Term text="cerrar" />
                </Button>
                <Button
                    onClick={handleChange}
                    disabled={fecha === '' || errorFechaActividad}
                    color="primary"
                    variant="contained"
                >
                    <Term text="confirmar" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

const DialogCasilla47Workflow: DialogoWorkflow = {
    Component: DialogCasilla47,
    onResponse: (expediente) => {
        const actividadEconomica = [...(expediente.actividadEconomica || [])];

        actividadEconomica.forEach(x => {
            if (x.pregunta === 'solicitud:fechaActividades_pregunta') {
                x.opcionSeleccionada = {
                    value: 'respuesta-dialogo-47',
                    label: 'solicitud:fecha_casilla46',
                };
            }
        })

        return {
            ...expediente,
            actividadEconomica,
        }
    },
}

export default DialogCasilla47Workflow;