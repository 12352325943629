import { Card, CardMedia, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import React, { useCallback } from 'react'; //
import { useTranslation } from 'react-i18next';
import useStyles from '../../resources/styles/generic.styles';
import imagen1 from '../../resources/images/card1.jpg';
import imagen2 from '../../resources/images/card2.jpg';
import imagen3 from '../../resources/images/card3.jpg';
import imagen4 from '../../resources/images/card4.jpg';

const Imagenes = {
    imagen1,
    imagen2,
    imagen3,
    imagen4
}

interface IProps {
    titulo: string,
    contenido: string,
    imagen: keyof typeof Imagenes,
    accionable: boolean,
    onClick: () => void,
}

const CardHome: React.FC<IProps> = ({ titulo, contenido, accionable, imagen, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClick = useCallback(() => {
        if (accionable) {
            onClick();
        }
    }, [accionable, onClick])

    return (
        <Card className={classes.rootCard} onClick={handleClick}>
            <CardMedia
                className={classes.media}
                image={Imagenes[imagen]}
                title={titulo}
            />
            <CardContent style={{ flex: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                    {titulo}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {contenido}
                </Typography>
            </CardContent>
            {
                accionable &&
                <CardActions>
                    <Button size="small" color="primary" onClick={handleClick}>
                        {t('solicitud:acceder')}
                    </Button>
                </CardActions>
            }
        </Card>
    )
}

export default CardHome;

