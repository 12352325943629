import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@material-ui/core";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IExpediente, IJustificantePago, IJustificantePagoProvisional } from "../../gateways/interfaces/expediente.interface";
import { PrettierText } from "../atoms/prettier-text.atom";
import NumberFormatCustom from "./number-format.custom";
import TablaPagos from "./tabla-pagos";

interface IProps {
    justificantes: IJustificantePagoProvisional[];
    expediente: IExpediente;
    open: boolean;
    handleAdd: (event: any, justificantes: IJustificantePagoProvisional[]) => void;
    handleOpen: () => void;
    handleClose: () => void;
    handleChangeJustificantes: (justificantes: IJustificantePagoProvisional[]) => void;
    handleDelete: (id: number) => void;
    handleView: (id: string) => void;
    expedientBlocked: boolean;
    handleOrderJustificantes: (justificantes: IJustificantePago[]) => void;
}

const RelacionPagos: React.FC<IProps> = ({ justificantes, expediente, open, handleAdd, handleOpen, handleClose, handleChangeJustificantes, handleDelete, handleView, handleOrderJustificantes, expedientBlocked }) => {
    const fechaPagoMin: string = "2021-06-14";
    const fechaEmisionMin: string = "2020-03-01";

    const { t } = useTranslation();

    const [nombre, setNombre] = useState('');
    const [nif, setNif] = useState('');
    const [errorNif, setErrorNif] = useState(false);
    const [messageNif, setMessageNif] = useState('');
    const [referencia, setReferencia] = useState('');
    const [fechaPago, setFechaPago] = useState('');
    const [errorFechaPago, setErrorFechaPago] = useState(false);
    const [messageFechaPago, setMessageFechaPago] = useState('');
    const [importe, setImporte] = useState('');
    const [, setFile] = useState<File | null>(null);
    const [fechaEmision, setFechaEmision] = useState('');
    const [errorFechaEmision, setErrorFechaEmision] = useState(false);
    const [messageFechaEmision, setMessageFechaEmision] = useState('');
    const [baseImponible, setBaseImponible] = useState('');

    const handleNombre = useCallback((x: string) => {
        setNombre(x);
    }, []);

    const handleNif = useCallback((x: string) => {
        if (x.length <= 30) {
            setErrorNif(false);
            setMessageNif('');
        } else {
            setErrorNif(true);
            setMessageNif(t('error:nif_no_validado'));
        }
        setNif(x);
    }, [t]);


    const handleReferencia = useCallback((x: string) => {
        setReferencia(x);
    }, []);

    const handleFecha = useCallback((x: string) => {
        setFechaPago(x);
    }, []);

    const handleFechaEmision = useCallback((x: string) => {
        setFechaEmision(x);
    }, []);

    const handleImporte = useCallback((x: string) => {
        setImporte(x);
    }, []);

    const handleBaseImponible = useCallback((x: string) => {
        setBaseImponible(x);
    }, []);

    // const handleFile = useCallback((event: any) => {
    //     setFile(event.target.files[0]);
    // }, []);

    const readBlobFile = useCallback((x: File) => {
        return new Promise<Blob>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const blob = new Blob([reader.result as ArrayBuffer], { type: x.type });
                resolve(blob);
            };
            reader.readAsArrayBuffer(x);
        })
    }, []);

    const seeFile = useCallback(async (justificante: IJustificantePagoProvisional) => {
        const x = justificante.fichero;
        const blob = await readBlobFile(x);

        const fileUrl = URL.createObjectURL(blob);
        const w = window.open(fileUrl, '_blank');
        w && w.focus();
    }, [readBlobFile]);

    const handleSubmitModal = useCallback((event: any) => {
        handleAdd(event, justificantes);
    }, [handleAdd, justificantes]);

    const addProvisional = useCallback(() => {
        if (!nif || !nombre || !referencia || !fechaPago || !importe || !fechaEmision) {
            return;
        }
        handleChangeJustificantes([...justificantes, {
            nifProveedor: nif,
            nombreProveedor: nombre,
            numeroReferencia: referencia,
            fechaPago: moment(fechaPago).format('DD/MM/yyyy'),
            importe: importe,
            // fichero: file,
            numeroPrelacion: ((expediente?.justificantes?.length || 0) + justificantes.length + 1).toString(),
            baseImponible: baseImponible,
            fechaEmision: moment(fechaEmision).format('DD/MM/yyyy')
        } as IJustificantePagoProvisional]);
        setNif('');
        setNombre('');
        setReferencia('');
        setFechaPago('');
        setImporte('');
        setFile(null);
        setBaseImponible('');
        setFechaEmision('');
    }, [fechaPago, handleChangeJustificantes, importe, justificantes, nif, nombre, referencia, baseImponible, fechaEmision, expediente?.justificantes]);

    const deleteProvisional = useCallback((x: IJustificantePagoProvisional) => {
        const just = justificantes.filter((y) => x.numeroReferencia !== y.numeroReferencia)
        handleChangeJustificantes(just);
    }, [handleChangeJustificantes, justificantes]);

    const deleteJustificante = useCallback((justificante: IJustificantePago) => {
        if (justificante !== undefined) {
            handleDelete(justificante.id);
        }
    }, [handleDelete]);

    const downloadJustificante = useCallback((justificante: IJustificantePago) => {
        handleView(justificante.idDocumento);
    }, [handleView]);

    const handleDown = useCallback((index: number) => {
        if (expediente.justificantes !== undefined && expediente.justificantes.length > 1) {
            let justificantesAux = [...expediente.justificantes];
            [justificantesAux[index], justificantesAux[index + 1]] = [justificantesAux[index + 1], justificantesAux[index]];
            handleOrderJustificantes(justificantesAux);
        }
    }, [expediente.justificantes, handleOrderJustificantes]);

    const handleUp = useCallback((index: number) => {
        if (expediente.justificantes !== undefined && expediente.justificantes.length > 1) {
            let justificantesAux = [...expediente.justificantes];
            [justificantesAux[index], justificantesAux[index - 1]] = [justificantesAux[index - 1], justificantesAux[index]];
            handleOrderJustificantes(justificantesAux);
        }
    }, [expediente.justificantes, handleOrderJustificantes]);

    useEffect(() => {
        if (new Date(fechaPago) < new Date(fechaEmision)) {
            setErrorFechaEmision(true);
            setMessageFechaEmision(t('error:fecha_emision_no_validada'));
            setErrorFechaPago(true);
            setMessageFechaPago(t('error:fecha_pago_no_validada'));
        } else if (new Date(fechaPago) < new Date(fechaPagoMin) || new Date(fechaPago) > new Date()) {
            setErrorFechaPago(true);
            setMessageFechaPago(t('error:fecha_pago_no_validada'));
        } else if (new Date(fechaEmision) < new Date(fechaEmisionMin) || new Date(fechaEmision) > new Date()) {
            setErrorFechaEmision(true);
            setMessageFechaEmision(t('error:fecha_emision_no_validada'));
        } else {
            setErrorFechaEmision(false);
            setMessageFechaEmision('');
            setErrorFechaPago(false);
            setMessageFechaPago('');
        }

    }, [t, fechaEmision, fechaPago])

    return (
        <>
            <div style={{ display: 'flex', padding: 10 }}>
                <div style={{ flex: 1, marginRight: 40 }}>
                    <PrettierText text={t('solicitud:relacion-deudas')} />
                </div>
                {!expedientBlocked &&
                    <div>
                        <Button variant="contained" color="default" onClick={handleOpen} style={{ marginBottom: 20, marginLeft: 10 }}>
                            {t('solicitud:anyadir')}
                        </Button>
                    </div>
                }
            </div>
            <TablaPagos
                justificantes={expediente.justificantes}
                showAttachments={true}
                handleViewDocument={downloadJustificante}
                handleDeleteDocument={deleteJustificante}
                expedientBlocked={expedientBlocked}
                handleDown={handleDown}
                handleUp={handleUp}
            />
            {!expedientBlocked &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogTitle id="alert-dialog-title">{t("solicitud:Añadir Entidad")}</DialogTitle>
                    <DialogContent>
                        <div >
                            <div style={{ display: "flex" }}>
                                <TextField
                                    label={t("solicitud:creditor")}
                                    style={{ flex: 1, paddingRight: 20 }}
                                    onChange={(e) => handleNombre(e.target.value)}
                                    value={nombre}
                                />
                                <TextField
                                    label={t("solicitud:nif")}
                                    style={{ paddingRight: 20 }}
                                    onChange={(e) => handleNif(e.target.value)}
                                    value={nif}
                                    error={errorNif}
                                    helperText={messageNif}
                                />
                                <TextField
                                    label={t("solicitud:numero-documento")}
                                    style={{ flex: 1, paddingRight: 20 }}
                                    onChange={(e) => handleReferencia(e.target.value)}
                                    value={referencia}
                                />
                            </div>
                            <div style={{ display: "flex" }}>
                                <TextField
                                    label={t("solicitud:fecha-emision")}
                                    type="date"
                                    style={{ flex: 1, paddingRight: 20 }}
                                    onChange={(e) => handleFechaEmision(e.target.value)}
                                    value={fechaEmision}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { max: moment(new Date()).format("yyyy-MM-DD"), min: fechaEmisionMin } }}
                                    error={errorFechaEmision}
                                    helperText={messageFechaEmision}
                                    lang="es-ES"
                                />
                                <TextField
                                    label={t('solicitud:base-imponible')}
                                    id="baseImponible"
                                    style={{ flex: 1, paddingRight: 20 }}
                                    onChange={(e) => handleBaseImponible(e.target.value)}
                                    value={baseImponible}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom as any,
                                    }}
                                />
                                <TextField
                                    label={t('solicitud:importe_total')}
                                    id="importe"
                                    style={{ paddingRight: 20 }}
                                    onChange={(e) => handleImporte(e.target.value)}
                                    value={importe}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom as any,
                                    }}
                                />
                                <TextField
                                    label={t("solicitud:fechaPago")}
                                    type="date"
                                    style={{ paddingRight: 20 }}
                                    onChange={(e) => handleFecha(e.target.value)}
                                    value={fechaPago}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { max: moment(new Date()).format("yyyy-MM-DD"), min: fechaPagoMin } }}
                                    error={errorFechaPago}
                                    helperText={messageFechaPago}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", marginTop: 15 }}>
                            {/* <div style={{ flex: 1 }}>
                                <input
                                    required={true}
                                    type="file"
                                    name="justificante"
                                    id="justificante"
                                    accept="image/*,.pdf"
                                    style={{ display: "none" }}
                                    onChange={handleFile}
                                />
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        const justificante = document.getElementById('justificante');
                                        if (justificante) {
                                            justificante.click();
                                        }
                                    }}>
                                    {t('solicitud:adjuntar_justificante')}
                                </Button>
                            </div> */}
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!nif || !nombre || !referencia || !fechaPago || !importe || !fechaEmision || errorFechaPago || errorFechaEmision || errorNif}
                                    onClick={addProvisional}
                                >
                                    {t('solicitud:preparar')}
                                </Button>
                            </div>
                        </div>
                        <div style={{ flex: 1, marginTop: 15 }}>
                            <TablaPagos
                                justificantes={justificantes}
                                showAttachments={true}
                                handleViewDocument={seeFile}
                                handleDeleteDocument={deleteProvisional}
                                expedientBlocked={expedientBlocked}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {t('Cerrar')}
                        </Button>
                        <Button onClick={handleSubmitModal} color="primary" disabled={justificantes.length <= 0}>
                            {t('Presentar')}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default RelacionPagos;