import { FC, useState, useCallback } from 'react';
import { Dialog, Button, DialogActions, DialogContent, Divider, makeStyles, TextField, DialogTitle, FormControl, FormHelperText, MenuItem, Select, InputLabel } from "@material-ui/core";
import Term from '../term';
import { useTranslation } from 'react-i18next';
import { InputChangeHandler } from '../../../utils/events';
import { DialogFormProps, DialogoWorkflow } from '../workflow/interfaces';
import { Alert } from '@material-ui/lab';
import { PrettierText } from '../../atoms/prettier-text.atom';

const letrasCifs = "ABCDEFGHJPQSUV".split('');

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    width: {
        width: '100%'
    },
    content: {
        margin: 5,
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
        marginLeft: 10,
        marginBottom: -8,
    }
}));

const DialogCasilla49: FC<DialogFormProps> = ({ expediente, onChange, onCloseWithoutChanges }) => {
    const { t } = useTranslation("solicitud");
    const classes = useStyles();
    const [letra, setLetra] = useState(expediente?.detalles?.find(x => x.codigo === '5')?.valor[0]);
    const [nif, setNif] = useState((expediente.detalles?.find(x => x.codigo === '5')?.valor || ''));
    const [razonSocial, setRazonSocial] = useState('');
    const [errorNif, setErrorNif] = useState(false);
    const [errorRS, setErrorRS] = useState(false);

    const handleValidate = useCallback(() => {
        let result = true;
        if ((expediente.detalles?.find(x => x.codigo === '5')?.valor || '')[0] === letra) {
            setErrorNif(true)
            result = false;
        } else {
            setErrorNif(false)
        }
        if (razonSocial && (razonSocial.length <= 0 || razonSocial.length > 100)) {
            setErrorRS(true);
            result = false;
        } else {
            setErrorRS(false);
        }
        return result;
    }, [expediente.detalles, razonSocial, letra])

    const handleChangeLetra = useCallback((valor: string) => {
        if (nif[0] === valor) {
            setErrorNif(true);
        } else {
            setErrorNif(false)
        }
        setLetra(valor);
        setNif(valor + nif.substring(1));
    }, [nif]);

    const handleChangeRazonSocial: InputChangeHandler = useCallback((event) => {
        setRazonSocial(event.target.value);
        if (event.target.value && event.target.value.length <= 0) {
            setErrorRS(true);
        } else {
            setErrorRS(false);
        }
    }, []);

    const handleChange = useCallback(() => {
        if (handleValidate()) {
            onChange({
                ...expediente,
                detalles: [
                    ...expediente.detalles ?? [],
                    { codigo: '92', origen: 'actividad-economica', valor: nif },
                    { codigo: '93', origen: 'actividad-economica', valor: razonSocial }
                ]
            });
        } else {
            return;
        }
    }, [nif, onChange, razonSocial, expediente, handleValidate]);

    return <>
        <Dialog
            maxWidth='xs'
            fullWidth={true}
            open={true}
        >
            <DialogTitle>{t("solicitud:title_modal_49")}</DialogTitle>
            <DialogContent className={classes.content}>
                <Alert severity="info" style={{ marginBottom: '10px' }}>
                    <PrettierText text={t('solicitud:dialogo_casilla_49_mas_info')} />
                </Alert>

                <FormControl className={classes.width}>
                    <InputLabel error={errorNif} id="demo-simple-select-label">{t("solicitud:casillero92")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={letra}
                        error={errorNif}
                        onChange={(e: any) => handleChangeLetra(e.target.value)}
                    >
                        {
                            letrasCifs.map((x, i) => (
                                expediente?.detalles?.find(x => x.codigo === '5')?.valor[0] !== x &&
                                <MenuItem key={`letras_sociedades${x}_${i}`} value={x}>{x}{nif.substring(1)}</MenuItem>
                            ))
                        }
                    </Select>
                    {
                        errorNif &&
                        <FormHelperText error={true}>
                            {t('error:error_nif_sociedades')}
                        </FormHelperText>
                    }
                </FormControl>
                <TextField
                    label={t('casillero_93')}
                    error={errorRS}
                    helperText={errorRS && t('error:error_razon_social')}
                    className={classes.width}
                    onChange={handleChangeRazonSocial}
                    value={razonSocial}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onCloseWithoutChanges}
                    color="default"
                >
                    <Term text="cerrar" />
                </Button>
                <Button
                    onClick={handleChange}
                    disabled={nif === '' || razonSocial === ''}
                    color="primary"
                    variant="contained"
                >
                    <Term text="confirmar" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

const DialogCasilla49Workflow: DialogoWorkflow = {
    Component: DialogCasilla49,
    onResponse: (expediente) => {
        const actividadEconomica = [...(expediente.actividadEconomica || [])];

        actividadEconomica.push({
            pregunta: 'solicitud:respuesta_modal_49',
            opciones: [],
            opcionSeleccionada: {
                value: 'respuesta-dialogo-49',
                label: 'solicitud:casillero49_respuesta',
            }
        });

        return {
            ...expediente,
            actividadEconomica,
        }
    },
}

export default DialogCasilla49Workflow;