import { FC, useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ResultadoSolicitudTemplate from "../components/templates/resultado-solicitud.template";
import { PageContext } from "../contexts/app.context";
import { EstadoExpediente } from "../gateways/interfaces/expediente.interface";
import useContentsGateway from "../gateways/contents.gateway";
import useExpedienteGateway from "../gateways/expediente.gateway";
import { downloadFile } from "../utils/downloadFile";

export const ResultadoSolicitudPage: FC<RouteComponentProps> = ({ history }) => {
    const { fetchContent } = useContentsGateway();

    const [pageState, pageDispatcher] = useContext(PageContext);
    const { getDocsRecursos, getFile } = useExpedienteGateway();

    const [body, setBody] = useState('');
    const [docs, setDocs] = useState<any>(null);

    const expediente = pageState.expediente;

    const handleViewDocument = useCallback(async (id: string, name?: string) => {
        const result = await getFile(id);
        downloadFile(result, name ?? 'documento recurso');
    }, [getFile]);

    useEffect(() => {
        (async () => {
            const docs = await getDocsRecursos(expediente?.id ?? 0);
            setDocs(docs);
            switch (expediente?.idEstado) {
                case EstadoExpediente.Aprobada:
                case EstadoExpediente.PagosJustificados:
                    setBody(await fetchContent('resultado_aprobada'));
                    break;
                case EstadoExpediente.Rechazada:
                    setBody(await fetchContent('resultado_rechazada'));
                    break;
                case EstadoExpediente.Desistimiento:
                    setBody(await fetchContent('resultado_desistimiento'));
                    break;
                default:
                    setBody(await fetchContent('resultado_pendiente'));
                    break;
            }

        })();
    }, [fetchContent, expediente, getDocsRecursos]);

    useEffect(() => {
        if (expediente) {
            pageDispatcher({
                type: 'setMenu',
                menu: true,
            });
        } else {
            history.push('/');
        }
    }, [history, pageDispatcher, expediente]);

    useEffect(() => {
        window.addEventListener("popstate", () => {
            history.push('/');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        {
            expediente !== null &&
            <ResultadoSolicitudTemplate body={body} expediente={expediente} docs={docs} handleView={handleViewDocument}/>
        }
    </>
    );
}