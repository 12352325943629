import { IExpediente } from "./expediente.interface";

export interface IFirma {
    tipoAccion: TipoAccion;
    content: any;
}

export interface IFirmaResponse {
    ok: boolean;
    idDocumento?: string;
    idFirma?: number | undefined;
    mensaje: string;
}

export interface ISignature {
    textoFirmado: string;
    userAgent: string;
    browser: string | null;
    platform: string;
    idFirma?: number;
    idMensaje?: number;
    idDocumento?: number;
    tipoAccion?: TipoAccion;
    idioma: string;
}

export interface ISignatureResponse {
    ok: boolean;
    expediente?: IExpediente;
    mensaje?: string;
}

export enum TipoAccion {
    presentacion = 1,
    despresentacion = 2,
    justificacion = 3,
    cumplimiento = 11,
}
