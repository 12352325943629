import { Card } from "@material-ui/core";
import { FC, useCallback, useContext, useEffect } from "react";
import MainLayout from "./_main.layout";
import { IStatus, ISteper } from "../../gateways/interfaces/steper.interface";
import { mdiAccount, mdiAccountGroup, mdiFileCertificateOutline, mdiFileDocumentOutline } from "@mdi/js";
import StepperIntri from "../organisms/stepper";
import Solicitante from '../organisms/solicitante.card';
import { PageContext } from "../../contexts/app.context";
import ActividadEconomica from "../organisms/actividad-economica";
import { ISujeto } from "../../gateways/interfaces/sujeto.interfaces";
import DeclaraResponsablemente from "../organisms/declara-responsablemente.card";
import Presentacion from "../organisms/presentacion.card";
import useStyles from '../../resources/styles/solicitud.styles';
import { IExpediente, OnChangeExpedienteCallback } from "../../gateways/interfaces/expediente.interface";

const listSteps: ISteper[] = [
    {
        index: 0,
        name: 'solicitante',
        icon: mdiAccount,
        status: IStatus.Opened
    },
    {
        index: 1,
        name: 'actividad_economica',
        icon: mdiAccountGroup,
        status: IStatus.Blocked
    },
    {
        index: 2,
        name: 'declara_responsablemente',
        icon: mdiFileCertificateOutline,
        status: IStatus.Blocked
    },
    {
        index: 3,
        name: 'presentacion',
        icon: mdiFileDocumentOutline,
        status: IStatus.Blocked
    },
]

type ComponentSelectorProps = {
    step: number;
    expediente: IExpediente;
    sujeto: ISujeto;
    body: string;
    onChange: OnChangeExpedienteCallback;
    onChangeStep: (step: number, block: boolean) => void;
    onSubmit: (expediente: IExpediente) => void;
    onImprimir: () => void;
    onDespresentar: () => void;
}

const ComponentSelector: FC<ComponentSelectorProps> = ({
    step, expediente, sujeto, body, onChange, onChangeStep, onSubmit, onImprimir, onDespresentar
}) => {
    switch (step) {
        case 0:
            return <Solicitante expediente={{ ...expediente }} sujeto={sujeto} step={step} onChange={onChange} changeStep={onChangeStep} />
        case 1:
            return <ActividadEconomica expediente={{ ...expediente }} sujeto={sujeto} step={step} onChange={onChange} changeStep={onChangeStep} />
        case 2:
            return <DeclaraResponsablemente expediente={{ ...expediente }} sujeto={sujeto} step={step} onChange={onChange} changeStep={onChangeStep} />
        case 3:
            return <Presentacion expediente={{ ...expediente }} sujeto={sujeto} step={step} body={body} onChange={onChange} changeStep={onChangeStep} onSubmit={onSubmit} onImprimir={onImprimir} onDespresentar={onDespresentar} />
        default:
            return null;
    }
}

interface IProps {
    stepActivo: number,
    expediente: IExpediente,
    sujeto: ISujeto,
    body: string,
    onChangeStep: (value: number) => void;
    onChangeExpediente: OnChangeExpedienteCallback;
    onSubmit: (value: IExpediente) => void;
    onImprimir: () => void;
    onDespresentar: () => void;
}


const SolicitudTemplate: FC<IProps> = ({ stepActivo, expediente, sujeto, body, onChangeStep, onChangeExpediente, onSubmit, onImprimir, onDespresentar }) => {
    const classes = useStyles();

    const [, pageDispatcher] = useContext(PageContext);

    const handleImprimir = useCallback(() => {
        onImprimir();
    }, [onImprimir])

    const handleChangeSteps = useCallback((stepAux: number, block: boolean) => {
        if (block) {
            for (const x of listSteps) {
                if (x.index <= stepAux) {
                    x.status = IStatus.Opened;
                } else {
                    x.status = IStatus.Blocked;
                }
            }
        } else {
            listSteps[stepAux].status = IStatus.Opened;
        }
        onChangeStep(stepAux);
    }, [onChangeStep]);

    const handleChangeExpediente = useCallback<OnChangeExpedienteCallback>((expedienteAux) => {
        onChangeExpediente(expedienteAux);
    }, [onChangeExpediente]);

    useEffect(() => {
        pageDispatcher({
            type: 'setMenu',
            menu: true,
        });
    }, [pageDispatcher])

    return (
        <MainLayout>
            {!expediente.id ?
                <>
                    <div className={classes.root}>
                        <Card style={{ flex: 1 }}>
                            <StepperIntri listSteps={listSteps} stepActive={stepActivo} orientation={'horizontal'} onChange={handleChangeSteps} />
                        </Card>
                    </div>
                    <div style={{ flex: 1, paddingBottom: 100 }}>
                        <ComponentSelector
                            expediente={expediente}
                            step={stepActivo}
                            sujeto={sujeto}
                            body={body}
                            onChange={handleChangeExpediente}
                            onChangeStep={handleChangeSteps}
                            onSubmit={onSubmit}
                            onImprimir={handleImprimir}
                            onDespresentar={onDespresentar}
                        />
                    </div>
                </>
                :
                <>
                    <Presentacion
                        expediente={{ ...expediente }}
                        sujeto={sujeto}
                        step={3}
                        body={body}
                        onChange={handleChangeExpediente}
                        changeStep={onChangeStep}
                        onSubmit={onSubmit}
                        onImprimir={handleImprimir}
                        onDespresentar={onDespresentar}
                    />
                </>
            }
        </MainLayout>
    )
}

export default SolicitudTemplate;