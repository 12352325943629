import { FC } from "react"
import { useTranslation } from "react-i18next"
import MainLayout from '../components/templates/_main.layout';

export const LanguageTest: FC = () => {
    const { t, i18n } = useTranslation(['solicitud']);

    return (
        <MainLayout>
            <h1>{t('solicitud:subtitulo')}</h1>
            <p>current language: {i18n.language}</p>
        </MainLayout>
    )
}