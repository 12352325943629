import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { IExpediente } from "../../gateways/interfaces/expediente.interface";
import { parseFloatIntelligent } from "../../utils/brain-parse";
import { PrettierText } from "../atoms/prettier-text.atom";
import TablaPagos from "./tabla-pagos";

interface IProps {
    expediente: IExpediente;
    type: number;
    dialogOpen: boolean;
    loading: boolean;
    handleDialogClose: () => void;
    handleDialogSubmit: () => void;
    props: {
        pagarCostos: boolean | null,
        puedeJustificar: boolean | null,
        importe: number,
        localizadorDocumento: string
    };
    lineaAeat: string;
}

const useStyles = makeStyles({
    title: {
        color: '#004f82',
        fontSize: 20
    },
    withMargin: {
        margin: 10
    },
    dialogWidth: {
        minWidth: 500,
    },
});

export const FirmaJustificantes: FC<IProps> = ({ expediente, type, props, dialogOpen, loading, handleDialogClose, handleDialogSubmit, lineaAeat }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            {expediente !== null && expediente.justificantes !== undefined && expediente.sujeto !== undefined ?
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"                    
                >
                    <DialogTitle id="alert-dialog-title">Firmar</DialogTitle>
                    <DialogContent className={classes.dialogWidth}>
                        {loading ?
                            <CircularProgress />
                            :
                            <>
                                {type === 1 &&
                                    <div>
                                        {/* <div style={{ marginBottom: '10px' }}>
                                            <Trans i18nKey="solicitud:declaracion-responsable-menos-6000"
                                                values={{
                                                    nombre: expediente.sujeto.nombre, nif: expediente.sujeto.nif, linea: lineaAeat
                                                }}
                                                components={{ b: <strong /> }}
                                            ></Trans>
                                        </div> */}
                                        {/* <div> */}
                                        {props.puedeJustificar ?
                                            <Trans i18nKey="solicitud:justificacion_anexo_menos_6000_integro"
                                                values={{
                                                    casilla6: expediente.sujeto.nombre, casilla5: expediente.sujeto.nif, casilla7007: lineaAeat,
                                                    casilla850: parseFloatIntelligent(expediente.importeAsignado || '0').toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                }}
                                                components={{ b: <strong />, br: <br /> }}
                                            />
                                            :
                                            <Trans i18nKey="solicitud:justificacion_anexo_menos_6000_parcial"
                                                values={{
                                                    casilla6: expediente.sujeto.nombre, casilla5: expediente.sujeto.nif, casilla7007: lineaAeat,
                                                    casilla850: parseFloatIntelligent(expediente.importeAsignado || '0').toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                    casilla4004: (parseFloatIntelligent(expediente.importeAsignado || '0') - props.importe).toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                    casilla4002: props.localizadorDocumento
                                                }}
                                                components={{ b: <strong />, br: <br /> }}
                                            />
                                            // <div>
                                            //     <p>-<Trans i18nKey="solicitud:devolucion-resto-ayuda"
                                            //         values={{
                                            //             importeDevuelto: (parseInt(expediente.importeAsignado || '0') - props.importe).toLocaleString('de-DE', { minimumFractionDigits: 2, }), numeroDocumento: props.localizadorDocumento
                                            //         }}
                                            //         components={{ b: <strong /> }}
                                            //     ></Trans></p>
                                            // </div>
                                        }
                                        {/* </div> */}
                                    </div>
                                }
                                {type === 2 &&
                                    <div>
                                        {/* <Trans i18nKey="solicitud:declaracion-responsable-menos-60000"
                                            values={{
                                                nombre: expediente?.sujeto.nombre, nif: expediente?.sujeto.nif, linea: lineaAeat
                                            }}
                                            components={{ b: <strong /> }}
                                        ></Trans>
                                        <div> */}
                                        {
                                            props.puedeJustificar
                                                ?
                                                <>
                                                    <Trans i18nKey="solicitud:justificacion_anexo_entre_6000_y_60000_integro"
                                                        values={{
                                                            casilla6: expediente.sujeto.nombre, casilla5: expediente.sujeto.nif, casilla7007: lineaAeat,
                                                            casilla850: parseFloatIntelligent(expediente.importeAsignado || '0').toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                        }}
                                                        components={{ b: <strong />, br: <br /> }}
                                                    />
                                                    <TablaPagos justificantes={expediente.justificantes} showAttachments={false} />
                                                </>
                                                :
                                                <>
                                                    <Trans i18nKey="solicitud:justificacion_anexo_entre_6000_y_60000_parcial"
                                                        values={{
                                                            casilla6: expediente.sujeto.nombre, casilla5: expediente.sujeto.nif, casilla7007: lineaAeat,
                                                            casilla850: parseFloatIntelligent(expediente.importeAsignado || '0').toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                            casilla4004: (parseFloatIntelligent(expediente.importeAsignado || '0') - props.importe).toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                            casilla4002: props.localizadorDocumento
                                                        }}
                                                        components={{ b: <strong />, br: <br /> }}
                                                    />
                                                    <TablaPagos justificantes={expediente.justificantes} showAttachments={false} />
                                                    <Trans i18nKey="solicitud:justificacion_anexo_entre_6000_y_60000_parcial_adicional"
                                                        values={{
                                                            casilla4004: (parseFloatIntelligent(expediente.importeAsignado || '0') - props.importe).toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                            casilla4002: props.localizadorDocumento
                                                        }}
                                                        components={{ b: <strong />, br: <br /> }}
                                                    />
                                                    {
                                                        props.pagarCostos &&
                                                        <Trans i18nKey="solicitud:justificacion-anexo-costes-fijos" components={{ b: <strong />, br: <br /> }} />
                                                    }
                                                </>
                                        }
                                        {/* <p><Trans i18nKey="solicitud:justificacion-menos-60000"
                                            values={{
                                                importeAsignado: parseFloat(expediente?.importeAsignado?.toString() || '0').toLocaleString('de-DE', {
                                                    minimumFractionDigits: 2,
                                                })
                                            }}
                                            components={{ b: <strong /> }}
                                        ></Trans></p>
                                        <TablaPagos justificantes={expediente.justificantes} showAttachments={false} />
                                        <p><Trans i18nKey="solicitud:total-justificacion-menos-60000"
                                            values={{
                                                importe: props.importe.toLocaleString('de-DE', {
                                                    minimumFractionDigits: 2,
                                                })
                                            }}
                                            components={{ b: <strong /> }}
                                        ></Trans></p> */}
                                        {/* </div> */}
                                        {/* {!props.puedeJustificar &&
                                            <p>{t("solicitud:segon")}.-</p> && <Trans i18nKey="solicitud:devolucion-resto-ayuda"
                                                values={{
                                                    importeDevuelto: (parseInt(expediente.importeAsignado || '0') - props.importe).toLocaleString('de-DE', {
                                                        minimumFractionDigits: 2,
                                                    }), numeroDocumento: props.localizadorDocumento
                                                }}
                                                components={{ b: <strong /> }}
                                            ></Trans>
                                        } */}
                                    </div>
                                }
                                {type === 3 &&
                                    <div>
                                        {
                                            props.puedeJustificar ?
                                                <Trans i18nKey="solicitud:justificacion_anexo_mas_60000_integro"
                                                    values={{
                                                        casilla6: expediente?.sujeto.nombre, casilla5: expediente?.sujeto.nif, casilla7007: lineaAeat,
                                                        casilla850: parseFloatIntelligent(expediente.importeAsignado || '0').toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                    }}
                                                    components={{ b: <strong />, br: <br /> }}
                                                />
                                                :
                                                <Trans i18nKey="solicitud:justificacion_anexo_mas_60000_parcial"
                                                    values={{
                                                        casilla6: expediente?.sujeto.nombre, casilla5: expediente?.sujeto.nif, casilla7007: lineaAeat,
                                                        casilla850: parseFloatIntelligent(expediente.importeAsignado || '0').toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                        casilla4004: (parseFloatIntelligent(expediente.importeAsignado || '0') - props.importe).toLocaleString('de-DE', { minimumFractionDigits: 2, }),
                                                        casilla4002: props.localizadorDocumento
                                                    }}
                                                    components={{ b: <strong />, br: <br /> }}
                                                />
                                        }
                                    </div>
                                }
                                {type === 4 &&
                                    <div>      
                                        <PrettierText text={t('solicitud:firma_cumplimiento')} />
                                    </div>
                                }
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} disabled={loading} color="primary">
                            {t('Cerrar')}
                        </Button>
                        <Button color="primary" variant="contained" className={classes.withMargin} disabled={loading} onClick={handleDialogSubmit}>
                            {t('Confirmar')}
                        </Button>
                    </DialogActions>
                </Dialog>
                : null}
        </>
    )
}
export default FirmaJustificantes;