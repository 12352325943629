/* eslint-disable */
export default `
<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:camunda="http://camunda.org/schema/1.0/bpmn" xmlns:modeler="http://camunda.org/schema/modeler/1.0" id="Definitions_0ap828b" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="4.8.1" modeler:executionPlatform="Camunda Platform" modeler:executionPlatformVersion="7.15.0">
  <bpmn:process id="Process_0v0xgam" isExecutable="true">
    <bpmn:startEvent id="inicio">
      <bpmn:outgoing>inicio_a_regimen</bpmn:outgoing>
    </bpmn:startEvent>
    <bpmn:sequenceFlow id="inicio_a_regimen" sourceRef="inicio" targetRef="Gateway_1ywersb" />
    <bpmn:task id="regimen_tributacion_pregunta" name="Regimen Tributación">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:regimenEstimacion_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "31", "label": "solicitud:casillero31", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "32", "label": "solicitud:casillero32", "valor": "1"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:regimenEstimacion_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_03ykz4w</bpmn:incoming>
      <bpmn:outgoing>regimen_a_respuesta</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="regimen_respuesta">
      <bpmn:incoming>regimen_a_respuesta</bpmn:incoming>
      <bpmn:outgoing>regimen_31_flow</bpmn:outgoing>
      <bpmn:outgoing>Flow_1cqxcs1</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="regimen_a_respuesta" sourceRef="regimen_tributacion_pregunta" targetRef="regimen_respuesta" />
    <bpmn:sequenceFlow id="regimen_31_flow" name="[31]" sourceRef="regimen_respuesta" targetRef="regime_31_gateway">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "31") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:exclusiveGateway id="regime_31_gateway" default="flow_31_default">
      <bpmn:incoming>regimen_31_flow</bpmn:incoming>
      <bpmn:outgoing>flow_31_default</bpmn:outgoing>
      <bpmn:outgoing>flow_31_baleares_no_valido</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:task id="direccion_no_baleares_dialogo" name="Dirección No Baleares">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="motivo">solicitud:cp_baleares_no_valido</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-fin</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>flow_31_baleares_no_valido</bpmn:incoming>
      <bpmn:outgoing>flow_a_tu_casa</bpmn:outgoing>
    </bpmn:task>
    <bpmn:task id="fecha_inicio_actvidad_pregunta_31" name="Fecha inicio actividad">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "45", "valor": "1", "label": "solicitud:casillero45" }</camunda:value>
              <camunda:value>{ "casilla": "46", "valor": "1", "label": "solicitud:casillero46" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:fechaActividades_pregunta</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>flow_31_default</bpmn:incoming>
      <bpmn:outgoing>Flow_1emwlnm</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="flow_31_default" sourceRef="regime_31_gateway" targetRef="fecha_inicio_actvidad_pregunta_31" />
    <bpmn:endEvent id="Event_12tj8vy">
      <bpmn:incoming>flow_a_tu_casa</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="flow_a_tu_casa" sourceRef="direccion_no_baleares_dialogo" targetRef="Event_12tj8vy" />
    <bpmn:exclusiveGateway id="Gateway_1afsbet">
      <bpmn:incoming>Flow_1emwlnm</bpmn:incoming>
      <bpmn:outgoing>Flow_1q0hbvi</bpmn:outgoing>
      <bpmn:outgoing>Flow_0tbghge</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1emwlnm" sourceRef="fecha_inicio_actvidad_pregunta_31" targetRef="Gateway_1afsbet" />
    <bpmn:task id="Activity_1yb6ow5" name="¿Has solicitado ayudas?">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">pregunta_solicitado_ayudas_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "900", "label": "Si", "valor": "1" }</camunda:value>
              <camunda:value>{ "casilla": "900", "label": "No", "valor": "0" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:pregunta_solicitado_ayudas</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1q0hbvi</bpmn:incoming>
      <bpmn:incoming>Flow_0p9hlbm</bpmn:incoming>
      <bpmn:outgoing>Flow_1si9p8a</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1q0hbvi" name="Anterior al 2019" sourceRef="Gateway_1afsbet" targetRef="Activity_1yb6ow5">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "45" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_1qfmt2q" name="Dialogo Casilla 47">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla47</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0tbghge</bpmn:incoming>
      <bpmn:outgoing>Flow_0p9hlbm</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0tbghge" name="Posterior al 2019" sourceRef="Gateway_1afsbet" targetRef="Activity_1qfmt2q">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "46" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0p9hlbm" sourceRef="Activity_1qfmt2q" targetRef="Activity_1yb6ow5" />
    <bpmn:exclusiveGateway id="Gateway_09gzd2v" default="Flow_1gw2l8p">
      <bpmn:incoming>Flow_1si9p8a</bpmn:incoming>
      <bpmn:outgoing>Flow_1nstsr6</bpmn:outgoing>
      <bpmn:outgoing>Flow_1gw2l8p</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1si9p8a" sourceRef="Activity_1yb6ow5" targetRef="Gateway_09gzd2v" />
    <bpmn:endEvent id="Event_12k96t0">
      <bpmn:incoming>Flow_1nstsr6</bpmn:incoming>
      <bpmn:incoming>Flow_0y84trs</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_1nstsr6" name="NO" sourceRef="Gateway_09gzd2v" targetRef="Event_12k96t0">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "900" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_025yneo" name="Dialogo: Ayudas publicas">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla900</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1gw2l8p</bpmn:incoming>
      <bpmn:outgoing>Flow_0y84trs</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1gw2l8p" name="SI" sourceRef="Gateway_09gzd2v" targetRef="Activity_025yneo" />
    <bpmn:sequenceFlow id="Flow_0y84trs" sourceRef="Activity_025yneo" targetRef="Event_12k96t0" />
    <bpmn:exclusiveGateway id="Gateway_1x60e2h" default="Flow_0g5b9c3">
      <bpmn:incoming>Flow_1eh7frs</bpmn:incoming>
      <bpmn:outgoing>Flow_10f93ad</bpmn:outgoing>
      <bpmn:outgoing>Flow_0g5b9c3</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1cqxcs1" name="[32]" sourceRef="regimen_respuesta" targetRef="Activity_1vv362c">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "32") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="flow_31_baleares_no_valido" sourceRef="regime_31_gateway" targetRef="direccion_no_baleares_dialogo">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">((context.detalles.find((x) =&gt; x.codigo === "12")?.valor.startsWith("07")) ?? false) === false</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_1eo6vmf" name="Dirección No Baleares">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="motivo">solicitud:cp_baleares_no_valido</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-fin</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_10f93ad</bpmn:incoming>
      <bpmn:outgoing>Flow_0w4ysny</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_10f93ad" sourceRef="Gateway_1x60e2h" targetRef="Activity_1eo6vmf">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">((context.detalles.find((x) =&gt; x.codigo === "12")?.valor.startsWith("07")) ?? false) === false</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:endEvent id="Event_05nms7y">
      <bpmn:incoming>Flow_0w4ysny</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_0w4ysny" sourceRef="Activity_1eo6vmf" targetRef="Event_05nms7y" />
    <bpmn:task id="Activity_1u6j6cr" name="Regimen Tributación Ventas">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "37", "label": "solicitud:casillero37", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "38", "label": "solicitud:casillero38", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "39", "label": "solicitud:casillero39", "valor": "1"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:regimenTributacion2020_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0g5b9c3</bpmn:incoming>
      <bpmn:incoming>Flow_0ey0bu5</bpmn:incoming>
      <bpmn:outgoing>Flow_1ig3c18</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0g5b9c3" sourceRef="Gateway_1x60e2h" targetRef="Activity_1u6j6cr" />
    <bpmn:task id="Activity_02aenkk" name="Fecha inicio actividad">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "45", "valor": "1", "label": "solicitud:casillero45" }</camunda:value>
              <camunda:value>{ "casilla": "46", "valor": "1", "label": "solicitud:casillero46" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:fechaActividades_pregunta</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1ig3c18</bpmn:incoming>
      <bpmn:outgoing>Flow_0kyfwy2</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1ig3c18" sourceRef="Activity_1u6j6cr" targetRef="Activity_02aenkk" />
    <bpmn:exclusiveGateway id="Gateway_0p96vo4">
      <bpmn:incoming>Flow_0kyfwy2</bpmn:incoming>
      <bpmn:outgoing>Flow_1gz9j2o</bpmn:outgoing>
      <bpmn:outgoing>Flow_04fp4sp</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0kyfwy2" sourceRef="Activity_02aenkk" targetRef="Gateway_0p96vo4" />
    <bpmn:sequenceFlow id="Flow_1gz9j2o" name="Posterior al 2019" sourceRef="Gateway_0p96vo4" targetRef="Activity_0h4myio">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "46" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_0h4myio" name="Dialogo Casilla 47">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla47</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1gz9j2o</bpmn:incoming>
      <bpmn:outgoing>Flow_0lmnsa4</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_1qlv695">
      <bpmn:incoming>Flow_1xecyw5</bpmn:incoming>
      <bpmn:outgoing>Flow_0t1tw1w</bpmn:outgoing>
      <bpmn:outgoing>Flow_1a9v0ub</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_04fp4sp" name="Anterior al 2019" sourceRef="Gateway_0p96vo4" targetRef="Activity_03pbuv5">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "45" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0lmnsa4" sourceRef="Activity_0h4myio" targetRef="Gateway_16w8ljc" />
    <bpmn:task id="Activity_03pbuv5" name="Declaro Resultado Negativo">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "44", "valor": "1", "label": "Si" }</camunda:value>
              <camunda:value>{ "casilla": "44", "valor": "0", "label": "No" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:declaBINegativa</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_04fp4sp</bpmn:incoming>
      <bpmn:outgoing>Flow_1xecyw5</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1xecyw5" name="[44]" sourceRef="Activity_03pbuv5" targetRef="Gateway_1qlv695" />
    <bpmn:task id="Activity_1gzijoa" name="Dialogo Thomas Cook">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogThomasCook</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0t1tw1w</bpmn:incoming>
      <bpmn:outgoing>Flow_0siam31</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0t1tw1w" name="Si" sourceRef="Gateway_1qlv695" targetRef="Activity_1gzijoa">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "44" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1a9v0ub" name="No" sourceRef="Gateway_1qlv695" targetRef="Gateway_16w8ljc">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "44" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:exclusiveGateway id="Gateway_07z9ny6" default="Flow_0sdfubm">
      <bpmn:incoming>Flow_0siam31</bpmn:incoming>
      <bpmn:outgoing>Flow_0wpvr2t</bpmn:outgoing>
      <bpmn:outgoing>Flow_0sdfubm</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0siam31" sourceRef="Activity_1gzijoa" targetRef="Gateway_07z9ny6" />
    <bpmn:endEvent id="Event_033zsb9">
      <bpmn:incoming>Flow_1u6wjt8</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_0wpvr2t" name="[650] &#60; 0" sourceRef="Gateway_07z9ny6" targetRef="Activity_0xntmrp">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">parseFloat(context.detalles.find((x) =&gt; x.codigo === '650').valor) &lt; 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0sdfubm" sourceRef="Gateway_07z9ny6" targetRef="Gateway_16w8ljc" />
    <bpmn:task id="Activity_0xntmrp" name="Importe 650 Negativo">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="motivo">solicitud:_650_negativo</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-fin</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0wpvr2t</bpmn:incoming>
      <bpmn:outgoing>Flow_1u6wjt8</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1u6wjt8" sourceRef="Activity_0xntmrp" targetRef="Event_033zsb9" />
    <bpmn:task id="Activity_0lc0ysm" name="¿Has solicitado ayudas?">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:pregunta_solicitado_ayudas_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "900", "label": "Si", "valor": "1" }</camunda:value>
              <camunda:value>{ "casilla": "900", "label": "No", "valor": "0" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:pregunta_solicitado_ayudas</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1ga34pr</bpmn:incoming>
      <bpmn:incoming>Flow_1jin8sq</bpmn:incoming>
      <bpmn:outgoing>Flow_1o3kz1m</bpmn:outgoing>
    </bpmn:task>
    <bpmn:task id="Activity_0asagmg" name="Regimen Tributación Ventas">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "38", "label": "solicitud:casillero38", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "39", "label": "solicitud:casillero39", "valor": "1"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:regimenTributacion2020_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_07nnx0l</bpmn:incoming>
      <bpmn:outgoing>Flow_0v53c3y</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_1dkkzl3" default="Flow_1wbkjmq">
      <bpmn:incoming>Flow_0v53c3y</bpmn:incoming>
      <bpmn:outgoing>Flow_0vysdji</bpmn:outgoing>
      <bpmn:outgoing>Flow_1wbkjmq</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0v53c3y" sourceRef="Activity_0asagmg" targetRef="Gateway_1dkkzl3" />
    <bpmn:sequenceFlow id="Flow_0vysdji" name="[39]" sourceRef="Gateway_1dkkzl3" targetRef="Activity_1jgfw2m">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "39") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1wbkjmq" name="[38]" sourceRef="Gateway_1dkkzl3" targetRef="Activity_0bx1l8a" />
    <bpmn:task id="Activity_1jgfw2m" name="Metodo determinacion pagos">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:metodoDeterminacionPagos_pregunta_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "701", "label": "solicitud:casillero28", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "702", "label": "solicitud:casillero29", "valor": "1"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:metodoDeterminacionPagos_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0vysdji</bpmn:incoming>
      <bpmn:outgoing>Flow_03ft8pj</bpmn:outgoing>
    </bpmn:task>
    <bpmn:task id="Activity_0bx1l8a" name="Formo parte grupo 19/20/21">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:parteGrupo_pregunta_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "2000", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "2000", "label": "No", "valor": "0"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:parteGrupo_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1wbkjmq</bpmn:incoming>
      <bpmn:incoming>Flow_03ft8pj</bpmn:incoming>
      <bpmn:outgoing>Flow_11wd0jn</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_11wd0jn" sourceRef="Activity_0bx1l8a" targetRef="Gateway_17rfvti" />
    <bpmn:task id="Activity_11o3rsh" name="Dialogo Casilla 39">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla39</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1nd7ezu</bpmn:incoming>
      <bpmn:outgoing>Flow_0vsb107</bpmn:outgoing>
    </bpmn:task>
    <bpmn:task id="Activity_0zxyifw" name="Dialogo Casilla 47">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla47</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0g9hden</bpmn:incoming>
      <bpmn:outgoing>Flow_1mt5qb2</bpmn:outgoing>
    </bpmn:task>
    <bpmn:task id="Activity_1u7man7" name="Dialogo Thomas Cook">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogThomasCook</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1cnd2pi</bpmn:incoming>
      <bpmn:outgoing>Flow_0jgpucw</bpmn:outgoing>
    </bpmn:task>
    <bpmn:task id="Activity_1kabd9l" name="Dialogo Casilla 326">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla326</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1mn8ik1</bpmn:incoming>
      <bpmn:outgoing>Flow_0c8xa3q</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_1ywersb" default="Flow_0qiwx8u">
      <bpmn:incoming>inicio_a_regimen</bpmn:incoming>
      <bpmn:outgoing>Flow_1o6omn0</bpmn:outgoing>
      <bpmn:outgoing>Flow_0xq57jr</bpmn:outgoing>
      <bpmn:outgoing>Flow_0qiwx8u</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1o6omn0" name="PERSONA FÍSICA" sourceRef="Gateway_1ywersb" targetRef="Activity_0jdfj5i">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">/^[0-9EKHLXYZ]/gmi.test(context.sujeto.nif)</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0xq57jr" name="PERSONA JURIDICA" sourceRef="Gateway_1ywersb" targetRef="Activity_1ltspy0">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">/^[ABCDFGQRSUVW]/gmi.test(context.sujeto.nif)</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_1vv362c" name="10 kilos">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:10Kilos_pregunta_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "43", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "43", "label": "No", "valor": "0"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:10Kilos_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1cqxcs1</bpmn:incoming>
      <bpmn:outgoing>Flow_1147q8q</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1147q8q" sourceRef="Activity_1vv362c" targetRef="Gateway_0javpic" />
    <bpmn:exclusiveGateway id="Gateway_0javpic" default="Flow_0ey0bu5">
      <bpmn:incoming>Flow_1147q8q</bpmn:incoming>
      <bpmn:outgoing>Flow_1eh7frs</bpmn:outgoing>
      <bpmn:outgoing>Flow_0ey0bu5</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1eh7frs" name="No" sourceRef="Gateway_0javpic" targetRef="Gateway_1x60e2h">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "43" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0ey0bu5" name="Si" sourceRef="Gateway_0javpic" targetRef="Activity_1u6j6cr" />
    <bpmn:task id="Activity_1p2ddq8" name="Ayudas directas COVID">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:casillero700_pregunta_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "700", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "700", "label": "No", "valor": "0"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:casillero700_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0jo4w6l</bpmn:incoming>
      <bpmn:outgoing>Flow_1ga34pr</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1ga34pr" sourceRef="Activity_1p2ddq8" targetRef="Activity_0lc0ysm" />
    <bpmn:exclusiveGateway id="Gateway_16w8ljc" default="Flow_1jin8sq">
      <bpmn:incoming>Flow_0lmnsa4</bpmn:incoming>
      <bpmn:incoming>Flow_1a9v0ub</bpmn:incoming>
      <bpmn:incoming>Flow_0sdfubm</bpmn:incoming>
      <bpmn:outgoing>Flow_0jo4w6l</bpmn:outgoing>
      <bpmn:outgoing>Flow_1jin8sq</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0jo4w6l" name="Si [43]" sourceRef="Gateway_16w8ljc" targetRef="Activity_1p2ddq8">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "43" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1jin8sq" name="No [43]" sourceRef="Gateway_16w8ljc" targetRef="Activity_0lc0ysm" />
    <bpmn:exclusiveGateway id="Gateway_17rfvti" default="Flow_0u1hehu">
      <bpmn:incoming>Flow_11wd0jn</bpmn:incoming>
      <bpmn:outgoing>Flow_0u1hehu</bpmn:outgoing>
      <bpmn:outgoing>Flow_0ckuwix</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_03ft8pj" sourceRef="Activity_1jgfw2m" targetRef="Activity_0bx1l8a" />
    <bpmn:task id="Activity_0w3krhp" name="Forma parte grupo 2021">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "42", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "42", "label": "No", "valor": "0"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:parteGrupo2021_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0u1hehu</bpmn:incoming>
      <bpmn:outgoing>Flow_1d78w0t</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0u1hehu" name="Si" sourceRef="Gateway_17rfvti" targetRef="Activity_0w3krhp" />
    <bpmn:sequenceFlow id="Flow_0ckuwix" name="No" sourceRef="Gateway_17rfvti" targetRef="Activity_1gugie5">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "2000" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:exclusiveGateway id="Gateway_0dks2n0">
      <bpmn:incoming>Flow_16ire3m</bpmn:incoming>
      <bpmn:outgoing>Flow_1pnmu90</bpmn:outgoing>
      <bpmn:outgoing>Flow_08lgh5m</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:task id="Activity_1gugie5" name="10 kilos">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:10Kilos_pregunta_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "43", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "43", "label": "No", "valor": "0"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:10Kilos_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0ckuwix</bpmn:incoming>
      <bpmn:incoming>Flow_0n7ehbt</bpmn:incoming>
      <bpmn:incoming>Flow_1gs0ao6</bpmn:incoming>
      <bpmn:outgoing>Flow_16ire3m</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_16ire3m" sourceRef="Activity_1gugie5" targetRef="Gateway_0dks2n0" />
    <bpmn:exclusiveGateway id="Gateway_1i3e7tz" default="Flow_17q8ite">
      <bpmn:incoming>Flow_1d78w0t</bpmn:incoming>
      <bpmn:outgoing>Flow_1mzcuix</bpmn:outgoing>
      <bpmn:outgoing>Flow_17q8ite</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1d78w0t" sourceRef="Activity_0w3krhp" targetRef="Gateway_1i3e7tz" />
    <bpmn:task id="Activity_0a5h2px" name="Forma parte grupo 2020">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "1006", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "1006", "label": "No", "valor": "0"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:parteGrupo2020_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1mzcuix</bpmn:incoming>
      <bpmn:outgoing>Flow_01a0kf6</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1mzcuix" name="No" sourceRef="Gateway_1i3e7tz" targetRef="Activity_0a5h2px">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "42" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_0hf4j0h" name="Forma parte grupo 2019 (solo si)">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "338", "label": "Si", "valor": "1"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:parteGrupo2019_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1jnryfe</bpmn:incoming>
      <bpmn:outgoing>Flow_0n7ehbt</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_01a0kf6" sourceRef="Activity_0a5h2px" targetRef="Gateway_0p9fohs" />
    <bpmn:sequenceFlow id="Flow_0n7ehbt" sourceRef="Activity_0hf4j0h" targetRef="Activity_1gugie5" />
    <bpmn:task id="Activity_0r611u5" name="Dirección No Baleares">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="motivo">solicitud:cp_baleares_no_valido</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-fin</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1018ot3</bpmn:incoming>
      <bpmn:outgoing>Flow_1i3tyva</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_1tgh2pb" default="Flow_1rdtklu">
      <bpmn:incoming>Flow_1pnmu90</bpmn:incoming>
      <bpmn:outgoing>Flow_1018ot3</bpmn:outgoing>
      <bpmn:outgoing>Flow_1rdtklu</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1pnmu90" name="No" sourceRef="Gateway_0dks2n0" targetRef="Gateway_1tgh2pb">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "43" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:endEvent id="Event_028njv5">
      <bpmn:incoming>Flow_1i3tyva</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_1i3tyva" sourceRef="Activity_0r611u5" targetRef="Event_028njv5" />
    <bpmn:sequenceFlow id="Flow_1018ot3" name="No" sourceRef="Gateway_1tgh2pb" targetRef="Activity_0r611u5">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">((context.detalles.find((x) =&gt; x.codigo === "12")?.valor.startsWith("07")) ?? false) === false</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:exclusiveGateway id="Gateway_0p3cwsw">
      <bpmn:incoming>Flow_08lgh5m</bpmn:incoming>
      <bpmn:incoming>Flow_1rdtklu</bpmn:incoming>
      <bpmn:incoming>Flow_0ms4585</bpmn:incoming>
      <bpmn:incoming>Flow_0m29n24</bpmn:incoming>
      <bpmn:outgoing>Flow_01vhyuf</bpmn:outgoing>
      <bpmn:outgoing>Flow_1nd7ezu</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_08lgh5m" name="Si" sourceRef="Gateway_0dks2n0" targetRef="Gateway_0p3cwsw">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "43" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1rdtklu" sourceRef="Gateway_1tgh2pb" targetRef="Gateway_0p3cwsw" />
    <bpmn:sequenceFlow id="Flow_17q8ite" name="Si" sourceRef="Gateway_1i3e7tz" targetRef="Activity_0sd3uz3" />
    <bpmn:task id="Activity_0sd3uz3" name="Articulo 58.1 27/2014">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:articulo_58_pregunta_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "326", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "327", "label": "No", "valor": "1"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:articulo_58_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_17q8ite</bpmn:incoming>
      <bpmn:outgoing>Flow_1wogt2z</bpmn:outgoing>
    </bpmn:task>
    <bpmn:task id="Activity_1d3hg8r" name="Ayuda debe solicitarla emprese dominante">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="motivo">solicitud:empresa_dominante_motivo_negativo</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-fin</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1d4ltao</bpmn:incoming>
      <bpmn:outgoing>Flow_0iqhrg8</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_1ersvjv" default="Flow_1mn8ik1">
      <bpmn:incoming>Flow_1wogt2z</bpmn:incoming>
      <bpmn:outgoing>Flow_1d4ltao</bpmn:outgoing>
      <bpmn:outgoing>Flow_1mn8ik1</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1wogt2z" sourceRef="Activity_0sd3uz3" targetRef="Gateway_1ersvjv" />
    <bpmn:sequenceFlow id="Flow_1d4ltao" name="[327]" sourceRef="Gateway_1ersvjv" targetRef="Activity_1d3hg8r">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "327" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:endEvent id="Event_1ka7io4">
      <bpmn:incoming>Flow_0iqhrg8</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_0iqhrg8" sourceRef="Activity_1d3hg8r" targetRef="Event_1ka7io4" />
    <bpmn:sequenceFlow id="Flow_1mn8ik1" name="[326]" sourceRef="Gateway_1ersvjv" targetRef="Activity_1kabd9l" />
    <bpmn:task id="Activity_1g6j0k5" name="Fecha inicio actividad">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "45", "valor": "1", "label": "solicitud:casillero45" }</camunda:value>
              <camunda:value>{ "casilla": "46", "valor": "1", "label": "solicitud:casillero46" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:fechaActividades_grupos_pregunta</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0c8xa3q</bpmn:incoming>
      <bpmn:outgoing>Flow_1ljigew</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0c8xa3q" sourceRef="Activity_1kabd9l" targetRef="Activity_1g6j0k5" />
    <bpmn:exclusiveGateway id="Gateway_0jgjdfl">
      <bpmn:incoming>Flow_1ljigew</bpmn:incoming>
      <bpmn:outgoing>Flow_1563fji</bpmn:outgoing>
      <bpmn:outgoing>Flow_0g9hden</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1ljigew" sourceRef="Activity_1g6j0k5" targetRef="Gateway_0jgjdfl" />
    <bpmn:sequenceFlow id="Flow_1563fji" name="Anterior al 2019" sourceRef="Gateway_0jgjdfl" targetRef="Activity_0my4udz">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "45" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0g9hden" name="Posterior 2019" sourceRef="Gateway_0jgjdfl" targetRef="Activity_0zxyifw">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "46" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1mt5qb2" sourceRef="Activity_0zxyifw" targetRef="Activity_0my4udz" />
    <bpmn:task id="Activity_0my4udz" name="Era Sociedad Dominante 2020">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "3050", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "3050", "label": "No", "valor": "0"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:eraSociedadDominante_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1mt5qb2</bpmn:incoming>
      <bpmn:incoming>Flow_1563fji</bpmn:incoming>
      <bpmn:outgoing>Flow_06y644f</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_1fdlo2s">
      <bpmn:incoming>Flow_06y644f</bpmn:incoming>
      <bpmn:outgoing>Flow_0ms4585</bpmn:outgoing>
      <bpmn:outgoing>Flow_0v5kfy4</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_06y644f" sourceRef="Activity_0my4udz" targetRef="Gateway_1fdlo2s" />
    <bpmn:task id="Activity_1uhwzvp" name="Dialogo Casilla 3051">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla3051</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0v5kfy4</bpmn:incoming>
      <bpmn:outgoing>Flow_0m29n24</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0ms4585" name="Si" sourceRef="Gateway_1fdlo2s" targetRef="Gateway_0p3cwsw">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "3050" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0v5kfy4" name="No" sourceRef="Gateway_1fdlo2s" targetRef="Activity_1uhwzvp">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "3050" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0m29n24" sourceRef="Activity_1uhwzvp" targetRef="Gateway_0p3cwsw" />
    <bpmn:sequenceFlow id="Flow_01vhyuf" name="No" sourceRef="Gateway_0p3cwsw" targetRef="Gateway_13qrl78" />
    <bpmn:exclusiveGateway id="Gateway_13qrl78" default="Flow_12odvif">
      <bpmn:incoming>Flow_0vsb107</bpmn:incoming>
      <bpmn:incoming>Flow_01vhyuf</bpmn:incoming>
      <bpmn:outgoing>Flow_0iylejf</bpmn:outgoing>
      <bpmn:outgoing>Flow_12odvif</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0vsb107" sourceRef="Activity_11o3rsh" targetRef="Gateway_13qrl78" />
    <bpmn:sequenceFlow id="Flow_0iylejf" name="Si [42]" sourceRef="Gateway_13qrl78" targetRef="Activity_0257bmo">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "42" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:exclusiveGateway id="Gateway_1mztnzf" default="Flow_06xsfgi">
      <bpmn:incoming>Flow_022fczb</bpmn:incoming>
      <bpmn:outgoing>Flow_1cnd2pi</bpmn:outgoing>
      <bpmn:outgoing>Flow_06xsfgi</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1cnd2pi" name="Si [44]" sourceRef="Gateway_1mztnzf" targetRef="Activity_1u7man7">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "44" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_0db7thg" name="Importe 650 Negativo">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="motivo">solicitud:_650_negativo</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-fin</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_168u8g6</bpmn:incoming>
      <bpmn:outgoing>Flow_11cl8y0</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_18hqwij">
      <bpmn:incoming>Flow_0jgpucw</bpmn:incoming>
      <bpmn:outgoing>Flow_168u8g6</bpmn:outgoing>
      <bpmn:outgoing>Flow_0upmu2e</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0jgpucw" sourceRef="Activity_1u7man7" targetRef="Gateway_18hqwij" />
    <bpmn:sequenceFlow id="Flow_168u8g6" name="[650] &#60; 0" sourceRef="Gateway_18hqwij" targetRef="Activity_0db7thg">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">parseFloat(context.detalles.find((x) =&gt; x.codigo === '650').valor) &lt; 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:endEvent id="Event_1vfwn4k">
      <bpmn:incoming>Flow_11cl8y0</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:task id="Activity_0sdroq4" name="Fecha inicio actividad">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "45", "valor": "1", "label": "solicitud:casillero45" }</camunda:value>
              <camunda:value>{ "casilla": "46", "valor": "1", "label": "solicitud:casillero46" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:fechaActividades_pregunta</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_12odvif</bpmn:incoming>
      <bpmn:outgoing>Flow_044uc4q</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_12odvif" name="No [42]" sourceRef="Gateway_13qrl78" targetRef="Activity_0sdroq4" />
    <bpmn:task id="Activity_1d25p5j" name="Dialogo Casilla 47">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla47</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_18ljwm3</bpmn:incoming>
      <bpmn:outgoing>Flow_0hxk3bt</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_1kjjs5s">
      <bpmn:incoming>Flow_044uc4q</bpmn:incoming>
      <bpmn:outgoing>Flow_18ljwm3</bpmn:outgoing>
      <bpmn:outgoing>Flow_1qyifj9</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_044uc4q" sourceRef="Activity_0sdroq4" targetRef="Gateway_1kjjs5s" />
    <bpmn:sequenceFlow id="Flow_18ljwm3" name="Posterior 2019" sourceRef="Gateway_1kjjs5s" targetRef="Activity_1d25p5j">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "46" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1qyifj9" name="Anterior 2019" sourceRef="Gateway_1kjjs5s" targetRef="Activity_0257bmo">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "45" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_11cl8y0" sourceRef="Activity_0db7thg" targetRef="Event_1vfwn4k" />
    <bpmn:exclusiveGateway id="Gateway_0yf4i6t" default="Flow_0t95l2h">
      <bpmn:incoming>Flow_0upmu2e</bpmn:incoming>
      <bpmn:incoming>Flow_06xsfgi</bpmn:incoming>
      <bpmn:incoming>Flow_0hxk3bt</bpmn:incoming>
      <bpmn:outgoing>Flow_0t95l2h</bpmn:outgoing>
      <bpmn:outgoing>Flow_1vnjd24</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0upmu2e" sourceRef="Gateway_18hqwij" targetRef="Gateway_0yf4i6t" />
    <bpmn:sequenceFlow id="Flow_06xsfgi" name="No [44]" sourceRef="Gateway_1mztnzf" targetRef="Gateway_0yf4i6t" />
    <bpmn:task id="Activity_06vswya" name="Dialogo Casilla 49">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla49</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0u1s189</bpmn:incoming>
      <bpmn:outgoing>Flow_1y6pktu</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0t95l2h" name="No [42]" sourceRef="Gateway_0yf4i6t" targetRef="Activity_0bsogrm" />
    <bpmn:exclusiveGateway id="Gateway_0zyq36o" default="Flow_0bfh882">
      <bpmn:incoming>Flow_1y6pktu</bpmn:incoming>
      <bpmn:outgoing>Flow_19s6tp9</bpmn:outgoing>
      <bpmn:outgoing>Flow_0bfh882</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1y6pktu" sourceRef="Activity_06vswya" targetRef="Gateway_0zyq36o" />
    <bpmn:sequenceFlow id="Flow_19s6tp9" name="No [43]" sourceRef="Gateway_0zyq36o" targetRef="Activity_01qal4l">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "43" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:exclusiveGateway id="Gateway_0hzz3zo">
      <bpmn:incoming>Flow_0bfh882</bpmn:incoming>
      <bpmn:incoming>Flow_1vnjd24</bpmn:incoming>
      <bpmn:incoming>Flow_140sa0o</bpmn:incoming>
      <bpmn:outgoing>Flow_0hr6mzh</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1vnjd24" name="Si [42]" sourceRef="Gateway_0yf4i6t" targetRef="Gateway_0hzz3zo">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "42" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_0dfavzw" name="Pregunta [700]">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:casillero700_pregunta_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "700", "valor": "1", "label": "Si" }</camunda:value>
              <camunda:value>{ "casilla": "700", "valor": "0", "label": "No" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:casillero700_pregunta</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0hr6mzh</bpmn:incoming>
      <bpmn:outgoing>Flow_0w4fsos</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0bfh882" name="Si [43]" sourceRef="Gateway_0zyq36o" targetRef="Gateway_0hzz3zo" />
    <bpmn:sequenceFlow id="Flow_0w4fsos" sourceRef="Activity_0dfavzw" targetRef="Activity_01qal4l" />
    <bpmn:sequenceFlow id="Flow_0hr6mzh" sourceRef="Gateway_0hzz3zo" targetRef="Activity_0dfavzw" />
    <bpmn:sequenceFlow id="Flow_1nd7ezu" name="Si" sourceRef="Gateway_0p3cwsw" targetRef="Activity_11o3rsh">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">((context.detalles.findIndex(x =&gt; x.codigo === "42" &amp;&amp; x.valor === "0") &gt;= 0 &amp;&amp;context.detalles.findIndex(x =&gt; x.codigo === "1006" &amp;&amp; x.valor === "1") &gt;= 0)||context.detalles.findIndex(x =&gt; x.codigo === "338" &amp;&amp; x.valor === "1") &gt;= 0)||context.detalles.findIndex((x) =&gt; x.codigo === "702" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_0257bmo" name="Declaro Resultado Negativo">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "44", "valor": "1", "label": "Si" }</camunda:value>
              <camunda:value>{ "casilla": "44", "valor": "0", "label": "No" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:declaBINegativa</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0iylejf</bpmn:incoming>
      <bpmn:incoming>Flow_1qyifj9</bpmn:incoming>
      <bpmn:outgoing>Flow_022fczb</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_022fczb" sourceRef="Activity_0257bmo" targetRef="Gateway_1mztnzf" />
    <bpmn:sequenceFlow id="Flow_0hxk3bt" sourceRef="Activity_1d25p5j" targetRef="Gateway_0yf4i6t" />
    <bpmn:task id="Activity_0bsogrm" name="Casilla [49]">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:pregunta_casilla_49_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "49", "label": "Si", "valor": "1" }</camunda:value>
              <camunda:value>{ "casilla": "49", "label": "No", "valor": "0" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:pregunta_casilla_49</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0t95l2h</bpmn:incoming>
      <bpmn:outgoing>Flow_00vvqis</bpmn:outgoing>
    </bpmn:task>
    <bpmn:exclusiveGateway id="Gateway_06v715q">
      <bpmn:incoming>Flow_00vvqis</bpmn:incoming>
      <bpmn:outgoing>Flow_0u1s189</bpmn:outgoing>
      <bpmn:outgoing>Flow_0fvoar1</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_00vvqis" sourceRef="Activity_0bsogrm" targetRef="Gateway_06v715q" />
    <bpmn:sequenceFlow id="Flow_0u1s189" name="Si [49]" sourceRef="Gateway_06v715q" targetRef="Activity_06vswya" />
    <bpmn:exclusiveGateway id="Gateway_101ssym" default="Flow_140sa0o">
      <bpmn:incoming>Flow_0fvoar1</bpmn:incoming>
      <bpmn:outgoing>Flow_1s119oe</bpmn:outgoing>
      <bpmn:outgoing>Flow_140sa0o</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0fvoar1" name="No [49]" sourceRef="Gateway_06v715q" targetRef="Gateway_101ssym">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "49" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1s119oe" name="No [43]" sourceRef="Gateway_101ssym" targetRef="Activity_01qal4l">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "43" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_140sa0o" name="Si [43]" sourceRef="Gateway_101ssym" targetRef="Gateway_0hzz3zo" />
    <bpmn:task id="Activity_1v822zh" name="MARCAR [33]">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="valor">1</camunda:inputParameter>
          <camunda:inputParameter name="casilla">33</camunda:inputParameter>
          <camunda:inputParameter name="type">set-casilla</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0p9i4wt</bpmn:incoming>
      <bpmn:outgoing>Flow_07nnx0l</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_07nnx0l" sourceRef="Activity_1v822zh" targetRef="Activity_0asagmg" />
    <bpmn:exclusiveGateway id="Gateway_0p9fohs" default="Flow_0yi4hug">
      <bpmn:incoming>Flow_01a0kf6</bpmn:incoming>
      <bpmn:outgoing>Flow_1jnryfe</bpmn:outgoing>
      <bpmn:outgoing>Flow_0yi4hug</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:task id="Activity_0nojxmr" name="Forma parte grupo 2019 (si o no)">
      <bpmn:extensionElements>
        <camunda:properties>
          <camunda:property />
        </camunda:properties>
        <camunda:inputOutput>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "338", "label": "Si", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "338", "label": "No", "valor": "0"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:parteGrupo2019_pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0yi4hug</bpmn:incoming>
      <bpmn:outgoing>Flow_1gs0ao6</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1jnryfe" sourceRef="Gateway_0p9fohs" targetRef="Activity_0hf4j0h">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "1006" &amp;&amp; x.valor === "0") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0yi4hug" sourceRef="Gateway_0p9fohs" targetRef="Activity_0nojxmr" />
    <bpmn:sequenceFlow id="Flow_1gs0ao6" sourceRef="Activity_0nojxmr" targetRef="Activity_1gugie5" />
    <bpmn:task id="Activity_0tja1ii" name="¿En que impuesto declaro mis beneficios empresariales?">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{"casilla": "5031", "label": "solicitud:casillero5031", "valor": "1"}</camunda:value>
              <camunda:value>{"casilla": "5032", "label": "solicitud:casillero5032", "valor": "1"}</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:impuestoBeneficios_pregunta</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0qiwx8u</bpmn:incoming>
      <bpmn:outgoing>Flow_099yt6d</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0qiwx8u" name="Otros casos" sourceRef="Gateway_1ywersb" targetRef="Activity_0tja1ii" />
    <bpmn:exclusiveGateway id="Gateway_1piptf7" default="Flow_0ow32is">
      <bpmn:incoming>Flow_099yt6d</bpmn:incoming>
      <bpmn:outgoing>Flow_0ow32is</bpmn:outgoing>
      <bpmn:outgoing>Flow_151tr8a</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_099yt6d" sourceRef="Activity_0tja1ii" targetRef="Gateway_1piptf7" />
    <bpmn:sequenceFlow id="Flow_0ow32is" name="IRPF" sourceRef="Gateway_1piptf7" targetRef="Activity_0jdfj5i" />
    <bpmn:sequenceFlow id="Flow_151tr8a" name="Sociedades&#10;o&#10;IRNR" sourceRef="Gateway_1piptf7" targetRef="Activity_1ltspy0">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "5032") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_1ltspy0" name="MARCAR [5032]">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="valor">1</camunda:inputParameter>
          <camunda:inputParameter name="casilla">5032</camunda:inputParameter>
          <camunda:inputParameter name="type">set-casilla</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0xq57jr</bpmn:incoming>
      <bpmn:incoming>Flow_151tr8a</bpmn:incoming>
      <bpmn:outgoing>Flow_0p9i4wt</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0p9i4wt" sourceRef="Activity_1ltspy0" targetRef="Activity_1v822zh" />
    <bpmn:task id="Activity_0jdfj5i" name="MARCAR [5031]">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="valor">1</camunda:inputParameter>
          <camunda:inputParameter name="casilla">5031</camunda:inputParameter>
          <camunda:inputParameter name="type">set-casilla</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1o6omn0</bpmn:incoming>
      <bpmn:incoming>Flow_0ow32is</bpmn:incoming>
      <bpmn:outgoing>Flow_03ykz4w</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_03ykz4w" sourceRef="Activity_0jdfj5i" targetRef="regimen_tributacion_pregunta" />
    <bpmn:endEvent id="Event_0w0ecfe">
      <bpmn:incoming>Flow_0mpk59d</bpmn:incoming>
      <bpmn:incoming>Flow_0s0nwy7</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:exclusiveGateway id="Gateway_1retytl" default="Flow_0mpk59d">
      <bpmn:incoming>Flow_0a5jll9</bpmn:incoming>
      <bpmn:outgoing>Flow_0mpk59d</bpmn:outgoing>
      <bpmn:outgoing>Flow_18qr07k</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_0mpk59d" sourceRef="Gateway_1retytl" targetRef="Event_0w0ecfe" />
    <bpmn:sequenceFlow id="Flow_18qr07k" name="Si" sourceRef="Gateway_1retytl" targetRef="Activity_19vghrj">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "900" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:task id="Activity_19vghrj" name="Dialogo: Ayudas publicas">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla900</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_18qr07k</bpmn:incoming>
      <bpmn:outgoing>Flow_0s0nwy7</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0s0nwy7" sourceRef="Activity_19vghrj" targetRef="Event_0w0ecfe" />
    <bpmn:task id="Activity_01qal4l" name="¿Has solicitado ayudas?">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="textoAclaratorio">solicitud:pregunta_solicitado_ayudas_mas_info</camunda:inputParameter>
          <camunda:inputParameter name="opciones">
            <camunda:list>
              <camunda:value>{ "casilla": "900", "label": "Si", "valor": "1" }</camunda:value>
              <camunda:value>{ "casilla": "900", "label": "No", "valor": "0" }</camunda:value>
            </camunda:list>
          </camunda:inputParameter>
          <camunda:inputParameter name="pregunta">solicitud:pregunta_solicitado_ayudas</camunda:inputParameter>
          <camunda:inputParameter name="type">pregunta</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1s119oe</bpmn:incoming>
      <bpmn:incoming>Flow_0w4fsos</bpmn:incoming>
      <bpmn:incoming>Flow_19s6tp9</bpmn:incoming>
      <bpmn:outgoing>Flow_0a5jll9</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0a5jll9" sourceRef="Activity_01qal4l" targetRef="Gateway_1retytl" />
    <bpmn:exclusiveGateway id="Gateway_058h73c" default="Flow_1djiv9t">
      <bpmn:incoming>Flow_1o3kz1m</bpmn:incoming>
      <bpmn:outgoing>Flow_1i5ovtt</bpmn:outgoing>
      <bpmn:outgoing>Flow_1djiv9t</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:sequenceFlow id="Flow_1o3kz1m" sourceRef="Activity_0lc0ysm" targetRef="Gateway_058h73c" />
    <bpmn:endEvent id="Event_1fqwebf">
      <bpmn:incoming>Flow_1djiv9t</bpmn:incoming>
      <bpmn:incoming>Flow_1mut0zg</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_1i5ovtt" name="Si" sourceRef="Gateway_058h73c" targetRef="Activity_0wa3dy3">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression" language="eval">context.detalles.findIndex((x) =&gt; x.codigo === "900" &amp;&amp; x.valor === "1") &gt;= 0</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1djiv9t" sourceRef="Gateway_058h73c" targetRef="Event_1fqwebf" />
    <bpmn:task id="Activity_0wa3dy3" name="Dialogo: Ayudas publicas">
      <bpmn:extensionElements>
        <camunda:inputOutput>
          <camunda:inputParameter name="componente">DialogCasilla900</camunda:inputParameter>
          <camunda:inputParameter name="type">dialog-form</camunda:inputParameter>
        </camunda:inputOutput>
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1i5ovtt</bpmn:incoming>
      <bpmn:outgoing>Flow_1mut0zg</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_1mut0zg" sourceRef="Activity_0wa3dy3" targetRef="Event_1fqwebf" />
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_0v0xgam">
      <bpmndi:BPMNEdge id="Flow_1mut0zg_di" bpmnElement="Flow_1mut0zg">
        <di:waypoint x="2970" y="1900" />
        <di:waypoint x="2970" y="1992" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1djiv9t_di" bpmnElement="Flow_1djiv9t">
        <di:waypoint x="2810" y="1885" />
        <di:waypoint x="2810" y="2010" />
        <di:waypoint x="2952" y="2010" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1i5ovtt_di" bpmnElement="Flow_1i5ovtt">
        <di:waypoint x="2835" y="1860" />
        <di:waypoint x="2920" y="1860" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2874" y="1842" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1o3kz1m_di" bpmnElement="Flow_1o3kz1m">
        <di:waypoint x="2680" y="1860" />
        <di:waypoint x="2785" y="1860" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0a5jll9_di" bpmnElement="Flow_0a5jll9">
        <di:waypoint x="3490" y="1860" />
        <di:waypoint x="3585" y="1860" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0s0nwy7_di" bpmnElement="Flow_0s0nwy7">
        <di:waypoint x="3760" y="1790" />
        <di:waypoint x="3760" y="1842" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_18qr07k_di" bpmnElement="Flow_18qr07k">
        <di:waypoint x="3610" y="1835" />
        <di:waypoint x="3610" y="1750" />
        <di:waypoint x="3710" y="1750" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3594" y="1786" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0mpk59d_di" bpmnElement="Flow_0mpk59d">
        <di:waypoint x="3635" y="1860" />
        <di:waypoint x="3742" y="1860" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_03ykz4w_di" bpmnElement="Flow_03ykz4w">
        <di:waypoint x="380" y="1500" />
        <di:waypoint x="380" y="1560" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0p9i4wt_di" bpmnElement="Flow_0p9i4wt">
        <di:waypoint x="380" y="1090" />
        <di:waypoint x="380" y="1020" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_151tr8a_di" bpmnElement="Flow_151tr8a">
        <di:waypoint x="700" y="1292" />
        <di:waypoint x="700" y="1130" />
        <di:waypoint x="430" y="1130" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="701" y="1230" width="58" height="40" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0ow32is_di" bpmnElement="Flow_0ow32is">
        <di:waypoint x="700" y="1342" />
        <di:waypoint x="700" y="1460" />
        <di:waypoint x="430" y="1460" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="702" y="1363" width="26" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_099yt6d_di" bpmnElement="Flow_099yt6d">
        <di:waypoint x="610" y="1317" />
        <di:waypoint x="675" y="1317" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0qiwx8u_di" bpmnElement="Flow_0qiwx8u">
        <di:waypoint x="405" y="1317" />
        <di:waypoint x="510" y="1317" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="410" y="1299" width="60" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1gs0ao6_di" bpmnElement="Flow_1gs0ao6">
        <di:waypoint x="1950" y="550" />
        <di:waypoint x="1950" y="880" />
        <di:waypoint x="1870" y="880" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0yi4hug_di" bpmnElement="Flow_0yi4hug">
        <di:waypoint x="1650" y="595" />
        <di:waypoint x="1650" y="510" />
        <di:waypoint x="1900" y="510" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1jnryfe_di" bpmnElement="Flow_1jnryfe">
        <di:waypoint x="1675" y="620" />
        <di:waypoint x="1770" y="620" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_07nnx0l_di" bpmnElement="Flow_07nnx0l">
        <di:waypoint x="380" y="940" />
        <di:waypoint x="380" y="900" />
        <di:waypoint x="800" y="900" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_140sa0o_di" bpmnElement="Flow_140sa0o">
        <di:waypoint x="3225" y="1640" />
        <di:waypoint x="3270" y="1640" />
        <di:waypoint x="3270" y="1475" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3232" y="1622" width="32" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1s119oe_di" bpmnElement="Flow_1s119oe">
        <di:waypoint x="3200" y="1665" />
        <di:waypoint x="3200" y="1860" />
        <di:waypoint x="3390" y="1860" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3202" y="1682" width="36" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0fvoar1_di" bpmnElement="Flow_0fvoar1">
        <di:waypoint x="3135" y="1640" />
        <di:waypoint x="3175" y="1640" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3137" y="1622" width="36" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0u1s189_di" bpmnElement="Flow_0u1s189">
        <di:waypoint x="3110" y="1665" />
        <di:waypoint x="3110" y="1700" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3114" y="1673" width="32" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_00vvqis_di" bpmnElement="Flow_00vvqis">
        <di:waypoint x="3110" y="1570" />
        <di:waypoint x="3110" y="1615" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0hxk3bt_di" bpmnElement="Flow_0hxk3bt">
        <di:waypoint x="3140" y="750" />
        <di:waypoint x="3410" y="750" />
        <di:waypoint x="3410" y="1380" />
        <di:waypoint x="3135" y="1380" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_022fczb_di" bpmnElement="Flow_022fczb">
        <di:waypoint x="2750" y="1080" />
        <di:waypoint x="2750" y="1155" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1nd7ezu_di" bpmnElement="Flow_1nd7ezu">
        <di:waypoint x="2385" y="900" />
        <di:waypoint x="2540" y="900" />
        <di:waypoint x="2540" y="730" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2544" y="820" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0hr6mzh_di" bpmnElement="Flow_0hr6mzh">
        <di:waypoint x="3295" y="1450" />
        <di:waypoint x="3390" y="1450" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0w4fsos_di" bpmnElement="Flow_0w4fsos">
        <di:waypoint x="3440" y="1490" />
        <di:waypoint x="3440" y="1820" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0bfh882_di" bpmnElement="Flow_0bfh882">
        <di:waypoint x="3135" y="1860" />
        <di:waypoint x="3270" y="1860" />
        <di:waypoint x="3270" y="1475" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3274" y="1812" width="32" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1vnjd24_di" bpmnElement="Flow_1vnjd24">
        <di:waypoint x="3110" y="1405" />
        <di:waypoint x="3110" y="1450" />
        <di:waypoint x="3245" y="1450" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3154" y="1433" width="32" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_19s6tp9_di" bpmnElement="Flow_19s6tp9">
        <di:waypoint x="3135" y="1860" />
        <di:waypoint x="3390" y="1860" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3332" y="1842" width="36" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1y6pktu_di" bpmnElement="Flow_1y6pktu">
        <di:waypoint x="3110" y="1780" />
        <di:waypoint x="3110" y="1835" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0t95l2h_di" bpmnElement="Flow_0t95l2h">
        <di:waypoint x="3110" y="1405" />
        <di:waypoint x="3110" y="1490" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3128" y="1463" width="36" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_06xsfgi_di" bpmnElement="Flow_06xsfgi">
        <di:waypoint x="2750" y="1205" />
        <di:waypoint x="2750" y="1380" />
        <di:waypoint x="3085" y="1380" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2762" y="1322" width="36" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0upmu2e_di" bpmnElement="Flow_0upmu2e">
        <di:waypoint x="2970" y="1325" />
        <di:waypoint x="2970" y="1380" />
        <di:waypoint x="3085" y="1380" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_11cl8y0_di" bpmnElement="Flow_11cl8y0">
        <di:waypoint x="3150" y="1300" />
        <di:waypoint x="3212" y="1300" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1qyifj9_di" bpmnElement="Flow_1qyifj9">
        <di:waypoint x="3090" y="925" />
        <di:waypoint x="3090" y="1040" />
        <di:waypoint x="2800" y="1040" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3097" y="945" width="66" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_18ljwm3_di" bpmnElement="Flow_18ljwm3">
        <di:waypoint x="3090" y="875" />
        <di:waypoint x="3090" y="790" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="3094" y="829" width="72" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_044uc4q_di" bpmnElement="Flow_044uc4q">
        <di:waypoint x="2970" y="900" />
        <di:waypoint x="3065" y="900" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_12odvif_di" bpmnElement="Flow_12odvif">
        <di:waypoint x="2775" y="900" />
        <di:waypoint x="2870" y="900" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2808" y="872" width="36" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_168u8g6_di" bpmnElement="Flow_168u8g6">
        <di:waypoint x="2995" y="1300" />
        <di:waypoint x="3050" y="1300" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2978" y="1222" width="44" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0jgpucw_di" bpmnElement="Flow_0jgpucw">
        <di:waypoint x="3110" y="1220" />
        <di:waypoint x="3110" y="1240" />
        <di:waypoint x="2970" y="1240" />
        <di:waypoint x="2970" y="1275" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1cnd2pi_di" bpmnElement="Flow_1cnd2pi">
        <di:waypoint x="2775" y="1180" />
        <di:waypoint x="3060" y="1180" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2832" y="1156" width="32" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0iylejf_di" bpmnElement="Flow_0iylejf">
        <di:waypoint x="2750" y="925" />
        <di:waypoint x="2750" y="1000" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2750" y="954" width="32" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0vsb107_di" bpmnElement="Flow_0vsb107">
        <di:waypoint x="2590" y="690" />
        <di:waypoint x="2750" y="690" />
        <di:waypoint x="2750" y="875" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_01vhyuf_di" bpmnElement="Flow_01vhyuf">
        <di:waypoint x="2385" y="900" />
        <di:waypoint x="2725" y="900" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2622" y="882" width="15" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0m29n24_di" bpmnElement="Flow_0m29n24">
        <di:waypoint x="2240" y="650" />
        <di:waypoint x="2360" y="650" />
        <di:waypoint x="2360" y="875" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0v5kfy4_di" bpmnElement="Flow_0v5kfy4">
        <di:waypoint x="2190" y="535" />
        <di:waypoint x="2190" y="610" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2198" y="570" width="15" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0ms4585_di" bpmnElement="Flow_0ms4585">
        <di:waypoint x="2215" y="510" />
        <di:waypoint x="2360" y="510" />
        <di:waypoint x="2360" y="875" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2282" y="492" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_06y644f_di" bpmnElement="Flow_06y644f">
        <di:waypoint x="2190" y="380" />
        <di:waypoint x="2190" y="485" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1mt5qb2_di" bpmnElement="Flow_1mt5qb2">
        <di:waypoint x="2050" y="200" />
        <di:waypoint x="2190" y="200" />
        <di:waypoint x="2190" y="300" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0g9hden_di" bpmnElement="Flow_0g9hden">
        <di:waypoint x="2000" y="315" />
        <di:waypoint x="2000" y="240" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2004" y="274" width="72" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1563fji_di" bpmnElement="Flow_1563fji">
        <di:waypoint x="2025" y="340" />
        <di:waypoint x="2140" y="340" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2044" y="322" width="77" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1ljigew_di" bpmnElement="Flow_1ljigew">
        <di:waypoint x="1860" y="340" />
        <di:waypoint x="1975" y="340" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0c8xa3q_di" bpmnElement="Flow_0c8xa3q">
        <di:waypoint x="1650" y="340" />
        <di:waypoint x="1760" y="340" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1mn8ik1_di" bpmnElement="Flow_1mn8ik1">
        <di:waypoint x="1435" y="340" />
        <di:waypoint x="1550" y="340" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1481" y="322" width="25" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0iqhrg8_di" bpmnElement="Flow_0iqhrg8">
        <di:waypoint x="1410" y="180" />
        <di:waypoint x="1410" y="118" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1d4ltao_di" bpmnElement="Flow_1d4ltao">
        <di:waypoint x="1410" y="315" />
        <di:waypoint x="1410" y="260" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1414" y="285" width="25" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1wogt2z_di" bpmnElement="Flow_1wogt2z">
        <di:waypoint x="1410" y="410" />
        <di:waypoint x="1410" y="365" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_17q8ite_di" bpmnElement="Flow_17q8ite">
        <di:waypoint x="1410" y="595" />
        <di:waypoint x="1410" y="490" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1421" y="542" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1rdtklu_di" bpmnElement="Flow_1rdtklu">
        <di:waypoint x="2083" y="1000" />
        <di:waypoint x="2360" y="1000" />
        <di:waypoint x="2360" y="925" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_08lgh5m_di" bpmnElement="Flow_08lgh5m">
        <di:waypoint x="2083" y="900" />
        <di:waypoint x="2335" y="900" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2141" y="882" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1018ot3_di" bpmnElement="Flow_1018ot3">
        <di:waypoint x="2058" y="1025" />
        <di:waypoint x="2058" y="1090" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2066" y="1057" width="15" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1i3tyva_di" bpmnElement="Flow_1i3tyva">
        <di:waypoint x="2108" y="1130" />
        <di:waypoint x="2162" y="1130" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1pnmu90_di" bpmnElement="Flow_1pnmu90">
        <di:waypoint x="2058" y="925" />
        <di:waypoint x="2058" y="975" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2066" y="945" width="15" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0n7ehbt_di" bpmnElement="Flow_0n7ehbt">
        <di:waypoint x="1820" y="660" />
        <di:waypoint x="1820" y="860" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_01a0kf6_di" bpmnElement="Flow_01a0kf6">
        <di:waypoint x="1590" y="620" />
        <di:waypoint x="1625" y="620" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1mzcuix_di" bpmnElement="Flow_1mzcuix">
        <di:waypoint x="1435" y="620" />
        <di:waypoint x="1490" y="620" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1442" y="602" width="15" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1d78w0t_di" bpmnElement="Flow_1d78w0t">
        <di:waypoint x="1410" y="710" />
        <di:waypoint x="1410" y="645" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_16ire3m_di" bpmnElement="Flow_16ire3m">
        <di:waypoint x="1870" y="900" />
        <di:waypoint x="2033" y="900" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0ckuwix_di" bpmnElement="Flow_0ckuwix">
        <di:waypoint x="1435" y="900" />
        <di:waypoint x="1770" y="900" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1573" y="873" width="15" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0u1hehu_di" bpmnElement="Flow_0u1hehu">
        <di:waypoint x="1410" y="875" />
        <di:waypoint x="1410" y="790" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1420" y="830" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_03ft8pj_di" bpmnElement="Flow_03ft8pj">
        <di:waypoint x="1210" y="750" />
        <di:waypoint x="1260" y="750" />
        <di:waypoint x="1260" y="860" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1jin8sq_di" bpmnElement="Flow_1jin8sq">
        <di:waypoint x="2265" y="1860" />
        <di:waypoint x="2580" y="1860" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2406" y="1842" width="36" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0jo4w6l_di" bpmnElement="Flow_0jo4w6l">
        <di:waypoint x="2240" y="1885" />
        <di:waypoint x="2240" y="1980" />
        <di:waypoint x="2360" y="1980" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2240" y="1930" width="32" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1ga34pr_di" bpmnElement="Flow_1ga34pr">
        <di:waypoint x="2460" y="1980" />
        <di:waypoint x="2630" y="1980" />
        <di:waypoint x="2630" y="1900" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0ey0bu5_di" bpmnElement="Flow_0ey0bu5">
        <di:waypoint x="1285" y="1830" />
        <di:waypoint x="1330" y="1830" />
        <di:waypoint x="1330" y="1690" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1302" y="1812" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1eh7frs_di" bpmnElement="Flow_1eh7frs">
        <di:waypoint x="1235" y="1830" />
        <di:waypoint x="1160" y="1830" />
        <di:waypoint x="1160" y="1675" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1190" y="1812" width="15" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1147q8q_di" bpmnElement="Flow_1147q8q">
        <di:waypoint x="1210" y="1930" />
        <di:waypoint x="1260" y="1930" />
        <di:waypoint x="1260" y="1855" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1105" y="1962" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0xq57jr_di" bpmnElement="Flow_0xq57jr">
        <di:waypoint x="380" y="1292" />
        <di:waypoint x="380" y="1170" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="352" y="1226" width="55" height="27" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1o6omn0_di" bpmnElement="Flow_1o6omn0">
        <di:waypoint x="380" y="1342" />
        <di:waypoint x="380" y="1420" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="352" y="1359" width="55" height="27" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_11wd0jn_di" bpmnElement="Flow_11wd0jn">
        <di:waypoint x="1310" y="900" />
        <di:waypoint x="1385" y="900" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1wbkjmq_di" bpmnElement="Flow_1wbkjmq">
        <di:waypoint x="1075" y="900" />
        <di:waypoint x="1210" y="900" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1091" y="873" width="19" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0vysdji_di" bpmnElement="Flow_0vysdji">
        <di:waypoint x="1050" y="875" />
        <di:waypoint x="1050" y="750" />
        <di:waypoint x="1110" y="750" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1056" y="809" width="19" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0v53c3y_di" bpmnElement="Flow_0v53c3y">
        <di:waypoint x="900" y="900" />
        <di:waypoint x="1025" y="900" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1u6wjt8_di" bpmnElement="Flow_1u6wjt8">
        <di:waypoint x="2410" y="1670" />
        <di:waypoint x="2410" y="1618" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0sdfubm_di" bpmnElement="Flow_0sdfubm">
        <di:waypoint x="2240" y="1735" />
        <di:waypoint x="2240" y="1835" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0wpvr2t_di" bpmnElement="Flow_0wpvr2t">
        <di:waypoint x="2265" y="1710" />
        <di:waypoint x="2360" y="1710" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2292" y="1692" width="44" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0siam31_di" bpmnElement="Flow_0siam31">
        <di:waypoint x="2240" y="1640" />
        <di:waypoint x="2240" y="1685" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1a9v0ub_di" bpmnElement="Flow_1a9v0ub">
        <di:waypoint x="1970" y="1725" />
        <di:waypoint x="1970" y="1860" />
        <di:waypoint x="2215" y="1860" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1978" y="1792" width="15" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0t1tw1w_di" bpmnElement="Flow_0t1tw1w">
        <di:waypoint x="1995" y="1700" />
        <di:waypoint x="2093" y="1700" />
        <di:waypoint x="2093" y="1600" />
        <di:waypoint x="2190" y="1600" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2039" y="1682" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1xecyw5_di" bpmnElement="Flow_1xecyw5">
        <di:waypoint x="1920" y="1540" />
        <di:waypoint x="1970" y="1540" />
        <di:waypoint x="1970" y="1675" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1936" y="1522" width="19" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0lmnsa4_di" bpmnElement="Flow_0lmnsa4">
        <di:waypoint x="1900" y="1890" />
        <di:waypoint x="2058" y="1890" />
        <di:waypoint x="2058" y="1860" />
        <di:waypoint x="2215" y="1860" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_04fp4sp_di" bpmnElement="Flow_04fp4sp">
        <di:waypoint x="1730" y="1625" />
        <di:waypoint x="1730" y="1540" />
        <di:waypoint x="1820" y="1540" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1731" y="1522" width="77" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1gz9j2o_di" bpmnElement="Flow_1gz9j2o">
        <di:waypoint x="1730" y="1675" />
        <di:waypoint x="1730" y="1890" />
        <di:waypoint x="1800" y="1890" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1738" y="1693" width="84" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0kyfwy2_di" bpmnElement="Flow_0kyfwy2">
        <di:waypoint x="1590" y="1650" />
        <di:waypoint x="1705" y="1650" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1ig3c18_di" bpmnElement="Flow_1ig3c18">
        <di:waypoint x="1380" y="1650" />
        <di:waypoint x="1490" y="1650" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0g5b9c3_di" bpmnElement="Flow_0g5b9c3">
        <di:waypoint x="1185" y="1650" />
        <di:waypoint x="1280" y="1650" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0w4ysny_di" bpmnElement="Flow_0w4ysny">
        <di:waypoint x="1210" y="1490" />
        <di:waypoint x="1312" y="1490" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_10f93ad_di" bpmnElement="Flow_10f93ad">
        <di:waypoint x="1160" y="1625" />
        <di:waypoint x="1160" y="1530" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1jej8rj_di" bpmnElement="flow_31_baleares_no_valido">
        <di:waypoint x="1180" y="1292" />
        <di:waypoint x="1180" y="1180" />
        <di:waypoint x="1260" y="1180" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1cqxcs1_di" bpmnElement="Flow_1cqxcs1">
        <di:waypoint x="1030" y="1625" />
        <di:waypoint x="1030" y="1930" />
        <di:waypoint x="1110" y="1930" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1040" y="1909" width="19" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0y84trs_di" bpmnElement="Flow_0y84trs">
        <di:waypoint x="2190" y="1420" />
        <di:waypoint x="2190" y="1335" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1gw2l8p_di" bpmnElement="Flow_1gw2l8p">
        <di:waypoint x="2040" y="1342" />
        <di:waypoint x="2040" y="1460" />
        <di:waypoint x="2140" y="1460" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2050" y="1398" width="11" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1nstsr6_di" bpmnElement="Flow_1nstsr6">
        <di:waypoint x="2065" y="1317" />
        <di:waypoint x="2172" y="1317" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="2110" y="1299" width="17" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1si9p8a_di" bpmnElement="Flow_1si9p8a">
        <di:waypoint x="1910" y="1317" />
        <di:waypoint x="2015" y="1317" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0p9hlbm_di" bpmnElement="Flow_0p9hlbm">
        <di:waypoint x="1710" y="1440" />
        <di:waypoint x="1860" y="1440" />
        <di:waypoint x="1860" y="1357" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0tbghge_di" bpmnElement="Flow_0tbghge">
        <di:waypoint x="1470" y="1342" />
        <di:waypoint x="1470" y="1440" />
        <di:waypoint x="1610" y="1440" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1478" y="1423" width="84" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1q0hbvi_di" bpmnElement="Flow_1q0hbvi">
        <di:waypoint x="1495" y="1317" />
        <di:waypoint x="1810" y="1317" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1614" y="1299" width="77" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1emwlnm_di" bpmnElement="Flow_1emwlnm">
        <di:waypoint x="1360" y="1317" />
        <di:waypoint x="1445" y="1317" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0yqut5p_di" bpmnElement="flow_a_tu_casa">
        <di:waypoint x="1360" y="1180" />
        <di:waypoint x="1452" y="1180" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0890yh2_di" bpmnElement="flow_31_default">
        <di:waypoint x="1205" y="1317" />
        <di:waypoint x="1260" y="1317" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1uisjs4_di" bpmnElement="regimen_31_flow">
        <di:waypoint x="1030" y="1575" />
        <di:waypoint x="1030" y="1317" />
        <di:waypoint x="1155" y="1317" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1010" y="1453" width="19" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_11cqw9n_di" bpmnElement="regimen_a_respuesta">
        <di:waypoint x="430" y="1600" />
        <di:waypoint x="1005" y="1600" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0flh1rn_di" bpmnElement="inicio_a_regimen">
        <di:waypoint x="188" y="1317" />
        <di:waypoint x="355" y="1317" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="inicio">
        <dc:Bounds x="152" y="1299" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_01e218m_di" bpmnElement="regimen_tributacion_pregunta">
        <dc:Bounds x="330" y="1560" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0dw38gq_di" bpmnElement="regimen_respuesta" isMarkerVisible="true">
        <dc:Bounds x="1005" y="1575" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1bs622d_di" bpmnElement="regime_31_gateway" isMarkerVisible="true">
        <dc:Bounds x="1155" y="1292" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1t1nz8r_di" bpmnElement="direccion_no_baleares_dialogo">
        <dc:Bounds x="1260" y="1140" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0fpjrri_di" bpmnElement="fecha_inicio_actvidad_pregunta_31">
        <dc:Bounds x="1260" y="1277" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_12tj8vy_di" bpmnElement="Event_12tj8vy">
        <dc:Bounds x="1452" y="1162" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1afsbet_di" bpmnElement="Gateway_1afsbet" isMarkerVisible="true">
        <dc:Bounds x="1445" y="1292" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1yb6ow5_di" bpmnElement="Activity_1yb6ow5">
        <dc:Bounds x="1810" y="1277" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1qfmt2q_di" bpmnElement="Activity_1qfmt2q">
        <dc:Bounds x="1610" y="1400" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_09gzd2v_di" bpmnElement="Gateway_09gzd2v" isMarkerVisible="true">
        <dc:Bounds x="2015" y="1292" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_12k96t0_di" bpmnElement="Event_12k96t0">
        <dc:Bounds x="2172" y="1299" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_025yneo_di" bpmnElement="Activity_025yneo">
        <dc:Bounds x="2140" y="1420" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1x60e2h_di" bpmnElement="Gateway_1x60e2h" isMarkerVisible="true">
        <dc:Bounds x="1135" y="1625" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1eo6vmf_di" bpmnElement="Activity_1eo6vmf">
        <dc:Bounds x="1110" y="1450" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_05nms7y_di" bpmnElement="Event_05nms7y">
        <dc:Bounds x="1312" y="1472" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1u6j6cr_di" bpmnElement="Activity_1u6j6cr">
        <dc:Bounds x="1280" y="1610" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_02aenkk_di" bpmnElement="Activity_02aenkk">
        <dc:Bounds x="1490" y="1610" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0p96vo4_di" bpmnElement="Gateway_0p96vo4" isMarkerVisible="true">
        <dc:Bounds x="1705" y="1625" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0h4myio_di" bpmnElement="Activity_0h4myio">
        <dc:Bounds x="1800" y="1850" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1qlv695_di" bpmnElement="Gateway_1qlv695" isMarkerVisible="true">
        <dc:Bounds x="1945" y="1675" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_03pbuv5_di" bpmnElement="Activity_03pbuv5">
        <dc:Bounds x="1820" y="1500" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1gzijoa_di" bpmnElement="Activity_1gzijoa">
        <dc:Bounds x="2190" y="1560" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_07z9ny6_di" bpmnElement="Gateway_07z9ny6" isMarkerVisible="true">
        <dc:Bounds x="2215" y="1685" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_033zsb9_di" bpmnElement="Event_033zsb9">
        <dc:Bounds x="2392" y="1582" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0xntmrp_di" bpmnElement="Activity_0xntmrp">
        <dc:Bounds x="2360" y="1670" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0lc0ysm_di" bpmnElement="Activity_0lc0ysm">
        <dc:Bounds x="2580" y="1820" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0asagmg_di" bpmnElement="Activity_0asagmg">
        <dc:Bounds x="800" y="860" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1dkkzl3_di" bpmnElement="Gateway_1dkkzl3" isMarkerVisible="true">
        <dc:Bounds x="1025" y="875" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1jgfw2m_di" bpmnElement="Activity_1jgfw2m">
        <dc:Bounds x="1110" y="710" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0bx1l8a_di" bpmnElement="Activity_0bx1l8a">
        <dc:Bounds x="1210" y="860" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_11o3rsh_di" bpmnElement="Activity_11o3rsh">
        <dc:Bounds x="2490" y="650" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0zxyifw_di" bpmnElement="Activity_0zxyifw">
        <dc:Bounds x="1950" y="160" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1u7man7_di" bpmnElement="Activity_1u7man7">
        <dc:Bounds x="3060" y="1140" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1kabd9l_di" bpmnElement="Activity_1kabd9l">
        <dc:Bounds x="1550" y="300" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1ywersb_di" bpmnElement="Gateway_1ywersb" isMarkerVisible="true">
        <dc:Bounds x="355" y="1292" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1vv362c_di" bpmnElement="Activity_1vv362c">
        <dc:Bounds x="1110" y="1890" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0javpic_di" bpmnElement="Gateway_0javpic" isMarkerVisible="true">
        <dc:Bounds x="1235" y="1805" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1p2ddq8_di" bpmnElement="Activity_1p2ddq8">
        <dc:Bounds x="2360" y="1940" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_16w8ljc_di" bpmnElement="Gateway_16w8ljc" isMarkerVisible="true">
        <dc:Bounds x="2215" y="1835" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_17rfvti_di" bpmnElement="Gateway_17rfvti" isMarkerVisible="true">
        <dc:Bounds x="1385" y="875" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0w3krhp_di" bpmnElement="Activity_0w3krhp">
        <dc:Bounds x="1360" y="710" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0dks2n0_di" bpmnElement="Gateway_0dks2n0" isMarkerVisible="true">
        <dc:Bounds x="2033" y="875" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1gugie5_di" bpmnElement="Activity_1gugie5">
        <dc:Bounds x="1770" y="860" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1i3e7tz_di" bpmnElement="Gateway_1i3e7tz" isMarkerVisible="true">
        <dc:Bounds x="1385" y="595" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0a5h2px_di" bpmnElement="Activity_0a5h2px">
        <dc:Bounds x="1490" y="580" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0hf4j0h_di" bpmnElement="Activity_0hf4j0h">
        <dc:Bounds x="1770" y="580" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0r611u5_di" bpmnElement="Activity_0r611u5">
        <dc:Bounds x="2008" y="1090" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1tgh2pb_di" bpmnElement="Gateway_1tgh2pb" isMarkerVisible="true">
        <dc:Bounds x="2033" y="975" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_028njv5_di" bpmnElement="Event_028njv5">
        <dc:Bounds x="2162" y="1112" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0p3cwsw_di" bpmnElement="Gateway_0p3cwsw" isMarkerVisible="true">
        <dc:Bounds x="2335" y="875" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0sd3uz3_di" bpmnElement="Activity_0sd3uz3">
        <dc:Bounds x="1360" y="410" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1d3hg8r_di" bpmnElement="Activity_1d3hg8r">
        <dc:Bounds x="1360" y="180" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1ersvjv_di" bpmnElement="Gateway_1ersvjv" isMarkerVisible="true">
        <dc:Bounds x="1385" y="315" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_1ka7io4_di" bpmnElement="Event_1ka7io4">
        <dc:Bounds x="1392" y="82" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1g6j0k5_di" bpmnElement="Activity_1g6j0k5">
        <dc:Bounds x="1760" y="300" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0jgjdfl_di" bpmnElement="Gateway_0jgjdfl" isMarkerVisible="true">
        <dc:Bounds x="1975" y="315" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0my4udz_di" bpmnElement="Activity_0my4udz">
        <dc:Bounds x="2140" y="300" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1fdlo2s_di" bpmnElement="Gateway_1fdlo2s" isMarkerVisible="true">
        <dc:Bounds x="2165" y="485" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1uhwzvp_di" bpmnElement="Activity_1uhwzvp">
        <dc:Bounds x="2140" y="610" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_13qrl78_di" bpmnElement="Gateway_13qrl78" isMarkerVisible="true">
        <dc:Bounds x="2725" y="875" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1mztnzf_di" bpmnElement="Gateway_1mztnzf" isMarkerVisible="true">
        <dc:Bounds x="2725" y="1155" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0db7thg_di" bpmnElement="Activity_0db7thg">
        <dc:Bounds x="3050" y="1260" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_18hqwij_di" bpmnElement="Gateway_18hqwij" isMarkerVisible="true">
        <dc:Bounds x="2945" y="1275" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_1vfwn4k_di" bpmnElement="Event_1vfwn4k">
        <dc:Bounds x="3212" y="1282" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0sdroq4_di" bpmnElement="Activity_0sdroq4">
        <dc:Bounds x="2870" y="860" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1d25p5j_di" bpmnElement="Activity_1d25p5j">
        <dc:Bounds x="3040" y="710" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1kjjs5s_di" bpmnElement="Gateway_1kjjs5s" isMarkerVisible="true">
        <dc:Bounds x="3065" y="875" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0yf4i6t_di" bpmnElement="Gateway_0yf4i6t" isMarkerVisible="true">
        <dc:Bounds x="3085" y="1355" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_06vswya_di" bpmnElement="Activity_06vswya">
        <dc:Bounds x="3060" y="1700" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0zyq36o_di" bpmnElement="Gateway_0zyq36o" isMarkerVisible="true">
        <dc:Bounds x="3085" y="1835" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0hzz3zo_di" bpmnElement="Gateway_0hzz3zo" isMarkerVisible="true">
        <dc:Bounds x="3245" y="1425" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0dfavzw_di" bpmnElement="Activity_0dfavzw">
        <dc:Bounds x="3390" y="1410" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0257bmo_di" bpmnElement="Activity_0257bmo">
        <dc:Bounds x="2700" y="1000" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0bsogrm_di" bpmnElement="Activity_0bsogrm">
        <dc:Bounds x="3060" y="1490" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_06v715q_di" bpmnElement="Gateway_06v715q" isMarkerVisible="true">
        <dc:Bounds x="3085" y="1615" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_101ssym_di" bpmnElement="Gateway_101ssym" isMarkerVisible="true">
        <dc:Bounds x="3175" y="1615" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1v822zh_di" bpmnElement="Activity_1v822zh">
        <dc:Bounds x="330" y="940" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_0p9fohs_di" bpmnElement="Gateway_0p9fohs" isMarkerVisible="true">
        <dc:Bounds x="1625" y="595" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0nojxmr_di" bpmnElement="Activity_0nojxmr">
        <dc:Bounds x="1900" y="470" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0tja1ii_di" bpmnElement="Activity_0tja1ii">
        <dc:Bounds x="510" y="1277" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1piptf7_di" bpmnElement="Gateway_1piptf7" isMarkerVisible="true">
        <dc:Bounds x="675" y="1292" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1ltspy0_di" bpmnElement="Activity_1ltspy0">
        <dc:Bounds x="330" y="1090" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0jdfj5i_di" bpmnElement="Activity_0jdfj5i">
        <dc:Bounds x="330" y="1420" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_0w0ecfe_di" bpmnElement="Event_0w0ecfe">
        <dc:Bounds x="3742" y="1842" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1retytl_di" bpmnElement="Gateway_1retytl" isMarkerVisible="true">
        <dc:Bounds x="3585" y="1835" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_19vghrj_di" bpmnElement="Activity_19vghrj">
        <dc:Bounds x="3710" y="1710" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_01qal4l_di" bpmnElement="Activity_01qal4l">
        <dc:Bounds x="3390" y="1820" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_058h73c_di" bpmnElement="Gateway_058h73c" isMarkerVisible="true">
        <dc:Bounds x="2785" y="1835" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_1fqwebf_di" bpmnElement="Event_1fqwebf">
        <dc:Bounds x="2952" y="1992" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0wa3dy3_di" bpmnElement="Activity_0wa3dy3">
        <dc:Bounds x="2920" y="1820" width="100" height="80" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>
`;