import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import { mdiChevronDown, mdiChevronUp, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

interface IProps {
    justificantes?: any[];
    showAttachments: boolean;
    handleViewDocument?: (justificante: any) => void;
    handleDeleteDocument?: (justificante: any) => void;
    expedientBlocked?: boolean;
    handleDown?: (index: number) => void;
    handleUp?: (index: number) => void;
}

export const TablaPagos: FC<IProps> = ({ justificantes, showAttachments, handleDeleteDocument, handleDown, handleUp, expedientBlocked }) => {
    const { t } = useTranslation("expediente");

    return (
        <>
            {justificantes !== undefined ?
                <TableContainer>
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow>
                                {!expedientBlocked && showAttachments && handleDown !== undefined && handleUp !== undefined && <StyledTableCell></StyledTableCell>}
                                {!showAttachments && <StyledTableCell>{t("solicitud:prelacion")}</StyledTableCell>}
                                <StyledTableCell>{t("solicitud:creditor")}</StyledTableCell>
                                <StyledTableCell>{t("solicitud:cif-nif-creditor")}</StyledTableCell>
                                <StyledTableCell>{t("solicitud:numero-documento")}</StyledTableCell>
                                <StyledTableCell>{t("solicitud:fecha-emision")}</StyledTableCell>
                                <StyledTableCell>{t("solicitud:base-imponible")}</StyledTableCell>
                                <StyledTableCell>{t("solicitud:importe_total")}</StyledTableCell>
                                <StyledTableCell>{t("solicitud:fechaPago")}</StyledTableCell>
                                {/* <StyledTableCell></StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                justificantes.map((justificante, index: number) => (
                                    <TableRow key={index}>
                                        {!expedientBlocked && showAttachments && handleDown !== undefined && handleUp !== undefined &&
                                            <TableCell>
                                                <IconButton color="inherit" onClick={() => handleUp(index)} disabled={index === 0}>
                                                    <Icon path={mdiChevronUp} size={1} />
                                                </IconButton>
                                                <IconButton color="inherit" onClick={() => handleDown(index)} disabled={index === justificantes.length - 1}>
                                                    <Icon path={mdiChevronDown} size={1} />
                                                </IconButton>
                                            </TableCell>}
                                        {!showAttachments && <TableCell>{justificante.numeroPrelacion}</TableCell>}
                                        <TableCell>{justificante.nombreProveedor}</TableCell>
                                        <TableCell>{justificante.nifProveedor}</TableCell>
                                        <TableCell>{justificante.numeroReferencia}</TableCell>
                                        {justificante.fechaEmision.toString().includes('T')
                                            ?
                                            <TableCell>{moment(justificante.fechaEmision).format('DD/MM/yyyy')}</TableCell>
                                            :
                                            <TableCell>{justificante.fechaEmision}</TableCell>}
                                        <TableCell>{justificante.baseImponible ? parseFloat(justificante.baseImponible.toString()).toLocaleString('de-DE', {
                                            minimumFractionDigits: 2,
                                        }) : ''}</TableCell>
                                        <TableCell>{parseFloat(justificante.importe.toString()).toLocaleString('de-DE', {
                                            minimumFractionDigits: 2,
                                        })}</TableCell>
                                        {justificante.fechaPago.toString().includes('T')
                                            ?
                                            <TableCell>{moment(justificante.fechaPago).format('DD/MM/yyyy')}</TableCell>
                                            :
                                            <TableCell>{justificante.fechaPago}</TableCell>}
                                        {showAttachments
                                            // && handleViewDocument !== undefined 
                                            && handleDeleteDocument !== undefined &&
                                            <TableCell align="right">
                                                {/* <IconButton color="inherit" onClick={() => handleViewDocument(justificante)}>
                                                    <Icon color='#004f84' path={mdiEyeCircleOutline} size={1} />
                                                </IconButton> */}
                                                {!expedientBlocked &&
                                                    <IconButton color="inherit" onClick={() => handleDeleteDocument(justificante)}>
                                                        <Icon color={'red'} path={mdiTrashCan} size={1} />
                                                    </IconButton>
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                : null}
        </>
    )
}
export default TablaPagos;