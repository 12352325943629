import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContext } from "../../contexts/app.context";
import useGlobalStyles from "../../resources/styles/global.styles";
import MainLayout from "../../components/templates/_main.layout";
import { TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Paper, TextField, InputAdornment } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiSearchWeb } from "@mdi/js";
import useExpedienteGateway from "../../gateways/expediente.gateway";
import { ICNAE } from "../../gateways/interfaces/expediente.interface";
import { Alert } from "@material-ui/lab";

export const CNAEPage: FC = () => {
    const { t } = useTranslation('solicitud');
    const classes = useGlobalStyles();
    const [page, pageDispatcher] = useContext(PageContext);
    const [search, setSearch] = useState('');
    const { getListadoCNAE } = useExpedienteGateway();
    const [cnaes, setCnaes] = useState<ICNAE[]>([]);

    const cnaesTranslated = useMemo(() => cnaes.map(x => ({
        ...x,
        descripcion: page.lang === 'es' ? x.descripcionES : x.descripcionCA,
    })), [cnaes, page.lang]);

    const [cnaesFiltered, setCnaesFiltered] = useState<(ICNAE & { descripcion: string })[]>([]);

    const handleSearch = useCallback((x: string) => {
        setSearch(x);
    }, [])

    useEffect(() => {
        let x = cnaesTranslated
            .filter((x) => x.codigo.indexOf(search) >= 0 || x.descripcion.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0);
        setCnaesFiltered(x);
    }, [cnaesTranslated, search, page.lang])

    useEffect(() => {
        async function load() {
            let result = await getListadoCNAE();
            result = result.sort((a, b) => parseInt(a.codigo, 10) - parseInt(b.codigo, 10));
            setCnaes(result);
        }
        load();
    }, [getListadoCNAE])

    useEffect(() => {
        pageDispatcher({
            type: 'setMenu',
            menu: false,
            menuContent: false,
        });
    }, [pageDispatcher]);

    return (
        <MainLayout>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }} className={classes.buscador}>
                <TextField
                    value={search}
                    placeholder={t('global:cnae_buscar')}
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon path={mdiSearchWeb} size={1} />
                            </InputAdornment>
                        ),
                    }}
                />
                <div style={{ flex: 1, textAlign: 'start' }}>
                    <h2>{t('solicitud:listado_cnaes')}</h2>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={140} align="center">
                                {t('solicitud:codigoCNAE')}
                            </TableCell>
                            <TableCell>
                                {t('solicitud:descripcionActividad')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        cnaesFiltered.length > 0 &&
                        <TableBody>
                            {
                                cnaesFiltered.map((x) => (
                                    <TableRow key={`row_cnae${x.id}`}>
                                        <TableCell align="center">{x.codigo}</TableCell>
                                        <TableCell>{x.descripcion}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            {
                cnaesFiltered.length === 0 &&
                <div style={{ display: 'flex' }}>
                    <Alert style={{ flex: 1 }} severity="warning">{t('solicitud:no_cnaes')}</Alert>
                </div>
            }
        </MainLayout>
    );
}