import { Button, IconButton } from "@material-ui/core";
import { mdiEyeCircleOutline, mdiTrashCan } from "@mdi/js";
import { Icon } from "@mdi/react";
import moment from "moment";
import { useCallback, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useDocumentGateway from "../../gateways/document.gateway";
import useExpedienteGateway from "../../gateways/expediente.gateway";
import { downloadFile } from "../../utils/downloadFile";

export const AttachButton: FC<{ id: string, documento: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string } | null, nameFile: string, text?: string, handleFile: (event: any) => void, deattachFile: () => void; blocked?: boolean }> = ({ id, documento, nameFile, text, handleFile, deattachFile, blocked }) => {
    const { t } = useTranslation();
    const { saveDocument } = useDocumentGateway();
    const { getFile } = useExpedienteGateway();
    const [errorType, setErrorType] = useState(false);
    const [messageType, setMessageType] = useState('');

    const readBlobFile = useCallback((x: File) => {
        return new Promise<Blob>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const blob = new Blob([reader.result as ArrayBuffer], { type: x.type });
                resolve(blob);
            };
            reader.readAsArrayBuffer(x);
        })
    }, []);

    const seeFile = useCallback(async (documento: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string }) => {
        let blob;
        if (documento.idDocumento) {
            blob = await getFile(documento.idDocumento)
            return await downloadFile(blob, documento.file ? documento.file.name : documento.nombre ? documento.nombre : '');
        } else if (documento.file) {
            blob = await readBlobFile(documento.file);
        }

        if (blob) {
            const fileUrl = URL.createObjectURL(blob);
            const w = window.open(fileUrl, '_blank');
            w && w.focus();
        }
    }, [readBlobFile, getFile]);

    const handleFileIntercept = useCallback(async (e: any) => {
        const file = e.target.files[0] as File;
        if (!file.type.includes("pdf") && !file.type.includes("image")) {
            setErrorType(true);
            setMessageType(t('error:solo_imagenes_o_pdf'))
            return;
        } else {
            setErrorType(false);
            setMessageType('')
        }
        const myNewFile = new File([file], moment() + file.name, { type: file.type });
        try {
            const idDoc = await saveDocument(myNewFile);
            handleFile({ file: e.target.files[0], idDocumento: idDoc });
        } catch (error) {
            setErrorType(true);
            setMessageType(t('error:documento_no_subido'));
            console.log('Error:', error);
        }
    }, [handleFile, saveDocument, setErrorType, setMessageType, t]);
    
    return (
        <>
            {
                documento && (documento.file || documento.showContent)
                    ?
                    <>
                        <span style={{ paddingRight: 5 }}>{documento.file ? documento.file.name : documento.nombre}</span>
                        <IconButton color="inherit" onClick={() => seeFile(documento)}>
                            <Icon color='#004f84' path={mdiEyeCircleOutline} size={1} />
                        </IconButton>
                        {!blocked &&
                            <IconButton color="inherit" onClick={() => deattachFile()}>
                                <Icon color={'red'} path={mdiTrashCan} size={1} />
                            </IconButton>
                        }
                    </>
                    :
                    <>
                        {!blocked &&
                            <div style={{ textAlign: 'right' }}>
                                <input
                                    required={true}
                                    type="file"
                                    name={nameFile}
                                    id={id}
                                    accept="image/*,.pdf"
                                    style={{ display: "none" }}
                                    onChange={handleFileIntercept}
                                />
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        const justificante = document.getElementById(id);
                                        if (justificante) {
                                            justificante.click();
                                        }
                                    }}>
                                    {!text ? t('solicitud:adjuntar') : text}
                                </Button>
                                {errorType && <p style={{ color: "red" }}>{messageType}</p>}
                            </div>
                        }
                    </>
            }
        </>
    )
}
