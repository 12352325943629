import { FC, useState, useCallback } from 'react';
import { Dialog, Button, DialogActions, DialogContent, Divider, makeStyles, TextField, DialogTitle } from "@material-ui/core";
import Term from '../term';
import { useTranslation } from 'react-i18next';
import { InputChangeHandler } from '../../../utils/events';
import { DialogFormProps, DialogoWorkflow } from '../workflow/interfaces';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    width: {
        width: '100%'
    },
    content: {
        margin: 5,
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
        marginLeft: 10,
        marginBottom: -8,
    }
}));


const DialogCasilla3051: FC<DialogFormProps> = ({ expediente, onChange, onCloseWithoutChanges }) => {
    const { t } = useTranslation("");
    const classes = useStyles();
    const [numeroI, setNumeroI] = useState('');

    const handleChangeNumeroI: InputChangeHandler = useCallback((event) => {
        setNumeroI(event.target.value);
    }, []);


    const handleChange = useCallback(() => {
        onChange({
            ...expediente,
            detalles: [
                ...expediente.detalles ?? [],
                { codigo: '3051', origen: 'actividad-economica', valor: numeroI },
            ]
        });
    }, [numeroI, onChange, expediente]);

    return <>
        <Dialog
            maxWidth='xs'
            fullWidth={true}
            open={true}
        >
            <DialogTitle>{t("solicitud:title_modal_3051")}</DialogTitle>
            <DialogContent className={classes.content}>
                <TextField
                    label={t("solicitud:numeroIdentificacionGrupo")}
                    className={classes.width}
                    style={{ flex: 1, paddingRight: 5 }}
                    onChange={handleChangeNumeroI}
                    value={numeroI}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onCloseWithoutChanges}
                    color="default"
                >
                    <Term text="cerrar" />
                </Button>
                <Button
                    onClick={handleChange}
                    disabled={numeroI === ''}
                    color="primary"
                    variant="contained"
                >
                    <Term text="confirmar" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

const DialogCasilla3051Workflow: DialogoWorkflow = {
    Component: DialogCasilla3051,
    onResponse: (expediente) => {
        const actividadEconomica = [...(expediente.actividadEconomica || [])];

        actividadEconomica?.push({
            pregunta: 'solicitud:respuesta_modal_3051',
            opciones: [],
            opcionSeleccionada: {
                value: 'respuesta-modal-3051',
                label: 'solicitud:casillero3051'
            }
        });

        return {
            ...expediente,
            actividadEconomica,
        }
    },
}

export default DialogCasilla3051Workflow;