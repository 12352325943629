import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import ProtectedRoute from '../../components/organisms/protected-route';
// import { WorkflowEngine } from "../../components/organisms/workflow";
// import { fromBpmnToWorkflow } from "../../components/organisms/workflow/bpmn.converter";
import { PageContext } from "../../contexts/app.context";
import { ConfigContext } from "../../contexts/config.context";
import useExpedienteGateway from "../../gateways/expediente.gateway";
import { JustificantesPagoPage } from "../justificantes-pago";
import { ResultadoSolicitudPage } from "../resultado-solicitud";
import SolicitudPage from "../solicitud";
import { dispatch, resolverPaso } from "./expediente.utils";
// import actividadesEconomicasBPMN from '../../components/organisms/actividad-economica-bpmn';
// import { IRespuesta } from "../../components/organisms/workflow/interfaces";
import moment from "moment";
import { useCallback } from "react";
import Term from "../../components/organisms/term";
import useContentsGateway from "../../gateways/contents.gateway";
import { CumplimientoCompromisosPage } from "../cumplimiento-compromisos";

export const ExpedientePage: FC = () => {
    const [pageState, pageDispatcher] = useContext(PageContext);
    const history = useHistory();
    const match = useRouteMatch();
    const { getExpediente, getSujetoRepresentado, getSujeto } = useExpedienteGateway();

    const [loading, setLoading] = useState(true);
    // const { FORMULARIO_ACTIVO } = useContext(ConfigContext);
    const FORMULARIO_ACTIVO = true;
    const [open, setOpen] = useState(false);
    const [body, setBody] = useState('');
    const { FECHA_FIN } = useContext(ConfigContext);
    const { fetchContent } = useContentsGateway();

    const handleRedirect = useCallback(() => {
        history.push('/');
    }, [history])

    useEffect(() => {
        (async () => {
            setBody(await fetchContent('formulario_fuera_plazo'));
        })();
    }, [fetchContent]);

    useEffect(() => {
        if (!FORMULARIO_ACTIVO) {
            history.push('/');

            return;
        } else {
            (async () => {
                setLoading(true);
                const expediente = await getExpediente();
                if (expediente && expediente.idEstado) {

                    let response: any = {};
                    if (pageState.jwp?.idSujetoRepresentado) {
                        response = await getSujetoRepresentado();
                    } else {
                        response = await getSujeto();
                    }

                    expediente.sujeto = response.sujeto;

                    // const workflowActividadesEconomicas = fromBpmnToWorkflow(actividadesEconomicasBPMN);
                    // const workflow = new WorkflowEngine(workflowActividadesEconomicas.tasks, workflowActividadesEconomicas.connectors);
                    // const respuestas: IRespuesta[] = [];
                    // expediente.actividadEconomica = respuestas;

                    // const casillero: Record<string, string> = {};

                    // if (expediente.detalles) {
                    //     for (const detalle of expediente.detalles) {
                    //         casillero['casilla_' + detalle.codigo] = detalle.valor;
                    //     }
                    // }

                    // workflow.onUserTask((task, next) => {
                    //     if (task.type !== 'pregunta') {
                    //         next();
                    //         return;
                    //     }

                    //     const opciones = task.opciones.map(x => ({
                    //         value: x.valor,
                    //         label: x.label,
                    //     }));

                    //     const opcionSeleccionada = task.opciones.filter(x => casillero['casilla_' + x.casilla] === x.valor).map(x => ({
                    //         value: x.valor,
                    //         label: x.label,
                    //     }))[0];

                    //     respuestas.push({
                    //         pregunta: task.pregunta,
                    //         opciones,
                    //         opcionSeleccionada,
                    //     });

                    //     next();
                    // });
                    // expediente.worflowAE = workflow;

                    // workflow.updateContext(expediente);
                    // workflow.run();

                    pageDispatcher({
                        type: 'setMenu',
                        menu: true,
                    });

                    pageDispatcher({
                        type: 'setExpediente',
                        payload: expediente,
                    });
                    pageDispatcher({
                        type: 'setPaso',
                        payload: resolverPaso(expediente.idEstado, expediente.puedeJustificar),
                    });
                    if (/^\/expediente\/?$/.test(history.location.pathname)) {
                        dispatch(expediente.idEstado ? expediente.idEstado : 0, history, expediente.puedeJustificar);
                    }
                } else {
                    if (FECHA_FIN !== null) {
                        if (moment.utc(FECHA_FIN) < moment.utc()) {
                            setLoading(false);
                            setOpen(true);
                            return;
                        }
                    }
                    pageDispatcher({ type: 'cleanExpediente' });
                    pageDispatcher({
                        type: 'setPaso',
                        payload: 0,
                    });
                    history.push('/expediente/solicitud');
                }

                setLoading(false);
            })();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FORMULARIO_ACTIVO]);

    return loading
        ? (<CircularProgress />)
        : (
            <>
                <div>
                    <ProtectedRoute exact path={match.path + "/solicitud"} component={SolicitudPage} />
                    <ProtectedRoute exact path={match.path + "/resultado"} component={ResultadoSolicitudPage} />
                    <ProtectedRoute exact path={match.path + "/justificantes"} component={JustificantesPagoPage} />
                    <ProtectedRoute exact path={match.path + "/cumplimiento"} component={CumplimientoCompromisosPage} />
                </div>
                <Dialog
                    open={open}
                >
                    <DialogTitle>
                        <Term text="sede_electronica" />
                    </DialogTitle>
                    {
                        <DialogContent>
                            <div dangerouslySetInnerHTML={{ __html: body }} />
                        </DialogContent>
                    }
                    <Divider />
                    <DialogActions style={{ color: '#2196f3' }}>
                        <Button
                            onClick={handleRedirect}
                            color="primary"
                            autoFocus
                        >
                            <Term text="cerrar" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
}