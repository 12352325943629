import { WorkflowConnector, WorkflowTask } from "./interfaces";
import sxml from "sxml";
import * as dialogs from "../dialogs";

type convertXmlToWorkflow = (xml: sxml.XML) => WorkflowTask;
type convertXmlToConnectorWorkflow = (
  xml: sxml.XML
) => WorkflowConnector;

const convertXmlListToArray = (xmlList: sxml.XMLList) => {
  const size = xmlList.size();
  const output = [];
  for (let i = 0; i < size; i++) {
    output.push(xmlList.at(i));
  }
  return output;
};

export const fromEndEventToWorkflow: convertXmlToWorkflow = (xml) => ({
  id: xml.getProperty("id"),
  type: "end",
});

export const fromStartEventToWorkflow: convertXmlToWorkflow = (xml) => ({
  id: xml.getProperty("id"),
  type: "start",
});

export const fromExclusiveGatewayToWorkflow: convertXmlToWorkflow = (xml) => {
  return {
    type: "exclusive-gateway",
    id: xml.getProperty("id"),
  };
};

export const fromTaskToWorkflow: convertXmlToWorkflow = (xml) => {
  const id = xml.getProperty("id");
  const inputParams = convertXmlListToArray(
    xml
      .get("bpmn:extensionElements")
      .at(0)
      .get("camunda:inputOutput")
      .at(0)
      .get("camunda:inputParameter")
  );

  const type = inputParams
    .filter((x) => x.getProperty("name") === "type")
    .map((x) => x.getValue())[0];

  switch (type) {
    case "pregunta":
      return {
        id,
        type: "pregunta",
        pregunta: inputParams
          .filter((x) => x.getProperty("name") === "pregunta")
          .map((x) => x.getValue())[0],
        textoAclaratorio: inputParams
          .filter((x) => x.getProperty("name") === "textoAclaratorio")
          .map((x) => x.getValue())[0],
        opciones: inputParams
          .filter((x) => x.getProperty("name") === "opciones")
          .map((x) =>
            convertXmlListToArray(
              x.get("camunda:list").at(0).get("camunda:value")
            )
          )[0]
          .map((x) => JSON.parse(x.getValue())),
      };
    case "dialog-fin":
      return {
        id,
        type: "dialogo-fin",
        motivo: inputParams
          .filter((x) => x.getProperty("name") === "motivo")
          .map((x) => x.getValue())[0],
      };
    case "set-casilla":
      return {
        id,
        type: "set-casilla",
        casilla: inputParams
          .filter((x) => x.getProperty("name") === "casilla")
          .map((x) => x.getValue())[0],
        valor: inputParams
          .filter((x) => x.getProperty("name") === "valor")
          .map((x) => x.getValue())[0],
      }
    case "dialog-form":
      const componentName = inputParams
        .filter((x) => x.getProperty("name") === "componente")
        .map((x) => x.getValue())[0] as string;
      if (!(componentName in dialogs)) {
        throw new Error(`Workflow: Dialog ${componentName} not registered`);
      }
      const componentInfo = (dialogs as any)[componentName];
      return {
        id,
        type: "dialogo-form",
        componentResponse: componentInfo.onResponse,
        componente: componentInfo.Component,
      };
    default:
      throw new Error(`Unknown task type ${type}`);
  }
};

const convertSequenceFlowToConnectorWorkflow: convertXmlToConnectorWorkflow = (
  xml
) => {
  const id = xml.getProperty("id");
  const sourceRef = xml.getProperty("sourceRef");
  const targetRef = xml.getProperty("targetRef");

  const conditionExpression = xml.has("bpmn:conditionExpression")
    ? xml.get("bpmn:conditionExpression").at(0)
    : null;

  let condition: ((context: any) => boolean) | undefined = undefined;

  if (conditionExpression) {
    const lang = conditionExpression.getProperty("language");
    const expression = conditionExpression.getValue();

    switch (lang) {
      case "eval":
        // @ts-ignore
        condition = function (context) {
          // eslint-disable-next-line
          return eval(expression) as boolean;
        };
        break;
      default:
        throw new Error(`Conditional language ${lang} not implemented`);
    }
  }

  return {
    id,
    from: sourceRef,
    to: targetRef,
    condition,
  };
};

export const fromBpmnToWorkflow = (bpmnXmlStr: string) => {
  const bpmnXml = new sxml.XML(bpmnXmlStr);

  const process = bpmnXml.get("bpmn:process").at(0);

  const startEvent = process.get("bpmn:startEvent").at(0);
  const endEvents = convertXmlListToArray(process.get("bpmn:endEvent"));

  const sequenceFlows = convertXmlListToArray(process.get("bpmn:sequenceFlow"));
  const tasks = convertXmlListToArray(process.get("bpmn:task"));
  const exclusiveGateways = convertXmlListToArray(
    process.get("bpmn:exclusiveGateway")
  );

  const workflowTasks: WorkflowTask[] = [
    fromStartEventToWorkflow(startEvent),
    ...tasks.map((x) => fromTaskToWorkflow(x)),
    ...exclusiveGateways.map((x) => fromExclusiveGatewayToWorkflow(x)),
    ...endEvents.map((x) => fromEndEventToWorkflow(x)),
  ];

  const workflowConnectors: WorkflowConnector[] =
    sequenceFlows.map((x) => convertSequenceFlowToConnectorWorkflow(x));

  return {
    tasks: workflowTasks,
    connectors: workflowConnectors,
  };
};
