import { WorkflowEngine } from "../../components/organisms/workflow";
import {
  IRespuesta,
  WorkflowTask,
} from "../../components/organisms/workflow/interfaces";
import { ISujeto } from "./sujeto.interfaces";

export type OnChangeExpedienteCallback = (
  expediente: IExpediente | ((current: IExpediente) => IExpediente)
) => void;

export interface ICNAE {
  id: number;
  codigo: string;
  descripcionES: string;
  descripcionCA: string;
}

export interface IExpediente {
  id?: number;
  referencia?: string;
  fechaRegistro?: Date;
  aprobada?: boolean;
  idEstado?: number;
  idFirma?: number;
  idDocumento?: string;
  justificantes?: IJustificantePago[];
  detalles?: IDetalleExpediente[];
  actividadEconomica?: IRespuesta[];
  worflowAE?: WorkflowEngine;
  workflowAETask?: { task: WorkflowTask; next: () => void, back: () => void };
  sujeto?: ISujeto;
  sociedades?: ISociedad[];
  referenciaBoib?: string | null;
  referenciaBoibExtra?: string | null;
  importeAsignado?: string | null;
  linea?: string | null;
  firmaJustificantes?: IFirmaJustificantes;
  puedeJustificar?:boolean;
}
export interface ISociedad {
  nombre: string;
  nif: string;
  tributa: boolean;
  ejercicio: string;
}

export interface IDetalleExpediente {
  origen: "solicitante" | "actividad-economica" | "declara" | "presenta";
  codigo: string;
  valor: string;
}

export interface IJustificantePago {
  id: number;
  fechaPago: Date;
  nombreProveedor: string;
  nifProveedor: string;
  numeroReferencia: string;
  importe: number;
  idDocumento: string;
  numeroPrelacion: string;
  fechaEmision: Date;
  baseImponible: number;
}

export interface IAyuda {
  idAyuda?: number;
  organismo: string;
  fechaConcesion: Date;
  importe: number;
  idExpediente?: number;
}

export interface IEntidad {
  idEntidad?: number;
  nombre: string;
  nif: string;
  tributa: boolean;
  idExpediente?: number;
}

export interface IJustificantePagoProvisional {
  fechaPago: string;
  nombreProveedor: string;
  nifProveedor: string;
  numeroReferencia: string;
  importe: string;
  fichero: File;
  numeroPrelacion: string;
  fechaEmision: string;
  baseImponible: string;
}

export interface IPrevisionGasto {
  idGasto?: number;
  orden: number | null;
  nombreProveedor: string;
  nifProveedor: string;
  numeroReferencia: string;
  importe: number;
  idExpediente?: number | null;
}

export interface IFirmaJustificantes {
  id?: number;
  idDocumentoThomasCook: string;
  idDocumentoInversiones: string;
  idDocumentoSubvenciones: string;
  idDocumentoAuditoria: string;
  puedeJustificar: boolean;
  costesJustificados: number;
  referenciaIngreso: string;
  idDocumentoIngreso: string;
  costesFijos: boolean;
  idDocumentoCostesFijos: string;
}

export interface IFirmaJustificantesProvisional {
  id?: number;
  documentoThomasCook: string | null;
  documentoInversiones: string | null;
  documentoSubvenciones: string | null;
  documentoAuditoria: string | null;
  puedeJustificar: boolean;
  costesJustificados: number;
  referenciaIngreso: string;
  documentoIngreso: string | null;
  costesFijos: boolean;
  documentoCostesFijos: string | null;
}

export interface IFirmaJustificanteCumplimiento {
    id?: number;
    documentoCumplimiento: string | null;
  }

export interface ICasillero {
  codigo: string;
  valor: any;
}

export enum EstadoExpediente {
  PendienteDocu = 1,
  PendienteVali = 2,
  PendienteValiDocu = 3,
  PendienteCalc = 4,
  RechazoPend = 5,
  PendienteFirma = 6,
  PendientePublicacion = 7,
  Aprobada = 8,
  Rechazada = 9,
  Desistimiento = 12,
  PagosJustificados = 13,
  CumplimientoCompromisos = 14
}

export enum TipoDocumento {
    justificantePresentacion = 1,
    justificanteDespresentacion = 2,
    documentosPresentacion = 3,
    resultadoPresentacion = 4,
    justificanteJustificacion = 15,
    documentacionThomasCook,
    documentacionInversiones,
    documentacionSubvenciones,
    documentacionAuditoria,
    documentacionDevolucionAyuda,
    documentacionCostesFijos,
    desistimiento = 22,
    calculo = 23,
    documentacionCumplimiento = 59,
    justificanteCumplimiento = 60,
}
