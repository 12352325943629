import { StyleRules, Theme } from '@material-ui/core/styles';
import backgroundCabecera from '../images/background-cabecera.png';

const styles = (theme: Theme): StyleRules => ({
    rootAppBar: {
        background: 'linear-gradient(to right, rgba(22,63,117,1) 0%, rgba(45,178,190,1) 85%)',
        color: '#fff',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    growAppBar: {
        flexGrow: 1,
    },
    titleAppBar: {
        fontWeight: 300,
        fontSize: 12,
    },
    subtitleAppBar: {
        fontSize: 10,
    },
    logoSpace: {
        marginRight: theme.spacing(3),
    },
    headerWrap: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    headerContainer: {
        background: `url(${backgroundCabecera}) no-repeat center top`,
        backgroundColor: '#e0eaf4',
    },
    sedeHeader: {
        background: 'linear-gradient(to right, rgba(22,63,117,1), rgba(45,178,190,1))',
    },
    logoSede: {
        width: 50,
        marginLeft: 10,
    },
    logoSedeIni: {
        width: 25,
        verticalAlign: 'middle'
    },
    textSede: {
        fontWeight: 'lighter',
        color: 'white',
        fontSize: 20,
    },
    textSedeIdioma: {
        fontWeight: 'lighter',
        color: 'white',
        fontSize: 15,
    },
    containerLogo: {
        display: 'flex',
        flexGrow: 1,
        margin: '14px 20px',
    },
    titleIni: {
        flex: 1,
        textAlign: 'center',
        marginLeft: 38,
        textDecoration: 'none',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            marginLeft: '20px',
        },
    },
    showInMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    containerLanguage: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: 20,
            fontSize: 8,
        }
    },
    closeSessionContainer: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: 20,
        }
    },
    closeSessionButton: {
        color: '#fff'
    },
    logoContainer: {
        display: 'flex',
        paddingRight: 60,
        paddingLeft: 25
    },
    desktopContainer: {
        display: 'none',
        position: 'fixed',
    },
    mobileContainer: {
        display: 'block',
        width: '100%',
        zIndex: 999,
    },
    iconTextLog: {
        marginRight: 5,
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'center',
        padding: '0 20px',
    },
    textHeaderContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '1.5rem',
        textAlign: 'right'
    },
    withoutLink: {
        color: '#123c6a',
    },
    zIndex: {
        zIndex: 9999,
    },
    textHeader: {
        fontWeight: 'bolder',
    },
    pageTitle: {
        display: 'none',
        fontWeight: 300,
        fontSize: 25,
        color: '#004f84',
        textTransform: 'uppercase',
    },
    pageSubtitle: {
        display: 'none',
        fontWeight: 400,
        fontSize: 15,
        color: '#004f84',
        textTransform: 'uppercase',
        marginLeft: 55,
    },
    [theme.breakpoints.up('md')]: {
        desktopContainer: {
            zIndex: 900,
            display: 'block',
            width: '100%',
            left: 0,
            top: 0,
        },
        pageTitle: {
            display: 'flex',
            alignItems: 'center',
            // paddingBottom: '1rem',
            // borderBottom: '1px solid #a5b2b9',
        },
        pageSubtitle: {
            display: 'flex',
            alignItems: 'center',
            // paddingBottom: '1rem',
            // borderBottom: '1px solid #a5b2b9',
        },
        iconContainer: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#004f82',
            padding: 7,
            borderRadius: '50%',
            marginRight: 10,
        },
        iconContainerTransparent: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 7,
            borderRadius: '50%',
            marginRight: 10,
        },
        button: {
            marginLeft: 20,
            padding: '0 20px',
        },
        pageText: {
            fontSize: 14,
            color: '#004f84',
            fontWeight: 900,
        },
    },
});

export default styles;