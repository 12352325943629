import { Card, CardContent, CircularProgress, Grid, List, ListItemText, MenuItem } from "@material-ui/core";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { PageContext } from "../contexts/app.context";
import useApoderamientoGateway from "../gateways/apoderamiento.gateway";
import MainLayout from "../components/templates/_main.layout"
import { IApoderamiento } from "../gateways/interfaces/apoderamiento.interface";
import { AlertsContext } from "../contexts/alerts.context";
import { useTranslation } from "react-i18next";
import useGlobalStyles from "../resources/styles/global.styles";
import useContentsGateway from "../gateways/contents.gateway";
import Icon from "@mdi/react";
import { mdiCircle } from "@mdi/js";
import CardHome from "../components/organisms/card-home";
// import { ConfigContext } from "../contexts/config.context";
import { Alert } from "@material-ui/lab";
type Props = RouteComponentProps;

export const SeleccionPage: FC<Props> = ({ history }) => {
    const { t } = useTranslation();
    const [pageState, pageDispatcher] = useContext(PageContext);
    const classes = useGlobalStyles();
    const [loading, setLoading] = useState(false);
    const [listado, setListado] = useState<IApoderamiento[]>([]);
    const [vista, setVista] = useState(0);
    const [, setBody] = useState('');
    const [bodyApoderar, setBodyApoderar] = useState('');
    const [noApoderados, setNoApoderados] = useState('');
    const apoderamientoGateway = useApoderamientoGateway();
    // const { FORMULARIO_ACTIVO } = useContext(ConfigContext);
    const { fetchContent } = useContentsGateway();
    const [, alertDispatcher] = useContext(AlertsContext);

    const FORMULARIO_ACTIVO = true;

    // Events
    const handleNombrePropio = useCallback(async () => {
        if (!pageState || !pageState.jwp || !pageState.jwp.nif) {
            history.push('/');
            return;
        }
        const x = await apoderamientoGateway.getRepresentar(pageState.jwp.nif);
        if (x !== null) {
            pageDispatcher({
                type: 'authenticate', payload: {
                    accessToken: x
                }
            });
            history.push('/expediente');
        }
        history.push('/expediente');
    }, [apoderamientoGateway, history, pageDispatcher, pageState]);

    const handleRepresentar = useCallback(async (nif: string) => {
        const x = await apoderamientoGateway.getRepresentar(nif);
        if (x !== null) {
            pageDispatcher({
                type: 'authenticate', payload: {
                    accessToken: x
                }
            });
            history.push('/expediente');
        }
    }, [history, pageDispatcher, apoderamientoGateway])

    const handleRepresentacion = useCallback(async () => {
        setLoading(true);
        setVista(1);
        const x = await apoderamientoGateway.getListPoderdantes();
        if (x !== null) {
            setListado(x);
        } else {
            alertDispatcher({
                type: 'show-alert',
                payload: {
                    message: t('error:error_al_recuperar_nifs'),
                    variant: 'error',
                }
            });
        }
        setLoading(false);
    }, [apoderamientoGateway, alertDispatcher, t])

    useEffect(() => {
        pageDispatcher({
            type: 'setMenu',
            menu: false,
            menuContent: false,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        (async () => {
            setNoApoderados(await fetchContent('no_apoderados'));
            setBodyApoderar(await fetchContent('apoderar'));
            setBody(await fetchContent('home'));
        })();
    }, [fetchContent]);

    return (
        <MainLayout>
            {
                vista === 0 ?
                    <>
                        <Grid className={classes.marginXL} container spacing={4}>
                            <Grid item xs={12} md></Grid>
                            <Grid item xs={12} md={3} className={classes.rootGridContent}>
                                <CardHome
                                    titulo={t('solicitud:actuar_nombre_propio')}
                                    contenido={t('solicitud:card1_seleccion_contenido')}
                                    accionable={FORMULARIO_ACTIVO}
                                    imagen='imagen3'
                                    onClick={handleNombrePropio} />
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.rootGridContent}>
                                <CardHome
                                    titulo={t('solicitud:actuar_representacion')}
                                    contenido={t('solicitud:card2_seleccion_contenido')}
                                    accionable={FORMULARIO_ACTIVO}
                                    imagen='imagen4'
                                    onClick={handleRepresentacion} />
                            </Grid>
                            <Grid item xs={12} md></Grid>
                        </Grid>
                    </>
                    :
                    <>
                        {
                            !loading && vista === 1 ?
                                <Card>
                                    <CardContent>
                                        <h2>{t('solicitud:listado_personas_suplantar')}</h2>
                                        {
                                            listado.length > 0 ?
                                                <>
                                                    <div dangerouslySetInnerHTML={{ __html: bodyApoderar }}></div>

                                                    <List className={classes.marginList}>
                                                        {
                                                            (listado || []).map((x: IApoderamiento) => (
                                                                <MenuItem key={`listado_${x.referencia}`} onClick={() => handleRepresentar(x.nifPoderdante)}>
                                                                    <ListItemText style={{ cursor: 'pointer', marginTop: 10 }}>
                                                                        <Icon path={mdiCircle} size={0.5} color="grey" style={{ marginRight: 10 }} />
                                                                        {x.nombrePoderdante} - {x.nifPoderdante}
                                                                    </ListItemText>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </List>
                                                </>
                                                :
                                                <Alert style={{ alignItems: 'center' }} severity="info" children={<div dangerouslySetInnerHTML={{ __html: noApoderados }} />} />
                                        }

                                    </CardContent>
                                </Card>
                                :
                                <CircularProgress size={1} />
                        }
                    </>
            }
        </MainLayout>
    );
}