import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IExpediente } from '../../gateways/interfaces/expediente.interface';
import { IOpcion } from '../../gateways/interfaces/opcion.interfaces';
import useStyles from '../../resources/styles/solicitud.styles';
import { PrettierText } from '../atoms/prettier-text.atom';
import Preguntas from './preguntas';
import Respuestas from './respuestas';


interface IProps {
    expediente: IExpediente;
    pregunta: string;
    opciones: IOpcion[];
    opcion: IOpcion | undefined;
    textoAclaratorio?: string;
    onAnswer: (valor: IOpcion) => void;
}

const Contestador: React.FC<IProps> = ({ expediente, pregunta, opciones, opcion, textoAclaratorio, onAnswer }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const termsData = useMemo<Record<string, string>>(() => {
        const output: Record<string, string> = {};

        if (expediente.detalles) {
            for (const detalle of expediente.detalles) {
                let valor = detalle.valor;

                switch (detalle.codigo) {
                    case '47':
                        valor = moment(detalle.valor).format('DD/MM/YYYY');
                        break;
                    case '50':
                    case '277':
                    case '278':
                    case '800':
                    case '600':
                    case '601':
                    case '610':
                    case '620':
                    case '630':
                    case '631':
                    case '650':
                        valor = parseFloat(detalle.valor).toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                        });
                        break;
                }

                output['casilla' + detalle.codigo] = valor;
            }
        }

        return output;
    }, [expediente.detalles]);

    return (
        <>

            <div className={opcion === undefined ? classes.preguntaActiva : ''}>
                {opcion !== undefined
                    ? (<div style={{ display: 'flex' }}>
                        <div>
                            <Icon style={{ verticalAlign: 'middle', marginRight: 5 }} path={mdiCheckboxMarkedCircleOutline} color='#8f9ca5' size={1} />
                        </div>
                        <PrettierText text={t(pregunta, termsData)} bold />
                    </div>)
                    : <span className={classes.textoPregunta}><PrettierText text={t(pregunta, termsData)} /></span>
                }
            </div>
            {
                opcion === undefined
                    ?
                    <>
                        {
                            textoAclaratorio &&
                            <div style={{ display: 'flex', marginTop: 5 }}>
                                <PrettierText text={t(textoAclaratorio, termsData)} />
                            </div>
                        }
                        <Preguntas opciones={opciones} onAnswer={onAnswer} />
                    </>
                    :
                    <>
                        <Respuestas termsData={termsData} opcion={opcion} />
                        <div style={{ width: '100%', backgroundColor: '#d6e3eb', height: 2, marginTop: 15, marginBottom: 20 }} />
                    </>
            }
        </>
    )
}

export default Contestador;