import env from "../env";

export async function refreshToken(jwt: string): Promise<any> {
    const response = await fetch(env.API_URI + 'auth/refresh-token', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            accessToken: jwt,
        }),
    });

    const result = await response.json();
    if (result && result.accessToken !== '') {
        return result;
    }

    return result;
}