import { FC, useCallback, useMemo, useState } from "react";
import { CardContent, Card, Button, CardActions } from "@material-ui/core";
import MainLayout from "./_main.layout";
import useStyles from "../../resources/styles/generic.styles";
import { useTranslation } from "react-i18next";
import { IJustificantePagoProvisional, IExpediente, IFirmaJustificantesProvisional, EstadoExpediente, IJustificantePago } from "../../gateways/interfaces/expediente.interface";
import { useEffect } from "react";
import JustificantesRequisitos from "../organisms/justificantes-requisitos";
import JustificantesDestino from "../organisms/justificantes-destino";
import FirmaJustificantes from "../organisms/firma-justificantes";

interface IProps {
    expediente: IExpediente;
    docs: any[];
    loading: boolean;
    handleAdd: (event: any, justificantes: IJustificantePagoProvisional[]) => void;
    handleDelete: (id: number) => void;
    handleView: (id: string) => void;
    handleDownload: () => void;
    handleSign: (firma: IFirmaJustificantesProvisional) => void;
    handleOrderJustificantes: (justificantes?: IJustificantePago[]) => void;
    lineaAeat: string;
}

export const JustificantesPagoTemplate: FC<IProps> = ({ expediente, docs, loading, handleAdd, handleDelete, handleView, handleSign, handleOrderJustificantes, lineaAeat, handleDownload }) => {
    const { t } = useTranslation("expediente");
    const classes = useStyles()
    const [type, setType] = useState<1 | 2 | 3>(1);
    const [importeCasilla,] = useState(expediente?.detalles?.find((x) => x.codigo === '4001')?.valor ?? '0');
    const [filesRequisitos, setFilesRequisitos] = useState<{
        ThomasCook: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string },
        Inversiones: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string },
        Subvenciones: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string }
    }>({
        ThomasCook: (docs ?? []).find((x: any) => x.tipo === 16) ?
            {
                file: new File(['0x0000'] as BlobPart[],
                    (docs ?? []).find((x: any) => x.tipo === 16).nombre),
                showContent: null,
                nombre: null,
                idDocumento: (docs ?? []).find((x: any) => x.tipo === 16).idDocumento
            } :
            { file: null, showContent: null, nombre: null,idDocumento: '' },
        Inversiones: (docs ?? []).find((x: any) => x.tipo === 17) ?
            {
                file: new File(['0x0000'] as BlobPart[],
                    (docs ?? []).find((x: any) => x.tipo === 17).nombre),
                showContent: null,
                nombre: null,
                idDocumento: (docs ?? []).find((x: any) => x.tipo === 17).idDocumento
            } :
            { file: null, showContent: null, nombre: null,idDocumento: '' },
        Subvenciones: (docs ?? []).find((x: any) => x.tipo === 18) ?
            {
                file: new File(['0x0000'] as BlobPart[],
                    (docs ?? []).find((x: any) => x.tipo === 18).nombre),                    
                showContent: null,
                nombre: null,
                idDocumento: (docs ?? []).find((x: any) => x.tipo === 18).idDocumento
            } :
            { file: null, showContent: null, nombre: null,idDocumento: '' },
    });
    const [validateRequisitos, setValidateRequisitos] = useState(false);
    const [filesDestino, setFilesDestino] = useState<{
        ingreso: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string },
        costos: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string },
        auditoria: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string }
    }>({
        auditoria: (docs ?? []).find((x: any) => x.tipo === 19) ?
            {
                file: new File(['0x0000'] as BlobPart[],
                    (docs ?? []).find((x: any) => x.tipo === 19).nombre),
                showContent: null,
                nombre: null,
                idDocumento: (docs ?? []).find((x: any) => x.tipo === 19).idDocumento
            } :
            { file: null, showContent: null, nombre: null,idDocumento: '' },
        ingreso: (docs ?? []).find((x: any) => x.tipo === 20) ?
            {
                file: new File(['0x0000'] as BlobPart[],
                    (docs ?? []).find((x: any) => x.tipo === 20).nombre),
                showContent: null,
                nombre: null,
                idDocumento: (docs ?? []).find((x: any) => x.tipo === 20).idDocumento
            } :
            { file: null, showContent: null, nombre: null, idDocumento: '' },
        costos: (docs ?? []).find((x: any) => x.tipo === 21) ?
            {
                file: new File(['0x0000'] as BlobPart[],
                    (docs ?? []).find((x: any) => x.tipo === 21).nombre),
                showContent: null,
                nombre: null,
                idDocumento: (docs ?? []).find((x: any) => x.tipo === 21).idDocumento
            } :
            { file: null, showContent: null, nombre: null, idDocumento: '' },
    });
    const [validateDestino, setValidateDestino] = useState(false);
    const [propsDestino, setPropsDestino] = useState<{ pagarCostos: boolean | null, puedeJustificar: boolean | null, importe: number, localizadorDocumento: string }>(
        {
            puedeJustificar: expediente?.detalles?.find((x) => x.codigo === '4000') !== undefined
                ?
                expediente?.detalles?.find((x) => x.codigo === '4000')?.valor === '1' :
                null,
            importe: parseFloat(importeCasilla),
            localizadorDocumento: expediente?.detalles?.find((x) => x.codigo === '4002')?.valor ?? '',
            pagarCostos: expediente?.detalles?.find((x) => x.codigo === '4003') !== undefined
                ?
                expediente?.detalles?.find((x) => x.codigo === '4003')?.valor === '1' :
                null,
        });
    const [signDialogOpen, setSignDialogOpen] = useState(false);
    const signButtonDisabled = useMemo(() => validateRequisitos && validateDestino ? false : true, [validateRequisitos, validateDestino]);

    const expedienteJustificado = useMemo(() => expediente.idEstado === EstadoExpediente.PagosJustificados, [expediente]);

    const handleFilesRequisitos = useCallback((files: any) => {
        setFilesRequisitos(files);
    }, []);

    const handleFilesDestino = useCallback((files: any) => {
        setFilesDestino(files);
    }, []);

    const handlePropsDestino = useCallback((props: any) => {
        setPropsDestino(props);
    }, []);

    const handleSignButton = useCallback(() => {
        setSignDialogOpen(true);
    }, []);

    const handleSignDialogClose = useCallback(() => {
        setSignDialogOpen(false);
    }, []);

    const handleSignature = useCallback(() => {
        let firma: IFirmaJustificantesProvisional = {
            documentoThomasCook: filesRequisitos?.ThomasCook?.idDocumento,
            documentoInversiones: filesRequisitos?.Inversiones?.idDocumento,
            documentoSubvenciones: filesRequisitos?.Subvenciones?.idDocumento,
            documentoAuditoria: filesDestino?.auditoria?.idDocumento,
            puedeJustificar: !!propsDestino?.puedeJustificar,
            costesJustificados: propsDestino?.importe,
            referenciaIngreso: propsDestino?.localizadorDocumento,
            costesFijos: !!propsDestino?.pagarCostos,
            documentoIngreso: filesDestino?.ingreso?.idDocumento,
            documentoCostesFijos: filesDestino?.costos?.idDocumento,
        }
        handleSign(firma);
    }, [filesRequisitos, filesDestino, propsDestino, handleSign]);

    useEffect(() => {
        if (parseFloat(expediente?.importeAsignado || '0') <= 6000) {
            setType(1);
        } else if (parseFloat(expediente?.importeAsignado || '0') > 6000 && parseFloat(expediente?.importeAsignado || '0') < 60000) {
            setType(2);
        } else {
            setType(3);
        }
    }, [expediente, t]);

    return (
        <>
            {expediente !== null && expediente.justificantes !== undefined && expediente.sujeto !== undefined
                ?
                <>
                    <MainLayout>
                        <Card style={{ padding: 40 }} className={classes.card}>
                            <CardContent>
                                <h3 style={{ color: '#004f82', fontWeight: 400, fontSize: 24, marginBottom: 20 }}>{t("solicitud:justificantes_de_pago")}</h3>
                                <JustificantesRequisitos
                                    detallesExpediente={expediente.detalles}
                                    files={filesRequisitos}
                                    handleFiles={handleFilesRequisitos}
                                    setValidation={setValidateRequisitos}
                                    expedientBlocked={expedienteJustificado}
                                    downloadFile={handleView}
                                />
                                <JustificantesDestino
                                    expediente={expediente}
                                    type={type}
                                    handleAdd={handleAdd}
                                    handleDelete={handleDelete}
                                    handleView={handleView}
                                    files={filesDestino}
                                    handleFiles={handleFilesDestino}
                                    props={propsDestino}
                                    handleProps={handlePropsDestino}
                                    setValidation={setValidateDestino}
                                    expedientBlocked={expedienteJustificado}
                                    downloadFile={handleView}
                                    handleOrderJustificantes={handleOrderJustificantes}
                                />
                            </CardContent>
                            <CardActions style={{ justifyContent: "flex-end" }}>
                                <>
                                    {
                                        expediente.idEstado === EstadoExpediente.PagosJustificados ?
                                            <Button color="primary" variant="contained" style={{ margin: 10 }} onClick={handleDownload} >{t("solicitud:descargar_justificante")}</Button>
                                            :
                                            <Button color="primary" variant="contained" style={{ margin: 10 }} onClick={handleSignButton} disabled={signButtonDisabled}>{t("solicitud:Firmar")}</Button>

                                    }

                                </>
                            </CardActions>
                        </Card>
                    </MainLayout>

                    {!expedienteJustificado &&
                        <FirmaJustificantes
                            expediente={expediente}
                            loading={loading}
                            type={type}
                            props={propsDestino}
                            dialogOpen={signDialogOpen}
                            handleDialogClose={handleSignDialogClose}
                            handleDialogSubmit={handleSignature}
                            lineaAeat={lineaAeat}
                        />
                    }
                </>
                :
                null
            }
        </>
    );
}

export default JustificantesPagoTemplate;