import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    rootCard: {
        maxWidth: 340,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    media: {
        height: 0,
        paddingTop: '41.18%',
    },
    root15: {
        display: 'flex',
        flex: 1,
        marginTop: 15,
    },
    rootCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    card: {
        textAlign: 'justify',
        overflowY: 'scroll',
        zIndex: 100,
        width: '100%',
    },
    import: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    rounded: {
        borderRadius: 15,
        margin: '16px 0',
        width: 215,
    },
    right: {
        textAlign: 'right',
    },
    stepperHeader: {
        display: 'flex',
        flex: 1,
        marginBottom: 15,
    },
    stepperCard: {
        flex: 1,
        marginRight: 15,
    },
    marginRight15: {
        marginRight: 15,
    },
    marginBottom25: {
        display: "flex",
        marginBottom: 25,
    },
    width100: {
        width: '100%',
        marginBottom: 15,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        minWidth: 500,
    },
    hidden: {
        display: 'none',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
        },
    },
    marginList: {
        marginTop: 15,
        '& > .MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
}));

export default useStyles;