import { makeStyles } from "@material-ui/core";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AttachButton } from "../atoms/attach-button.atom";
import { PrettierText } from "../atoms/prettier-text.atom";

interface IProps {
    expedientBlocked: boolean;
    file: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string };
    handleFile: (x: { file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string } | null) => void;
}

const useStyles = makeStyles({
    title: {
        color: '#004f82',
        fontSize: 20
    },
    padded: {
        padding: 10
    },
    inlineTextFields: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paddedTextFields: {
        marginRight: 30
    }
});

export const JustificantesCumplimiento: FC<IProps> = ({ expedientBlocked, file, handleFile }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    // Local states
    const [fileCumplimiento, setFileCumplimiento] = useState<{ file: File | null, showContent: boolean | null, nombre: string | null, idDocumento: string } | null>(file);

    // Events
    const handleFileCumplimiento = useCallback((x: any) => {
        setFileCumplimiento(x);
    }, []);

    const deattachFileCumplimiento = useCallback(() => {
        setFileCumplimiento(null);
    }, []);

    // Effects
    useEffect(() => {
        handleFile(fileCumplimiento);
    }, [fileCumplimiento, handleFile])

    return (
        <div>
            <h4 className={classes.title}>{t("solicitud:justificantes_cumplimiento")}</h4>
            <div style={{ margin: '10px' }}>
                <PrettierText text={t('solicitud:descripcion_cumplimiento')} />
            </div>
            {expedientBlocked ?
                <h4 className={classes.title}>{t("solicitud:documentos_aportados")}</h4>
                :
                null
            }
            <div style={{ alignSelf: 'center' }}>
                <AttachButton
                    id="fileCumplimiento"
                    documento={fileCumplimiento}
                    nameFile="documento cumplimiento"
                    handleFile={handleFileCumplimiento}
                    deattachFile={deattachFileCumplimiento}
                    blocked={expedientBlocked}
                />
            </div>
        </div>
    )
}
export default JustificantesCumplimiento;