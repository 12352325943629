import { useTranslation } from 'react-i18next';

interface IProps {
    text: string;
    className?: string;
}

function Term(props: IProps) {
    const { t } = useTranslation('global');

    return (
        <span className={props.className}>{t(props.text)}</span>
    );
}

export default Term;
