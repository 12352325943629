import { useTranslation } from 'react-i18next';
import { IOpcion } from '../../gateways/interfaces/opcion.interfaces';
import { PrettierText } from '../atoms/prettier-text.atom';

interface IProps {
    opcion: IOpcion;
    termsData: Record<string, string>;
}

const Respuestas: React.FC<IProps> = ({ opcion, termsData }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                opcion.label === '' && opcion.value === '' ?
                    null
                    :
                    <div style={{ paddingLeft: 24, marginTop: 10 }}>
                        <PrettierText text={t(opcion.label, termsData)}/>
                    </div>
            }
        </>
    )
}

export default Respuestas;