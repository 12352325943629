import { makeStyles } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDetalleExpediente } from "../../gateways/interfaces/expediente.interface";
import Attachment from "./attachment";

function validarCasilla(detallesExpediente?: IDetalleExpediente[], casilla?: string): boolean {
    return detallesExpediente?.find(x => x.codigo === casilla) !== undefined;
}

function validarCasillaMayor0(detallesExpediente?: IDetalleExpediente[], casilla?: string): boolean {
    return parseFloat(detallesExpediente?.find(x => x.codigo === casilla)?.valor || '0') > 0;
}

interface IProps {
    detallesExpediente?: IDetalleExpediente[];
    files: {
        ThomasCook: { file: File | null, idDocumento: string },
        Inversiones: { file: File | null, idDocumento: string },
        Subvenciones: { file: File | null, idDocumento: string }
    }
    handleFiles: (x: any) => void;
    setValidation: React.Dispatch<React.SetStateAction<boolean>>;
    expedientBlocked: boolean;
    downloadFile: (x: string, name: string) => void;
}

const useStyles = makeStyles({
    title: {
        color: '#004f82',
        fontSize: 20
    },
    section: {
        marginBottom: 20
    }
});

export const JustificantesRequisitos: FC<IProps> = ({ detallesExpediente, files, handleFiles, setValidation, expedientBlocked, downloadFile }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [fileThomasCook, setFileThomasCook] = useState<{ file: File | null, idDocumento: string }>(files.ThomasCook);
    const [fileInversiones, setFileInversiones] = useState<{ file: File | null, idDocumento: string }>(files.Inversiones);
    const [fileSubvenciones, setFileSubvenciones] = useState<{ file: File | null, idDocumento: string }>(files.Subvenciones);

    const showThomasCook = useMemo(() => {
        return validarCasillaMayor0(detallesExpediente, '610');
    }, [detallesExpediente])
    const showInversiones = useMemo(() => {
        return validarCasillaMayor0(detallesExpediente, '620') || validarCasillaMayor0(detallesExpediente, '630') || validarCasillaMayor0(detallesExpediente, '631')
    }, [detallesExpediente])
    const showSubvenciones = useMemo(() => {
        return validarCasilla(detallesExpediente, '50') && parseFloat(detallesExpediente?.find(x => x.codigo === '50')?.valor || '0') > 1800000
    }, [detallesExpediente])

    const handleFileThomasCook = useCallback((x: any) => {
        setFileThomasCook(x);
        handleFiles({ ...files, ThomasCook: x });
    }, [files, handleFiles]);

    const handleFileInversiones = useCallback((x: any) => {
        setFileInversiones(x);
        handleFiles({ ...files, Inversiones: x });
    }, [handleFiles, files]);

    const handleFileSubvenciones = useCallback((x: any) => {
        setFileSubvenciones(x);
        handleFiles({ ...files, Subvenciones: x });
    }, [files, handleFiles]);

    const deattachFileThomasCook = useCallback(() => {
        setFileThomasCook({ file: null, idDocumento: '' });
        handleFiles({ ...files, ThomasCook: { file: null, idDocumento: '' } });
    }, [files, handleFiles]);

    const deattachFileInversiones = useCallback(() => {
        setFileInversiones({ file: null, idDocumento: '' });
        handleFiles({ ...files, Inversiones: { file: null, idDocumento: '' } });
    }, [files, handleFiles]);

    const deattachFileSubvenciones = useCallback(() => {
        setFileSubvenciones({ file: null, idDocumento: '' });
        handleFiles({ ...files, Subvenciones: { file: null, idDocumento: '' } });
    }, [files, handleFiles]);


    useEffect(() => {
        if (showThomasCook && (fileThomasCook.file === null || fileThomasCook.idDocumento === "")) {
            setValidation(false);
            return;
        }
        if (showInversiones && (fileInversiones.file === null || fileInversiones.idDocumento === "")) {
            setValidation(false);
            return;
        }
        if (showSubvenciones && (fileSubvenciones.file === null || fileSubvenciones.idDocumento === "")) {
            setValidation(false);
            return;
        }
        setValidation(true);
    }, [showInversiones, showSubvenciones, showThomasCook, fileSubvenciones, fileThomasCook, fileInversiones, setValidation])

    return (
        <>
            {showThomasCook || showInversiones || showSubvenciones ?
                <div className={classes.section}>
                    <h4 className={classes.title}>{t("solicitud:justificantes_requisitos")}</h4>
                    {
                        showThomasCook &&
                        <Attachment
                            text="solicitud:adjuntos-thomas-cook"
                            name="ThomasCook"
                            file={fileThomasCook}
                            handleFile={handleFileThomasCook}
                            deattachFile={deattachFileThomasCook}
                            addBorder={showInversiones || showSubvenciones}
                            blocked={expedientBlocked}
                            downloadFile={downloadFile}
                        />
                    }
                    {
                        showInversiones &&
                        <Attachment
                            text="solicitud:adjuntos-inversiones"
                            name="Inversiones"
                            file={fileInversiones}
                            handleFile={handleFileInversiones}
                            deattachFile={deattachFileInversiones}
                            addBorder={showSubvenciones}
                            blocked={expedientBlocked}
                            downloadFile={downloadFile}
                        />
                    }
                    {
                        showSubvenciones &&
                        <Attachment
                            text="solicitud:adjuntos-subvenciones"
                            name="Subvenciones"
                            file={fileSubvenciones}
                            handleFile={handleFileSubvenciones}
                            deattachFile={deattachFileSubvenciones}
                            addBorder={false}
                            blocked={expedientBlocked}
                            downloadFile={downloadFile}
                        />
                    }
                </div>
                : null}
        </>
    );
}
export default JustificantesRequisitos;