import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    preguntaActiva: {
        background: `linear-gradient(90deg,rgba(0,79,130,1)0%, rgba(0,79,130,1)1%, rgba(235,235,235,1)1%, rgba(235,235,235,1)100%)`,
        paddingLeft: 20,
        paddingBottom: 10,
        paddingTop: 10,
    },
    textoPregunta: {
        fontSize: 18
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },
    footerContainer: {
        borderTop: '2px solid #d3c45b',
        textAlign: 'right',
    },
    wrapper: {
        margin: '15px',
        position: 'relative',
    },
    rootFooter: {
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: 0,
        left: 0,
        [theme.breakpoints.up('md')]: {
            left: 300,
            width: 'calc(100% - 300px)',
            boxShadow: '4px 0px 15px -10px rgba(0,0,0,0.75) inset',
            WebkitBoxShadow: '4px 0px 15px -10px rgba(0,0,0,0.75) inset',
            MozBoxShadow: '4px 0px 15px -10px rgba(0,0,0,0.75) inset',
        }
    },
    root: {
        display: 'flex', flex: 1, marginBottom: 15, marginTop: 0,

        [theme.breakpoints.up('md')]: {
            root: {
                display: 'flex', flex: 1, marginBottom: 15, marginTop: 15,
            },
        },
    },
    responsiveDiv: {
        display: "flex", marginBottom: 25, flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            display: "flex", marginBottom: 25, flexDirection: 'row',
        },
    },

    inputLabel: {
        fontSize: 16
    },
    inputValue: {
        fontSize: 18, fontWeight: 'normal'
    },
    inputPadding: {
        flex: 1, paddingRight: 35, paddingBottom: 20
    },
    inputPaddingWithoutFlex: {
        paddingRight: 35, paddingBottom: 20
    },

    radioLabel: {
        marginBottom: 10,
        marginLeft: 5,

        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        },
    },

    checkBoxList: {
        marginLeft: 0, marginTop: -10, alignSelf: 'flex-start',
    },
    checkBoxDiv: {
        display: "flex", marginRight: 35, textAlign: "justify", marginBottom: 25,
    },

    tableColumnHeader: {
        fontWeight: 'normal', color: 'GrayText',
    },
    tableColumnValue: {
        fontWeight: 'bold',
    }

}));

export default useStyles;