import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core';
import React from 'react'; //

interface IProps {
    checked: boolean | null,
    disabled?: boolean,
    error?: boolean,
    messageError?: string,
    onChange: (parametro: boolean) => void,
}

const CheckSiNo: React.FC<IProps> = ({ checked, disabled, error, messageError, onChange }) => {

    return (
        <FormControl required={error === true} error={error === true}>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked === true}
                            disabled={disabled === true}
                            onChange={() => onChange(true)}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    }
                    label="SI"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked === false}
                            disabled={disabled === true}
                            onChange={() => onChange(false)}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    }
                    label="NO"
                />
            </FormGroup>
            {
                error === true &&
                <FormHelperText error={true}>
                    {messageError}
                </FormHelperText>
            }
        </FormControl>
    )
}

export default CheckSiNo;