import { FC, useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { PageContext } from "../contexts/app.context";
import { CircularProgress } from "@material-ui/core";
import { EstadoExpediente, IExpediente, IFirmaJustificanteCumplimiento, TipoDocumento } from "../gateways/interfaces/expediente.interface";
import { dispatch } from "./expediente/expediente.utils";
import CumplimientoCompromisosTemplate from "../components/templates/cumplimiento-compromisos.template";
import useExpedienteGateway from "../gateways/expediente.gateway";
import { downloadFile } from "../utils/downloadFile";
import { useTranslation } from "react-i18next";
import useAfirmaGateway from "../gateways/afirma.gateway";
import { Afirma } from "../utils/afirma";
import { TipoAccion } from "../gateways/interfaces/afirma.interfaces";
import { AlertsContext } from "../contexts/alerts.context";

export const CumplimientoCompromisosPage: FC<RouteComponentProps> = ({ history }) => {
    // Global states
    const { t } = useTranslation(['solicitud', 'error']);
    const [pageState, pageDispatcher] = useContext(PageContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, setLoading] = useState(false);

    // Local states    
    const [loadingClave, setLoadingClave] = useState(false);
    const [signDialogOpen, setSignDialogOpen] = useState(false);
    const [expediente, setExpediente] = useState<IExpediente | null>(null);
    const [docCumplimiento, setDocCumplimiento] = useState<any>(null);

    // Gateways
    const { getDocumentoCumplimiento, descargarJustificanteJustificacion } = useExpedienteGateway();
    const gateway = useAfirmaGateway();

    // Events
    const handleSign = useCallback(async (firmaAUX: IFirmaJustificanteCumplimiento) => {
        setLoadingClave(true);
        const result = await gateway.generarFirma({ tipoAccion: TipoAccion.cumplimiento, content: firmaAUX });
        if (result.ok) {
            const afirma = new Afirma(result.idFirma ? result.idFirma : 0, TipoAccion.cumplimiento, gateway, pageState.lang, (datosFirma: any) => {
                if (datosFirma.ok) {
                    setExpediente({ ...expediente, idEstado: EstadoExpediente.CumplimientoCompromisos });                    
                    pageDispatcher({ type: 'setExpediente', payload: { ...expediente, idEstado: EstadoExpediente.CumplimientoCompromisos } });
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t("solicitud:firma_documentos_correcta"),
                            variant: 'success',
                        }
                    });
                    setLoadingClave(false);
                    setSignDialogOpen(false);
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t(datosFirma.mensaje),
                            variant: 'info',
                        }
                    });
                    setLoadingClave(false);
                }                                
            });
            afirma.processSignature();
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: t(result.mensaje),
                    variant: 'info',
                }
            });
            setLoadingClave(false);
        }
    }, [alertsDispatch, expediente, gateway, pageDispatcher, pageState.lang, t]);

    const handleDownload = useCallback(async () => {
        const x = await descargarJustificanteJustificacion(pageState.lang, TipoDocumento.justificanteCumplimiento);
        downloadFile(x, 'justificante');
    }, [descargarJustificanteJustificacion, pageState])

    const handleSignDialog = useCallback((open: boolean) => {
        setSignDialogOpen(open);
    }, []);

    // Effects 
    useEffect(() => {
        async function load() {
            if (pageState.expediente) {
                setLoading(true);
                const doc = await getDocumentoCumplimiento();
                setDocCumplimiento(doc);
                setExpediente({ ...pageState.expediente });
                setLoading(false);
                if (pageState.expediente?.puedeJustificar) {
                    pageDispatcher({
                        type: 'setMenu',
                        menu: true,
                    });
                } else {
                    dispatch(pageState.expediente.idEstado ? pageState.expediente.idEstado : 0, history, expediente?.puedeJustificar);
                }
            } else {
                history.push('/');
            }
        }
        load();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, pageState.expediente]);
    
    return (
        <>
            {
                expediente
                    ?
                    <CumplimientoCompromisosTemplate
                        expediente={expediente}
                        docCumplimiento={docCumplimiento}
                        loading={loadingClave}
                        signDialogOpen={signDialogOpen}
                        handleSign={handleSign}
                        handleSignDialog={handleSignDialog}
                        handleDownload={handleDownload}
                    />
                    :
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress size={75} />
                    </div>
            }
        </>
    );
}