import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const theme = createMuiTheme({
    palette: {
      primary: blue,
    },
    overrides: {
      MuiCard: {
        root: {
          borderRadius: 8,
        }
      }
    }
  });

export default theme;