import { FC } from "react";
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const NumberFormatCustomWithoutDecimals: FC<NumberFormatCustomProps> = ({ inputRef, onChange, name, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      decimalScale={0}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString={true}
    />
  );
}

export default NumberFormatCustomWithoutDecimals;