import { FC, useState, useCallback, useMemo } from 'react';
import { Dialog, Button, DialogActions, DialogContent, Divider, makeStyles, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles, TextField, DialogTitle, FormControl, FormControlLabel, FormGroup, Checkbox, FormHelperText } from "@material-ui/core";
import Term from '../term';
import { useTranslation } from 'react-i18next';
import { DialogFormProps, DialogoWorkflow } from '../workflow/interfaces';
import { mdiTrashCan } from '@mdi/js';
import { ISociedad } from '../../../gateways/interfaces/expediente.interface';
import Icon from '@mdi/react';
import CheckSiNo from '../check.si-no';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    width: {
        width: '100%'
    },
    content: {
        margin: 5,
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
        marginLeft: 10,
        marginBottom: -8,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


const DialogCasilla326: FC<DialogFormProps> = ({ expediente, onChange, onCloseWithoutChanges }) => {
    const { t } = useTranslation("solicitud");
    const classes = useStyles();
    const [sociedades, setSociedades] = useState<ISociedad[]>([]);
    const [nif, setNif] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [razonSocialError, setRazonSocialError] = useState(false);
    const [state, setState] = useState({
        e2019: false,
        e2020: false,
        e2021: false,
    });
    const { e2019, e2020, e2021 } = state;

    const [tributa, setTributa] = useState<boolean | null>(null);
    const ejercicio = useMemo(() => {
        let x: string[] = [];
        e2019 && x.push('2019');
        e2020 && x.push('2020');
        e2021 && x.push('2021');
        return x.join(',');
    }, [e2019, e2020, e2021]);
    const [errorNif, setErrorNif] = useState(false);
    const [errorTributa, setErrorTributa] = useState(false);
    const [errorEjercicio, setErrorEjercicio] = useState(false);

    const handleChangeNombreEntidad = useCallback((x: string) => {
        setRazonSocial(x);
    }, []);
    const handleChangeNifEntidad = useCallback((x: string) => {
        setNif(x);
    }, []);
    const handleChangeTributa = useCallback((bit: boolean) => {
        setTributa(bit);
    }, []);
    const handleChangeEjercicio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleDeleteSociedad = useCallback((sociedad: ISociedad) => {
        const list = sociedades.filter((x) => x.nif !== sociedad.nif || x.ejercicio !== sociedad.ejercicio);
        setSociedades(list);
    }, [sociedades])

    const handleAddSociedad = useCallback(() => {
        setErrorTributa(false);
        setErrorNif(false);
        setErrorEjercicio(false);
        setRazonSocialError(false);
        let dominada2021 = false;
        let sociedadAnyo = false;
        for (let s of ejercicio.split(',')) {
            if (sociedades.find(x => x.nif === nif && x.ejercicio.split(',').includes(s)) !== undefined)
                sociedadAnyo = true;
            if (nif === expediente?.detalles?.find(x => x.codigo === '5')?.valor && s === '2021') {
                dominada2021 = true;
            }
        }
        if (
            tributa === null ||
            (razonSocial === '') ||
            (nif.length !== 9 || !new RegExp(/^([a-z]|[A-Z]){1}[\d]{7}([a-z]|[A-Z]|\d){1}$/).test(nif) ||
                dominada2021 || sociedadAnyo) ||
            ejercicio === ''
        ) {
            if (tributa === null) {
                setErrorTributa(true);
            }
            if (razonSocial === '') {
                setRazonSocialError(true);
            }
            if (nif.length !== 9 || !new RegExp(/^([a-z]|[A-Z]){1}[\d]{7}([a-z]|[A-Z]|\d){1}$/).test(nif)) {
                setErrorNif(true);
            }
            if (dominada2021 || sociedadAnyo) {
                setErrorNif(true);
            }
            if (ejercicio === '') {
                setErrorEjercicio(true);
            }
            return;
        } else {
            setSociedades([
                ...sociedades,
                { nif, nombre: razonSocial, tributa, ejercicio }
            ]);
            if (ejercicio && ejercicio.length <= 0) {
                return;
            }
            setRazonSocial('');
            setTributa(null);
            setNif('');
            setState({
                e2019: false,
                e2020: false,
                e2021: false,
            });
        }
    }, [ejercicio, nif, razonSocial, tributa, sociedades, expediente])

    const handleChange = useCallback(() => {
        const nifStrings: string[] = [];
        sociedades.forEach(x => {
            nifStrings.push(x.nif);
        })
        const nifs = nifStrings.join(', ');
        expediente.detalles?.push({
            codigo: '3260',
            origen: 'actividad-economica',
            valor: nifs
        });
        onChange({
            ...expediente,
            sociedades: sociedades,
        });
    }, [sociedades, onChange, expediente]);

    return <>
        <Dialog
            maxWidth='lg'
            fullWidth={true}
            open={true}
        >
            <DialogTitle>{t("solicitud:title_modal_326")}</DialogTitle>
            <DialogContent className={classes.content}>
                <div style={{ display: 'flex' }}>
                    <TextField
                        label={t("solicitud:nif")}
                        style={{ flex: 1, paddingRight: 35 }}
                        error={errorNif}
                        helperText={errorNif === true ? t('error:error_nif') : ''}
                        onChange={(e) => handleChangeNifEntidad(e.target.value)}
                        value={nif}
                    />
                    <TextField
                        label={t("solicitud:razon_social")}
                        style={{ flex: 1, paddingRight: 35 }}
                        error={razonSocialError}
                        helperText={razonSocialError ? t('error:razon_social_obligatoria') : ''}
                        onChange={(e) => handleChangeNombreEntidad(e.target.value)}
                        value={razonSocial}
                    />
                </div>
                <div style={{ display: 'flex', marginTop: 50 }}>
                    <div style={{ flex: 1 }}>
                        <h4 style={errorTributa ? { color: '#f44336' } : undefined}>{t("solicitud:tributa_hacienda_foral")}</h4>
                        <CheckSiNo error={errorTributa} messageError={errorTributa ? t('error:error_tributa') : ''} checked={tributa} onChange={(x) => handleChangeTributa(x)} />
                    </div>
                    <FormControl required error={errorEjercicio} style={{ flex: 1 }} component="fieldset">
                        <h4 style={errorEjercicio ? { color: '#f44336' } : undefined}>{t('solicitud:ejercicios')}</h4>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={e2019} color="primary" onChange={handleChangeEjercicio} name="e2019" />}
                                label="2019"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={e2020} color="primary" onChange={handleChangeEjercicio} name="e2020" />}
                                label="2020"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={e2021} color="primary" onChange={handleChangeEjercicio} name="e2021" />}
                                label="2021"
                            />
                        </FormGroup>
                        {
                            errorEjercicio &&
                            <FormHelperText error={true}>
                                {t('error:error_ejercicio')}
                            </FormHelperText>
                        }

                    </FormControl>
                    <div style={{ alignSelf: 'center' }}>
                        <Button
                            // disabled={nif === '' || razonSocial === '' || tributa === null || ejercicio === ''} 
                            variant="contained" color="primary" onClick={handleAddSociedad}>
                            {t("solicitud:anyadir")}
                        </Button>
                    </div>
                </div>
                {
                    sociedades.length === 0 &&
                    <div style={{ display: 'flex', marginTop: 25 }}>
                        <Alert style={{ flex: 1 }} severity="info">{t('solicitud:introduzca_una_sociedad')}</Alert>
                    </div>
                }
                {
                    sociedades.length > 0 &&
                    <>
                        <TableContainer style={{ marginTop: 50 }}>
                            <Table size={'small'} style={{ borderColor: 'primary', borderWidth: 1 }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{t("solicitud:nombre")}</StyledTableCell>
                                        <StyledTableCell align="right">{t("solicitud:nif")}</StyledTableCell>
                                        <StyledTableCell align="right">{t("solicitud:tributa_hacienda_foral")}</StyledTableCell>
                                        <StyledTableCell align="right">{t("solicitud:ejercicio_sociedades")}</StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        sociedades.map((sociedad: ISociedad, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>{sociedad.nombre}</TableCell>
                                                <TableCell align="right">{sociedad.nif}</TableCell>
                                                <TableCell align="right">{sociedad.tributa === true ? 'Si' : 'No'}</TableCell>
                                                <TableCell align="right">{sociedad.ejercicio}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton color="inherit" onClick={() => handleDeleteSociedad(sociedad)}>
                                                        <Icon color={'red'} path={mdiTrashCan} size={1} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            sociedades.find(x => x.ejercicio.search('2021') !== -1) === undefined &&
                            <div style={{ display: 'flex', marginTop: 25 }}>
                                <Alert style={{ flex: 1 }} severity="warning">{t('solicitud:introduzca_una_sociedad_2021')}</Alert>
                            </div>
                        }
                    </>
                }
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onCloseWithoutChanges}
                    color="default"
                >
                    <Term text="cerrar" />
                </Button>
                <Button
                    onClick={handleChange}
                    disabled={sociedades.length === 0 || sociedades.find(x => x.ejercicio.search('2021') !== -1) === undefined}
                    color="primary"
                    variant="contained"
                >
                    <Term text="confirmar" />
                </Button>
            </DialogActions>
        </Dialog>
    </>
};

const DialogCasilla326Workflow: DialogoWorkflow = {
    Component: DialogCasilla326,
    onResponse: (expediente) => {
        const actividadEconomica = [...(expediente.actividadEconomica || [])];

        actividadEconomica.push({
            pregunta: 'solicitud:respuesta_modal_326',
            opciones: [],
            opcionSeleccionada: {
                value: 'respuesta-dialogo-326',
                label: 'solicitud:casillero3260'
            }
        });

        return {
            ...expediente,
            actividadEconomica,
        }
    },
}

export default DialogCasilla326Workflow;