/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useReducer } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import i18next from 'i18next';
import Favicon from 'react-favicon';
import './I18n';
import { AlertsContext } from './contexts/alerts.context';
import AlertsReducer from './contexts/alerts.reducer';
import { ClavePage } from './pages/clave';
import { HomePage } from './pages/home';
import { returnLanguage } from './utils/language.utils';
import ProtectedRoute from './components/organisms/protected-route';
import { PageContext, PageReducer, PageDefaultState } from './contexts/app.context';
import AlertDialog from './components/organisms/alert.dialog';
import { AfirmaTestPage } from './pages/afirma-test.page';
import { configAppAfirma } from './utils/afirma';
import { SeleccionPage } from './pages/seleccion';
import { ConfigContext } from './contexts/config.context';
import env from './env';
import { ExpedientePage } from './pages/expediente';
import { InfoRequisitosPage } from './pages/info-requisitos';
import { Footer } from './components/organisms/footer';
import { HerramientasPage } from './pages/herramientas';
import { LanguageTest } from './pages/language-test.page';
import useAuthGateway from './gateways/auth.gateway';

let config: any = null;
const configPromise = fetch(env.API_URI + 'config').then(async (c) => config = await c.json());

const App = () => {
    if (!config) {
        throw configPromise;
    }

    const authGateway = useAuthGateway();

    useEffect(() => {
        async function set() {
            const language = await returnLanguage();
            await i18next.changeLanguage(language);
            setInterval(async () => {
                await authGateway.checkSession();
            }, 900000);
        }
        set();
        configAppAfirma();
    }, []);

    const pageReducer = useReducer(PageReducer, PageDefaultState);
    const alertsReducer = useReducer(AlertsReducer, { alert: null });

    return (
        <ConfigContext.Provider value={config}>
            <PageContext.Provider value={pageReducer}>
                <AlertsContext.Provider value={alertsReducer}>
                    <BrowserRouter>
                        <Favicon url={require('./resources/images/favicon.ico').default} />
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/requisitos" component={InfoRequisitosPage} />
                        <Route exact path="/clave" component={ClavePage} />
                        <ProtectedRoute exact path="/afirma-test" component={AfirmaTestPage} />
                        <ProtectedRoute exact path="/language-test" component={LanguageTest} />
                        <ProtectedRoute exact path="/seleccion" component={SeleccionPage} />
                        <ProtectedRoute path="/expediente" component={ExpedientePage} />
                        <Route path="/herramientas" component={HerramientasPage} />
                    </BrowserRouter>
                    <AlertDialog />
                    <Footer />
                </AlertsContext.Provider>
            </PageContext.Provider>
        </ConfigContext.Provider>
    );
}

export default App;
