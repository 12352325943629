import { useCallback } from 'react';
import { IExpediente } from '../../gateways/interfaces/expediente.interface';
import { IDeclaracion } from '../../gateways/interfaces/solicitud.interfaces';
import Contestador from './contestador';

interface IProps {
    declaraciones: IDeclaracion[];
    expediente: IExpediente;
    onChange: (expediente: IExpediente, declaraciones: IDeclaracion[]) => void;
}

const Declarador: React.FC<IProps> = ({ declaraciones, expediente, onChange }) => {
    const handleSubmit = useCallback((declaracion: IDeclaracion) => {
        onChange({
            ...expediente,
            detalles: [
                ...expediente.detalles || [],
                { origen: 'declara', codigo: declaracion.casilla, valor: "1" }
            ]
        }, declaraciones);
    }, [expediente, onChange, declaraciones]);

    return (
        <>
            {
                declaraciones.map((x: IDeclaracion, index: number) => {
                    const declaracionAnterior = declaraciones[index - 1];
                    const declaradaAnterior = declaracionAnterior && (expediente.detalles ?? []).findIndex(x => x.codigo === declaracionAnterior.casilla) >= 0;
                    const declarada = (expediente.detalles ?? []).findIndex(e => e.codigo === x.casilla) >= 0;
                    return (
                        (x.visualizar === undefined || x.visualizar(expediente)) && (index === 0 || declaradaAnterior) &&
                        <div key={'declarador' + index + '_' + x.casilla} style={{ marginBottom: 20 }}>
                            <Contestador
                                expediente={expediente}
                                opciones={[{ value: true, label: 'aceptar' }]}
                                pregunta={x.declaracion}
                                opcion={declarada ? { value: '', label: '' } : undefined}
                                textoAclaratorio={x.textoAclaratorio ? x.textoAclaratorio : undefined}
                                onAnswer={() => handleSubmit(x)} />
                        </div>
                    )
                })
            }
        </>
    )
}

export default Declarador;