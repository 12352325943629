import { makeStyles } from '@material-ui/core/styles';

const useGlobalStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
    },
    buscador: {
        textAlign: 'right',
        marginRight: 15,
        marginBottom: 10,
        marginTop: 10
    },
    rootCards: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            marginTop: 100,
        }
    },
    rootGridContent: {
        display: 'flex',
        justifyContent: 'center',
    },
    root15: {
        display: 'flex',
        flex: 1,
        marginTop: 15,
    },
    rootCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    card: {
        textAlign: 'justify',
        overflowY: 'scroll',
        zIndex: 100,
        width: '100%',
    },
    import: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    rounded: {
        borderRadius: 15,
        margin: '16px 0',
        width: 215,
    },
    right: {
        textAlign: 'right',
    },
    stepperHeader: {
        display: 'flex',
        flex: 1,
        marginBottom: 15,
    },
    stepperCard: {
        flex: 1,
        marginRight: 15,
    },
    marginRight15: {
        marginRight: 15,
    },
    marginBottom25: {
        display: "flex",
        marginBottom: 25,
    },
    width100: {
        width: '100%',
        marginBottom: 15,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        minWidth: 500,
    },
    hidden: {
        display: 'none',
    },
    [theme.breakpoints.up('md')]: {
        marginXL: {
            marginTop: 100
        }
    }
}));

export default useGlobalStyles;