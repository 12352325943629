export enum IStatus {
    Blocked,
    Opened,
}

export interface ISteper {
  index: number;
  name: string;
  icon?: string;
  url?: string,
  status: IStatus;
}
