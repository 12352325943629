import { StyleRules, Theme, withStyles, WithStyles } from "@material-ui/core";
import { FC, useContext } from "react";
import { PageContext } from "../../contexts/app.context";
import Header from "../organisms/header";
import SideNavLeft from '../organisms/sidenav';
import ContentMenu from '../organisms/content-menu';

const styles = (theme: Theme): StyleRules => ({
    main: {
        display: 'flex',
        flex: 1,
    },
    component: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginTop: '55px',
        marginLeft: 15,
        paddingTop: theme.spacing(4),
        paddingRight: 15,
    },
    [theme.breakpoints.up('md')]: {
        component: {
            paddingTop: 230,
            paddingBottom: 100,
            display: 'flex',
            marginTop: '0px',
        },
        main: {
            paddingTop: 0,
        },
    }
});

type Props = WithStyles<typeof styles>;

const MainLayout: FC<Props> = ({ children, ...props }) => {
    const { classes } = props;
    const [pageState,] = useContext(PageContext);

    return (
        <>
            <Header />
            <div className={classes.main}>
                <main className={classes.main}>
                    {
                        pageState.menu
                            ? <SideNavLeft />
                            : null
                    }
                    {
                        pageState.menuContent
                            ? <ContentMenu />
                            : null
                    }
                    <div className={classes.component}>
                        {children}
                    </div>
                </main>
            </div>
        </>);
}

export default withStyles(styles)(MainLayout);