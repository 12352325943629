import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AlertsContext } from "../contexts/alerts.context";
import { PageContext } from "../contexts/app.context";
import {
    IJustificantePago,
    IJustificantePagoProvisional,
    IExpediente,
    ICNAE,
    IFirmaJustificantesProvisional,
    IFirmaJustificantes,
    TipoDocumento
} from "./interfaces/expediente.interface";

const useExpedienteGateway = () => {
    const { t } = useTranslation('global');
    const [appState, pageDispatcher] = useContext(PageContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const { axios } = appState;

    return {
        getListadoCNAE: useCallback(async () => {
            const response = await axios.get<ICNAE[]>('/values/cnae');
            return response.data;
        }, [axios]),
        getDocsJustificativos: useCallback(async (idExpediente: number) => {
            try {
                const response = await axios.get("/expediente/documentos-justificativos?idExpediente=" + idExpediente);
                if (response.status === 403) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t('sesion_expirada'),
                            variant: 'info',
                        }
                    });
                    pageDispatcher({ type: 'logout' });
                }
                return response.data;
            } catch (e) {
                return null;
            }
        }, [alertsDispatch, axios, pageDispatcher, t]),
        getDocumentoCumplimiento: useCallback(async () => {
            try {
                const response = await axios.get("/expediente/documentos-cumplimiento?tipoDocumento=" + TipoDocumento.documentacionCumplimiento);
                if (response.status === 403) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t('sesion_expirada'),
                            variant: 'info',
                        }
                    });
                    pageDispatcher({ type: 'logout' });
                }
                return response.data;
            } catch (e) {
                return null;
            }
        }, [alertsDispatch, axios, pageDispatcher, t]),
        getDocsRecursos: useCallback(async (idExpediente: number) => {
            try {
                const response = await axios.get("/expediente/documentos-recursos?idExpediente=" + idExpediente);
                if (response.status === 403) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t('sesion_expirada'),
                            variant: 'info',
                        }
                    });
                    pageDispatcher({ type: 'logout' });
                }
                return response.data;
            } catch (e) {
                return null;
            }
        }, [alertsDispatch, axios, pageDispatcher, t]),
        getJustificantes: useCallback(async (idExpediente: number) => {
            try {
                const response = await axios.get("/expediente/justificantes?idExpediente=" + idExpediente);
                if (response.status === 403) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t('sesion_expirada'),
                            variant: 'info',
                        }
                    });
                    pageDispatcher({ type: 'logout' });
                }
                return response.data;
            } catch (e) {
                return null;
            }
        }, [alertsDispatch, axios, pageDispatcher, t]),
        getExpediente: useCallback(async (): Promise<IExpediente | null> => {
            try {
                const response = await axios.get("/expediente");

                if (response.status === 403) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t('sesion_expirada'),
                            variant: 'info',
                        }
                    });
                    pageDispatcher({ type: 'logout' });
                }

                return response.data;
            } catch (e) {
                return null;
            }
        }, [alertsDispatch, axios, pageDispatcher, t]),
        despresentar: useCallback(async () => {
            try {
                const response = await axios.post("/expediente/despresentar");

                if (response.status === 403) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t('sesion_expirada'),
                            variant: 'info',
                        }
                    });
                    pageDispatcher({ type: 'logout' });
                }
            } catch (e) {
                return null;
            }
        },
            [alertsDispatch, axios, pageDispatcher, t]
        ),
        crearOrdenFirma: useCallback(
            async (expediente: IExpediente): Promise<number> => {
                try {
                    const response = await axios.post("/expediente/orden-firma", expediente);
                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }
                    return response.data;
                } catch (e) {
                    throw new Error();
                }
            },
            [alertsDispatch, axios, pageDispatcher, t]
        ),
        saveExpediente: useCallback(
            async (expediente: IExpediente): Promise<IExpediente | null> => {
                try {
                    const response = await axios.post("/expediente", { detalles: expediente.detalles, sociedades: expediente.sociedades });
                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }
                    if (response.data.ok === false) {
                        throw new Error();
                    }
                    return response.data.expediente;
                } catch (e) {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: t('error:error_al_presentar'),
                            variant: 'error',
                        }
                    });
                    return null;
                }
            },
            [alertsDispatch, axios, pageDispatcher, t]
        ),
        updateEstado: useCallback(
            async (expediente: IExpediente): Promise<boolean> => {
                try {
                    const response = await axios.put(
                        "expediente/actualizar-estado",
                        expediente
                    );

                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }
                    return response.data;
                } catch (e) {
                    return false;
                }
            },
            [alertsDispatch, axios, pageDispatcher, t]
        ),
        updateDatosPago: useCallback(
            async (expediente: IExpediente): Promise<boolean> => {
                try {
                    const response = await axios.put("expediente/datos-pago", expediente);

                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }
                    return response.data;
                } catch (e) {
                    return false;
                }
            },
            [alertsDispatch, axios, pageDispatcher, t]
        ),
        getSujeto: useCallback(
            async (): Promise<any | null> => {
                try {
                    const response = await axios.get("/expediente/get-sujeto");
                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }
                    return response.data;
                } catch (e) {
                    return null;
                }
            }, [alertsDispatch, axios, pageDispatcher, t]
        ),
        getSujetoRepresentado: useCallback(
            async (): Promise<any | null> => {
                try {
                    const response = await axios.get("/sujetos/sujeto-representado");
                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }
                    return response.data;
                } catch (e) {
                    return null;
                }
            }, [alertsDispatch, axios, pageDispatcher, t]
        ),
        descargarJustificante: useCallback(
            async (
                lang: string
            ) => {
                try {
                    const response = await axios.get(`/expediente/justificante?lang=${lang}`, { responseType: 'blob' });

                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }

                    return response.data;
                } catch (e) {
                    return null;
                }
            },
            [axios, alertsDispatch, pageDispatcher, t],
        ),
        descargarJustificanteJustificacion: useCallback(
            async (
                lang: string,
                tipoDocumento: TipoDocumento,
            ) => {
                try {
                    const response = await axios.get(`/expediente/justificanteJustificacion?lang=${lang}&tipoDocumento=${tipoDocumento}`, { responseType: 'blob' });

                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }

                    return response.data;
                } catch (e) {
                    return null;
                }
            },
            [axios, alertsDispatch, pageDispatcher, t],
        ),
        deleteJustificante: useCallback(
            async (id: number): Promise<any | null> => {
                try {
                    const response = await axios.post("/expediente/delete-justificante", {
                        id
                    });
                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }
                    return response.data;
                } catch (e) {
                    return null;
                }
            }, [alertsDispatch, axios, pageDispatcher, t]
        ),
        getFile: useCallback(
            async (id: string): Promise<any | null> => {
                try {
                    const response = await axios.get("/expediente/get-file?idDocumento=" + id, { responseType: 'blob' });
                    if (response.status === 403) {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: t('sesion_expirada'),
                                variant: 'info',
                            }
                        });
                        pageDispatcher({ type: 'logout' });
                    }
                    return response.data;
                } catch (e) {
                    return null;
                }
            }, [alertsDispatch, axios, pageDispatcher, t]
        ),
        saveJustificante: useCallback(
            async (
                idExpediente: number,
                x: IJustificantePagoProvisional[]
            ): Promise<IJustificantePago[] | null> => {
                try {
                    const result = [];
                    for (const just of x) {
                        const formData = new FormData();
                        formData.append("file", just.fichero);
                        formData.append("fechaPago", just.fechaPago);
                        formData.append("nombreProveedor", just.nombreProveedor);
                        formData.append("nifProveedor", just.nifProveedor);
                        formData.append("numeroReferencia", just.numeroReferencia);
                        formData.append("importe", just.importe);
                        formData.append("numeroPrelacion", just.numeroPrelacion);
                        formData.append("baseImponible", just.baseImponible);
                        formData.append("fechaEmision", just.fechaEmision);
                        formData.append("id", idExpediente.toString());
                        const response = await axios.post(
                            "/expediente/justificante-pago",
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        );
                        if (response.data.ok) {
                            result.push(response.data.entidad);
                        }
                    }
                    return result as IJustificantePago[];
                } catch (e) {
                    return null;
                }
            },
            [axios]
        ),
        updateJustificantes: useCallback(
            async (
                justificantes: IJustificantePago[]
            ): Promise<IJustificantePago[] | null> => {
                try {
                    const result = [];
                    for (const justificante of justificantes) {
                        const response = await axios.put(
                            "/expediente/justificante-pago",
                            justificante
                        );
                        if (response.data.ok) {
                            result.push(response.data.entidad);
                        }
                    }
                    return result as IJustificantePago[];
                } catch (e) {
                    return null;
                }
            },
            [axios]
        ),
        saveFirma: useCallback(
            async (
                idExpediente: number,
                firma: IFirmaJustificantesProvisional
            ): Promise<IFirmaJustificantes | null> => {
                try {
                    let result: any;
                    const formData = new FormData();
                    if (firma.documentoThomasCook) {
                        formData.append("documentoThomasCook", firma.documentoThomasCook);
                    }
                    if (firma.documentoInversiones) {
                        formData.append("documentoInversiones", firma.documentoInversiones);
                    }
                    if (firma.documentoSubvenciones) {
                        formData.append("documentoSubvenciones", firma.documentoSubvenciones);
                    }
                    if (firma.documentoAuditoria) {
                        formData.append("documentoAuditoria", firma.documentoAuditoria);
                    }
                    if (firma.documentoIngreso) {
                        formData.append("documentoIngreso", firma.documentoIngreso);
                    }
                    if (firma.documentoCostesFijos) {
                        formData.append("documentoCostesFijos", firma.documentoCostesFijos);
                    }
                    formData.append("puedeJustificar", firma.puedeJustificar.toString());
                    formData.append("costesJustificados", firma.costesJustificados.toString());
                    formData.append("referenciaIngreso", firma.referenciaIngreso);
                    formData.append("costesFijos", firma.costesFijos.toString());
                    formData.append("id", idExpediente.toString());
                    const response = await axios.post(
                        "/expediente/firma-justificante",
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );
                    if (response.data.ok) {
                        result = response.data.entidad;
                    }
                    return result as IFirmaJustificantes;
                } catch (e) {
                    return null;
                }
            },
            [axios]
        ),
        getLineaAeat: useCallback(async (idExpediente: number) => {
            try {
                const response = await axios.get("/expediente/linea-aeat?idExpediente=" + idExpediente);
                return response.data;
            } catch (e) {
                return null;
            }
        }, [axios]),
    };
};

export default useExpedienteGateway;
