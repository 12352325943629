import { useCallback, useContext } from "react";
import { PageContext } from "../contexts/app.context";
import { IFirma, IFirmaResponse, ISignature, ISignatureResponse } from "./interfaces/afirma.interfaces";

const useAfirmaGateway = () => {
  const [appState] = useContext(PageContext);
  const { axios } = appState;

  return {
    saveSignature: useCallback(
        async (signature: ISignature): Promise<ISignatureResponse> => {
            try {
                const response = await axios.post(
                    "afirma/saveSignature",
                    signature
                );

                if (response.status !== 201) {
                    throw new Error("Bad response status: " + response.statusText);
                }

                return response.data;
            } catch (e) {
                return { ok: false };
            }
      },
      [axios]
    ),
    generarFirma: useCallback(
        async (firma: IFirma): Promise<IFirmaResponse> => {
            try {
                const response = await axios.post(
                    "afirma/firmar",
                    firma
                );

                if (response.status !== 201) {
                    throw new Error("Bad response status: " + response.statusText);
                }

                return response.data;
            } catch (e) {
                return { ok: false, mensaje: 'error_firma' };
            }
    }, [axios])
  };
};

export default useAfirmaGateway;
